/** @format */
import { Enviroment } from '../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/adapters/axios/axios.protocols';

import { axiosInterceptor } from '../../../../utility/axios-interceptor.utility';
import { axiosConfig } from '../../../../utility/axios-config.utility';
const path = 'src/pages/profile/pages/account-wizzard/services.tsx';

export class Services {
	async get(account) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/account/get-by-account/${account}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async create(body) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/account`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async update(body) {
		return new AxiosAdapter({
			method: Http.PUT,
			url: `${Enviroment.HOST}/account`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}

	async getDomains() {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/select-option/domains`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}

export const serviceCreateData = async (body) => {
	const response = axiosConfig({
		method: 'POST',
		url: `/account`,
		data: body,
	});
	return await axiosInterceptor(response, path, 'CREATE');
};

export const serviceGetData = async (account) => {
	const response = axiosConfig({
		method: 'GET',
		url: `/account/get-by-account/${account}`,
	});
	return await axiosInterceptor(response, path);
};

export const serviceUpdateData = async (body) => {
	const response = axiosConfig({
		method: 'PUT',
		url: `/account/`,
		data: body,
	});
	return await axiosInterceptor(response, path, 'UPDATE');
};

export const serviceGetDomains = async () => {
	const response = axiosConfig({
		method: 'GET',
		url: `/select-option/domains`,
	});
	return await axiosInterceptor(response, path);
};

export const serviceGetTypeOptions = async (type) => {
	const response = axiosConfig({
		method: 'GET',
		url: `/select-option/type/${type}`,
	});
	return await axiosInterceptor(response, path);
};
