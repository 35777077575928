/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import Routers from './routers/Routers';
import { QueryClientProvider, QueryClient } from 'react-query';
import { GoogleConstant } from './_config/adapters/google-analitycs/constants';
import { FacebookConstant } from './_config/adapters/facebook-pixel/constants';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			retry: 1,
		},
	},
});

/* Google Analytic */
ReactGA.initialize(GoogleConstant.idGoogle);

/* Facebook Pixel */
ReactPixel.init(FacebookConstant.pixel, undefined, { autoConfig: true, debug: true });

if (process.env.REACT_APP_ENVIROMENT !== 'develop') console.log = () => {};

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<Routers />
	</QueryClientProvider>,
	document.getElementById('root'),
);

serviceWorker.unregister();
