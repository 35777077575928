/** @format */

import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Config } from '../../../../../../_config/constants/config.constant';
import VideoYoutube from './components/VideoYoutube';
import CoverYoutube from './components/CoverYoutube';
import StandarLink from './components/StandarLink';

import './style.css';

export default function LinkBody(props) {
	console.log('LinkBody', props.data.account.video_cover, props);
	const [moduleCustomer] = useState(props.data.module_customer);
	const [effect] = useState(props.data.account.effect || 'fadeIn');
	const [effectOut] = useState(props.data.account.effectOut || 'fadeOut');
	const [colorLink] = useState(props.data.account.color.link || '#210358');
	const [colorText] = useState(props.data.account.color.text || '#210358');
	const [duration] = useState(2000);
	const [links] = useState(props.data.account.links);
	const [theme] = useState(`own-linkbody-content`);
	const [coverActive] = useState(props.data.account.cover_active);
	const [coverUrl] = useState(props.data.account.cover_url);

	const handleFilterDistinct = (item, type) => {
		return item.filter((iterator) => iterator.type !== type);
	};

	const handleTypeLink = (item) => {
		console.log('handleTypeLink', item);
		if (item.type === Config.optionsType.coverVideo) {
			return <VideoYoutube key={item.id} data={item} theme={theme} colorLink={colorLink} text={colorText} />;
		} else {
			return <StandarLink key={item.id} data={item} theme={theme} colorLink={colorLink} text={colorText} />;
		}
	};
	return (
		<>
			<Animated animationIn={effect} animationOut={effectOut} isVisible={true} animationInDuration={duration}>
				{coverActive === true && (
					<>
						<CoverYoutube url={coverUrl} />
					</>
				)}
			</Animated>

			{moduleCustomer.length > 0 && (
				<Animated animationIn={effect} animationOut={effectOut} isVisible={true} animationInDuration={duration}>
					<div
						onClick={() => props.showModal(true)}
						className={theme}
						style={{
							background: colorLink,
							cursor: 'pointer',
						}}
					>
						<img src={'https://i.ibb.co/tXM4xcg/send.png'} alt={'customer'} title={'customer'} />
						<span style={{ color: colorText }}>{moduleCustomer[0].title || 'Deje su mensaje'}</span>
					</div>
				</Animated>
			)}

			<Animated animationIn={effect} animationOut={effectOut} isVisible={true} animationInDuration={duration}>
				{handleFilterDistinct(links, Config.optionsType.coverVideo).map((iterator) => handleTypeLink(iterator))}
			</Animated>
		</>
	);
}
