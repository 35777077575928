/** @format */

import { AxiosAdapter } from '../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../_config/adapters/axios/axios.protocols';

export class Services {
	async sendNotification(body) {
		console.log(body);
		return new AxiosAdapter({
			method: Http.POST,
			url: `http://localhost:8080/notification`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}

	async delete(id) {
		return new AxiosAdapter({
			method: Http.DELETE,
			url: `http://localhost:8080/notification/${id}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
