/** @format */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row, Card, Col, Form } from 'antd';
import { useColor } from 'react-color-palette';

import '../../../../../theme.css';
import 'react-color-palette/lib/css/styles.css';
import { Animated } from 'react-animated-css';

import Loading from '../../../../../components/Loading';
import SelectField from '../../../../../components/Form/Select';
import { CONSTANTS } from '../../../../../components/Constant';
import DrawerOptions from '../../../components/DrawerOptions';

import { serviceGetData, serviceUpdateData } from '../services';

import './style.css';

interface RouterParams {
	account: string;
}

export default function AccountAnimation(props) {
	const { account } = useParams<RouterParams>();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState<any>(true);
	const [link, setLink] = useColor('hex', '#210358');
	const [text, setText] = useColor('hex', '#FFFFFF');
	const [icon, setIcon] = useColor('hex', '#FFFFFF');
	const [effect, setEffect] = useState<any>('fadeIn');
	const [show, setShow] = useState(false);
	const [theme, setTheme] = useState('');
	const [colorLink, setColorLink] = useState();
	const [colorText, setColorText] = useState();

	useEffect(() => {
		console.log('useEffects', 'AccountAnimation', account);
		fetchData(account);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	const fetchData = async (account) => {
		await serviceGetData(account)
			.then((response) => {
				setData(response);
				setLink(link);
				setText(text);
				setIcon(icon);
				setColorLink(response.color.link);
				setColorText(response.color.text);
				setEffect(response.effect || 'fadeIn');
				setLoading(false);
			})
			.catch((e) => console.log(e));
	};

	const handleSubmit = () => {
		data.effect = effect;
		data.theme = theme;
		console.log('data', data);
		serviceUpdateData(data)
			.then((response) => {
				console.log(response);
			})
			.catch((e) => console.log(e));
	};

	if (isLoading) {
		return <Loading />;
	}

	if (data !== undefined) {
		return (
			<>
				<DrawerOptions componentAccount={account} />
				<Row justify="center" className="own-wizzard-global-content">
					<Col xs={23} sm={20} xl={10} className="own-wizzard-main-content">
						<div className="own-wizzard-content">
							<Card className={'own-card-margin-content'} title={'Elije tu propio estilo'} bordered={false}>
								<Form form={form} initialValues={data}>
									<SelectField
										componentLabel={'Elija una animación'}
										componentName={'effect'}
										componentMode={'single'}
										componentPlaceholder={'Seleccione una opción'}
										componentOptions={[...CONSTANTS.EFFECTS]}
										componentRules={'rulesSelect'}
										componentOnChange={(e) => {
											console.log(e);
											setShow(false);
											setEffect(e);
											setTimeout(() => {
												setShow(true);
											}, 1000);
										}}
									/>

									<SelectField
										componentLabel={'Elija un diseño'}
										componentName={'theme'}
										componentMode={'single'}
										componentPlaceholder={'Seleccione una opción'}
										componentOptions={[...CONSTANTS.THEME]}
										componentRules={'rulesSelect'}
										componentOnChange={(e) => {
											console.log(e);
											setShow(false);
											setTheme(e);
											setTimeout(() => {
												setShow(true);
											}, 1000);
										}}
									/>
									{show === true && (
										<>
											<Animated animationIn={effect} animationOut="fadeOut" isVisible={true}>
												{data.links.map((iterator, key) => {
													return (
														<a href={iterator.url} target="_blank" key={key} rel="noopener noreferrer">
															<div style={{ background: colorLink }} className={`own-animation-content ${theme}`}>
																<span style={{ color: colorText }}>{iterator.title}</span>
															</div>
														</a>
													);
												})}
											</Animated>
										</>
									)}

									<Form.Item>
										<div className="own-wizard-actions-container">
											<Button
												className={'own-global-button-submit'}
												onClick={() => {
													handleSubmit();
												}}
											>
												Actualizar
											</Button>
										</div>
									</Form.Item>
								</Form>
							</Card>
						</div>
					</Col>
				</Row>
			</>
		);
	}
}
