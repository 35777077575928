/** @format */

import React from 'react'

import { Form, Select } from 'antd'
import { rulesValidation } from '../Rules'
import './style.css'

const SelectField = (props) => {
	return (
		<>
			<h3 className='own-form-label'>
				{props.componentLabel} {props.componentRules ? '*' : ''}
			</h3>
			<Form.Item name={props.componentName} rules={rulesValidation[props.componentRules]}>
				<Select
					className={props.componentClass || 'own-select'}
					style={{ width: '100%' }}
					mode={props.componentMode}
					placeholder={props.componentPlaceholder}
					onChange={props.componentOnChange}
					maxTagCount={props.componentMaxTagCount}
					disabled={props.componentDisabled}
					options={props.componentOptions.map((iterator, key) => {
						iterator.key = key
						return iterator
					})}></Select>
			</Form.Item>
			<p className='own-form-example-input'>{props.componentExample}</p>
		</>
	)
}

export default SelectField
