import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
const MenuItem = ({ item, addSubMenuItem, deleteMenuItem, parentItem, index, moveItem }) => {
	const [, ref] = useDrag({
		type: 'MENU_ITEM',
	});

	const [, drop] = useDrop({
		accept: 'MENU_ITEM',
		hover: (draggedItem: any) => {
			if (draggedItem.index !== index) {
				moveItem(draggedItem.index, index);
				draggedItem.index = index;
			}
		},
	});

	const handleAddSubMenuItem = () => {
		addSubMenuItem(item);
	};

	const handleDelete = () => {
		deleteMenuItem(parentItem, index);
	};

	return (
		<div ref={(node) => ref(drop(node))} style={{ border: '1px solid #ccc', padding: '8px', marginBottom: '4px' }}>
			<h3>{item.title}</h3>
			<p>URL: {item.url}</p>
			<p>Position: {item.position}</p>
			<button onClick={handleAddSubMenuItem}>Add Submenu</button>
			<button onClick={handleDelete}>Delete Item</button>
			{item.subMenuItems && item.subMenuItems.length > 0 && (
				<div>
					{item.subMenuItems.map((subItem, index) => (
						<MenuItem
							key={index}
							item={subItem}
							addSubMenuItem={addSubMenuItem}
							deleteMenuItem={deleteMenuItem}
							parentItem={item}
							index={index}
							moveItem={moveItem}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default MenuItem;
