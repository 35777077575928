/** @format */

import { Enviroment } from '../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async getByEmail(email) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/account/get-by-email/${email}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
	async delete({ id }) {
		return new AxiosAdapter({
			method: Http.DELETE,
			url: `${Enviroment.HOST}/account/${id}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
