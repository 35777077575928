/** @format */

export const CONSTANTS = {
	MIN_FOLLOWERS: 15000,
	QUANTITY_POST: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
	TIMES: ['Hora(s)', 'Dia(s)', 'Semana(s)', 'Mes(es)', 'Año(s)'],
	SLUG_ADMITED: ['-instagram', '-facebook', '-tiktok', '-youtube'],

	TYPE_ACCOUNT: [
		{ label: 'Uso Personal', value: 'personal' },
		{ label: 'Para mi Negocio', value: 'business' },
	],
	BOOLEAN: [
		{ name: 'No', value: false },
		{ name: 'Si', value: true },
	],
	FORMAT: '.jpeg, .png, .jpg',
	FORMAT_BACKGROUND: '.jpeg, .png, .jpg, .gif',
	TYPE_LINK: [
		{ label: 'Una Red Social', value: 'social' },
		{ label: 'Un Sitio Web', value: 'web' },
		{ label: 'Un WhatsApp', value: 'whatsapp' },
	],
	CURRENCY: [
		{
			label: 'Dólar',
			value: 'USD',
		},
		{
			label: 'Dólar Australiano',
			value: 'AUD',
		},
		{
			label: 'Peso Chileno',
			value: 'CLP',
		},
		{
			label: 'Peso Colombiano',
			value: 'COP',
		},
		{
			label: 'Peso Argentino',
			value: 'ARS',
		},
		{
			label: 'Peso Mexicano',
			value: 'MXN',
		},
		{
			label: 'Bolívares',
			value: 'VES',
		},
	],
	EFFECTS: [
		{
			value: 'fadeIn',
			label: 'Por Defecto',
		},
		{
			value: 'bounce',
			label: 'Bote',
		},
		{
			value: 'flash',
			label: 'Destello',
		},
		{
			value: 'pulse',
			label: 'Legumbres',
		},
		{
			value: 'rubberBand',
			label: 'Banda EláStica',
		},
		{
			value: 'shake',
			label: 'Sacudir',
		},
		{
			value: 'headShake',
			label: 'Sacudida De La Cabeza',
		},
		{
			value: 'swing',
			label: 'Ritmo',
		},
		{
			value: 'tada',
			label: 'Tada',
		},
		{
			value: 'wobble',
			label: 'Tambalearse',
		},
		{
			value: 'jello',
			label: 'Gelatina',
		},
		{
			value: 'bounceIn',
			label: 'Rebotar',
		},
		{
			value: 'bounceInDown',
			label: 'Rebotar Hacia Abajo',
		},
		{
			value: 'bounceInUp',
			label: 'Rebotar Hacia Arriba',
		},
		{
			value: 'fadeInDown',
			label: 'Desvanecerse Hacia Abajo',
		},
		{
			value: 'fadeInDownBig',
			label: 'Desvanecerse En Grande',
		},
		{
			value: 'fadeInUp',
			label: 'Desvanecerse Hacia Arriba',
		},
		{
			value: 'fadeInUpBig',
			label: 'Desvanecerse En Grande',
		},
		{
			value: 'flipInX',
			label: 'Voltear En X',
		},
		{
			value: 'flipInY',
			label: 'Voltear En Y',
		},
		{
			value: 'lightSpeedIn',
			label: 'Velocidad De La Luz En',
		},
		{
			value: 'rotateIn',
			label: 'Girar Hacia Adentro',
		},
		{
			value: 'rollIn',
			label: 'Llegar',
		},
		{
			value: 'zoomIn',
			label: 'Acercarse',
		},
		{
			value: 'zoomInDown',
			label: 'Acercar Hacia Abajo',
		},
		{
			value: 'zoomInUp',
			label: 'Acercar Hacia Arriba',
		},
		{
			value: 'slideInDown',
			label: 'Deslizar Hacia Abajo',
		},
		{
			value: 'slideInUp',
			label: 'Deslizar Hacia Adentro Hacia Arriba',
		},
	],
	THEME: [
		{
			value: 'no-aplica',
			label: 'Por Defecto',
		},
		{
			value: 'theme-1',
			label: 'Tema 1',
		},
		{
			value: 'theme-2',
			label: 'Tema 2',
		},
		{
			value: 'theme-3',
			label: 'Tema 3',
		},
		{
			value: 'theme-4',
			label: 'Tema 4',
		},
		{
			value: 'theme-5',
			label: 'Tema 5',
		},
	],
};
