/** @format */
import React, { useState, useEffect } from 'react';
import { Col, Row, Form } from 'antd';

import Loading from '../../../../../../components/Loading';
import CustomInput from '../../../../../../components/Antd/Input';
import CustomFormItem from '../../../../../../components/Antd/FormItem';
import CustomSelect from '../../../../../../components/Antd/Select';
import CustomCard from '../../../../../../components/Antd/Card';
import CustomJodiEditor from '../../../../../../components/Antd/JodiEditor';
import CustomButton from '../../../../../../components/Antd/Button';

import UploadImageSquare from '../../../../../../components/UploadImageSquare';
import { Config } from '../../../../../../_config/constants/config.constant';

export default function FormProduct(props) {
	const [form] = Form.useForm();
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		setData(props.data);
		setLoading(false);
	}, [props]);

	if (isLoading === true) {
		return <Loading />;
	} else {
		return (
			<>
				<Row justify="center">
					<Col xs={24} md={10}>
						<CustomCard title={props.Label} bordered={false}>
							<Row justify="center">
								<Col span={21}>
									<Form form={form} layout={'vertical'} initialValues={data} onFinish={props.submit}>
										<CustomFormItem label={'Nombre del Producto'} name={'name'} rules={'required'}>
											<CustomInput type={'text'} value={data.name} maxLength={50} />
										</CustomFormItem>
										<UploadImageSquare
											image={data.image}
											title={'Imagen del Producto'}
											name={`producto-${Date.now()}`}
											handle={props.uploadImage}
										/>
										<CustomFormItem label={'Precio de Venta'} name={'price'} rules={'required'}>
											<CustomInput type={'number'} value={data.price} />
										</CustomFormItem>
										<CustomFormItem name={'price_offer'} label={'Precio (Oferta)'}>
											<CustomInput type={'number'} value={data.price_offer} />
										</CustomFormItem>
										<CustomFormItem label={'Tipo de Moneda'} name={'currency'} rules={'required'}>
											<CustomSelect mode={'single'} options={[...Config.currencies]} rules={'required'} />
										</CustomFormItem>

										<CustomJodiEditor
											name={'description'}
											label={'Características (Opcional)'}
											value={data.description}
											example={'Menciona las características mas importantes del producto'}
										/>
										<br></br>
										<CustomButton block htmlType={'submit'}>
											{props.button}
										</CustomButton>
									</Form>
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			</>
		);
	}
}
