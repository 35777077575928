import numberFormatter from 'number-formatter';

export const handleReserve = async ({ products, name, phone, currency }) => {
	let subTotal = 0;
	let total = 0;
	let totalDiscuount = 0;

	const listProducts = await products.map((iterator, key) => {
		console.log('iterator.price_offer', iterator.price_offer, 'iterator.price', iterator.price);
		let subTotalProduct = 0;
		let priceProduct = 0;
		let offerProduct = 0;
		let discountProduct = 0;

		if (iterator.price !== undefined && iterator.price_offer === undefined) {
			priceProduct = iterator.price;
			subTotalProduct = iterator.price * iterator.count;
			discountProduct = 0;
			offerProduct = 0;
		}

		if (iterator.price_offer !== undefined) {
			priceProduct = iterator.price;
			offerProduct = iterator.price_offer;
			subTotalProduct = iterator.price_offer * iterator.count;
			discountProduct = priceProduct - offerProduct;
		}
		subTotal = subTotal + subTotalProduct;
		totalDiscuount = totalDiscuount + discountProduct;
		total = subTotal - totalDiscuount;
		let formatDiscount = discountProduct === 0 ? '0' : numberFormatter('#.###,##', discountProduct);
		return `
                %0A
                %0A======================================
                %0AProducto (${key + 1}): ${iterator.name}
                %0ACantidad: ${iterator.count}
                %0APrecio Regular: ${numberFormatter('#.###,##', priceProduct)} ${iterator.currency}
                %0APrecio Oferta: ${numberFormatter('#.###,##', offerProduct)} ${iterator.currency}
                %0ADescuento: ${formatDiscount} ${iterator.currency}
                %0ASubtotal de ${iterator.count} unidades: ${numberFormatter('#.###,##', subTotalProduct)} ${iterator.currency}
                
            `;
	});
	let formatTotalDiscount = totalDiscuount === 0 ? '0' : numberFormatter('#.###,##', totalDiscuount);
	return `https://api.whatsapp.com/send
			?phone=${phone}
			&text=Hola ${name} me interesan estos producto(s):
			${listProducts}
            %0A
            %0A======================================
            %0ASub Total: ${numberFormatter('#.###,##', subTotal)} ${currency}
            %0ADescuento Total: ${formatTotalDiscount} ${currency}
            %0ATotal a Pagar: ${numberFormatter('#.###,##', total)}  ${currency}
			`;
};
