export const Enviroment = {
	HOST: process.env.REACT_APP_API,
	CONTACT: process.env.REACT_APP_CONTACT,
	DOMAIN: process.env.REACT_APP_DOMAIN,
	ENVIROMENT: process.env.REACT_APP_ENVIROMENT,
	IMBB_API_KEY: process.env.REACT_APP_IMBB_API_KEY,
	LOGO: process.env.REACT_APP_LOGO,
	LOGO_LETRA: process.env.REACT_APP_LOGO_LETRA,
	PHAROL: process.env.REACT_APP_PHAROL,
	PHAROL_FRONT_COMMON: process.env.REACT_APP_PHAROL_FRONT_COMMON,
	PHAROL_RME: process.env.REACT_APP_PHAROL_RME,
	PORT: process.env.REACT_APP_PORT,
	SALESPEOPLE: process.env.REACT_APP_SALESPEOPLE,
	SHARE_LINK: process.env.REACT_APP_SHARE_LINK,
	SUPPORT: process.env.REACT_APP_SUPPORT,
	WHATSAPP: process.env.REACT_APP_WHATSAPP,
};
