/** @format */

import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import Loading from '../../../../../components/Loading';
import PageError from '../../../../../components/Common/PageError';
import DrawerOptions from '../../../components/DrawerOptions';
import CustomNotification from '../../../../../components/Antd/Notification';

import FormComponent from '../components/Form';
import { Services } from '../services';

interface RouterParams {
	account: string;
	id: string;
}

export default function UpdateAgent(props) {
	const history = useHistory();
	const { account, id } = useParams<RouterParams>();
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(false);
	const [data, setData] = useState<any>(undefined);

	useEffect(() => {
		console.log('useEffects', 'UpdateAgent');
		new Services()
			.getOne(id)
			.then((response) => {
				setData(response);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	}, [props, id]);

	const handleOnFinish = async (item) => {
		item._id = data._id;
		item.account = data.account;
		item.links = await item.links.map((item, key) => {
			item.id = key.toString();
			return item;
		});
		new Services()
			.update(item)
			.then((response) => {
				CustomNotification({
					type: TypeNotification.success,
					description: `Se actualico correctamente`,
				});
				history.push(`/profile/agent-wizzard/${account}`);
			})
			.catch((e) => console.log(e));
	};

	if (isLoading === true) {
		return <Loading />;
	}

	if (isError === true) {
		return <PageError errorTitle={'Conexión fallida'} />;
	}
	if (data) {
		return (
			<>
				<DrawerOptions account={account} buttonBack={`/profile/agent-wizzard/${account}`} />
				<FormComponent label={'Actualizar Agente'} data={data} textButton={'Actualizar'} submit={handleOnFinish} />
			</>
		);
	}
}
