export const handleCurrenciesAdapter = (data) => {
	const records = data.map((iterator) => {
		return {
			label: iterator,
			value: iterator,
		};
	});

	return records;
};
