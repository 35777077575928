/** @format */

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import { GetUserStorage } from '../../../../_config/utils/user-storage/get-user-storage.utils';
import CustomButton from '../../../../components/Antd/Button';
import PageError from '../../../../components/Common/PageError';

import Loading from '../../../../components/Loading';

import Accounts from './Accounts';
import Options from './Options';
import { Services } from './services';
import './style.css';

export default function Resume() {
	const history = useHistory();
	const [isUser] = useState(GetUserStorage());
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [data, setData] = useState<any>(null);

	useEffect(() => {
		new Services()
			.getByEmail(isUser.email)
			.then((response) => {
				setData(response);
				setLoading(true);
			})
			.catch((e) => {
				setError(true);
			});
	}, [isUser.email]);

	if (isError === true) {
		return <PageError errorTitle={'Conexión fallida'} />;
	}

	if (isLoading === false) {
		return <Loading />;
	}

	if (data !== null) {
		return (
			<>
				<Row justify={'center'} className="own-resume">
					<Col span={20}>
						<Row justify={'center'}>
							<h3>
								¡Te damos la bienvenida,{' '}
								<span>
									{isUser.first_name} {isUser.last_name}
								</span>
								!
							</h3>
						</Row>
						<CustomButton onClick={() => history.push(`/profile/account-user/`)}>Agregar Link</CustomButton>
						<Accounts data={data} />
						<Options />
					</Col>
				</Row>
			</>
		);
	}
}
