/** @format */

import React, { useEffect, useState } from 'react';
import renderHTML from 'react-render-html';
import { Link, useParams } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
import Loading from '../../components/Loading';

import { serviceGetData } from './service';
import './style.css';

const { Content } = Layout;

export default function Help(props) {
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState(true);
	const { slug } = useParams<any>();
	useEffect(() => {
		console.log('useEffects', 'Help', props);
		serviceGetData('help')
			.then((response) => {
				setData(response);
				setLoading(false);
			})
			.catch((e) => console.log(e));
	}, [props]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<Content className="own-global-main-container">
				<div className="own-help-content">
					<h1 className="own-help-title">¿Necesitas Ayuda?</h1>
				</div>
				<Layout>
					<Row>
						<Col xs={24} sm={24} md={8}>
							<div className="own-help-content">
								{data.map((item, index) => {
									return (
										<li key={index}>
											<Link className={`own-help-list-li ${item.slug === slug ? 'own-active-li' : ''}`} to={`/help/${item.slug}`}>
												{item.title}
											</Link>
										</li>
									);
								})}
							</div>
						</Col>
						<Col xs={24} sm={24} md={16}>
							<div className="own-help-content">
								{data.map((item, index) => {
									return (
										<div key={index}>
											{slug === item.slug && (
												<>
													<h3>{item.title}</h3>
													{renderHTML(item.text_html)}
												</>
											)}
										</div>
									);
								})}
							</div>
						</Col>
					</Row>
				</Layout>
			</Content>
		</>
	);
}
