/** @format */

import React from 'react';
import { Result } from 'antd';
import './style.css';

export default function CustomResult(props) {
	return (
		<>
			<Result {...props} className={'default' || props.className} />
		</>
	);
}
