export const Config = {
	format: '.jpeg, .png, .jpg',
	typeLinks: [
		{ label: 'Un Mensaje a WhatsApp', value: 'whatsapp' },
		{ label: 'Una Red Social', value: 'social' },
		{ label: 'Un Sitio Web', value: 'web' },
	],
	currencies: [
		{
			label: 'Dólar',
			value: 'USD',
		},
		{
			label: 'Dólar Australiano',
			value: 'AUD',
		},
		{
			label: 'Peso Chileno',
			value: 'CLP',
		},
		{
			label: 'Peso Colombiano',
			value: 'COP',
		},
		{
			label: 'Peso Argentino',
			value: 'ARS',
		},
		{
			label: 'Peso Mexicano',
			value: 'MXN',
		},
		{
			label: 'Bolívares',
			value: 'VES',
		},
	],
	optionsType: {
		web: 'web',
		social: 'social',
		whatsapp: 'whatsapp',
		coverVideo: 'cover',
		youtubeVideo: 'youtube',
	},
};
