/** @format */

import React from 'react';
import { Modal } from 'antd';
import './style.css';

export default function CustomModal(props) {
	return (
		<>
			<Modal {...props} className={props.className || 'own-modal'} />
		</>
	);
}
