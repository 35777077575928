/** @format */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import GoogleAnalytics from '../../../../_config/adapters/google-analitycs';
import { GoogleConstant } from '../../../../_config/adapters/google-analitycs/constants';
import Error from '../../../../components/Error';
import Footer from '../../../../components/Footer';
import Biography from '../../components/Biography';
import Promotion from './components/Promotion';
import LinkBody from './components/LinkBody';
import Customer from './components/Customer';
// import Suscriber from './components/Suscriber';
import SkeletonAccount from './components/SkeletonAccount';
import { Services } from './services';
import './style.css';

interface Params {
	account: string;
}

export default function AccountDetail(props) {
	const { account } = useParams<Params>();
	const [data, setData] = useState<any>();
	const [allData, setAllData] = useState();
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(200);
	const [setModule] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const query = queryString.parse(props.location.search);
	const [referal] = useState(query.ref ? query.ref : null);

	useEffect(() => {
		console.log('useEffect', 'AccountDetail', props);
		GoogleAnalytics({ category: GoogleConstant.categories.account, action: GoogleConstant.actions.view, label: account } as any);
		new Services()
			.get(account)
			.then((response) => {
				if (response.account.pixel_facebook) {
					ReactPixel.init(response.account.pixel_facebook, undefined, {
						autoConfig: true,
						debug: true,
					});
					ReactPixel.pageView();
				}
				setAllData(response);
				setData(response.account);
				setLoading(false);
			})
			.catch((e) => {
				setError(500);
				setLoading(false);
				console.log('catch', e);
			});
	}, [props, account]);

	if (isLoading === true) {
		return <SkeletonAccount />;
	}

	if (isError !== 200) {
		return <Error componentStatus={isError} />;
	}

	if (data) {
		return (
			<>
				<Helmet>
					<title>@{data.name} | Creatulink</title>
					<link rel="canonical" href={'https://www.creatulink.com/' + data.name} />
				</Helmet>

				<Promotion />
				<div className="own-account-content">
					<Biography data={data} />
					<Customer
						data={allData}
						params={{
							module: allData,
							account: account,
						}}
						show={showModal}
						change={(e) => setShowModal(e)}
					/>
					<LinkBody onChange={setModule} showModal={(e) => setShowModal(e)} referal={referal} data={allData} account={account} />
					<Footer />
				</div>
			</>
		);
	}

	return <></>;
}
