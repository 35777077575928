/** @format */
import ReactGA from 'react-ga4';
import { GoogleConstant } from './constants';

export default function GoogleAnalytics({
	category = GoogleConstant.categories.event,
	action = GoogleConstant.actions.view,
	label,
	value,
	nonInteraction,
	transport,
}) {
	ReactGA.event({
		action: action,
		category: category,
		label: label,
	});
}
