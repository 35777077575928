/** @format */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Form } from 'antd';
import CustomInput from '../../../components/Antd/Input';
import CustomButton from '../../../components/Antd/Button';
import CustomForm from '../../../components/Antd/Form';
import CustomFormItem from '../../../components/Antd/FormItem';

import { Services } from './services';
import './style.css';

const { Content } = Layout;

export default function Recovery(props) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleOnFinish = (item) => {
		setLoading(true);
		console.log('log handleOnFinish', item);
		new Services()
			.recovery(item)
			.then((response) => {
				console.log(response);
			})
			.catch((e) => console.log(e));
		setLoading(false);
	};
	return (
		<>
			<div className="own-login-content own-login">
				<Content className="own-global-main-container">
					<Row justify="center">
						<Col xs={24} sm={24} md={12}>
							<div className="own-login-card">
								<div className="own-login-card-content">
									<Link to={`/`}>
										<img title="Logo Creatulink" alt="Logo Creatulink" width="100px" src={process.env.REACT_APP_LOGO} />
									</Link>
									<h2>Creatulink</h2>
									<p>Recuperemos tú contraseña</p>
								</div>
								<CustomForm layout="vertical" form={form} onFinish={handleOnFinish}>
									<CustomFormItem name={'email'} label={'Correo electrónico'}>
										<CustomInput placeholder={'Ingrese su E-mail'} type={'text'} />
									</CustomFormItem>

									<CustomButton block loading={loading} htmlType={'submit'}>
										Recuperar
									</CustomButton>
								</CustomForm>
								<div className="own-login-register">
									<Link to={`/auth/register`}>
										¿Aún no estás en Creatulink? <span>Regístrate</span>
									</Link>
								</div>
								<p>Al continuar, aceptas las Condiciones del servicio y la Política de privacidad de Creatulink.</p>
								<div className="own-login-register">
									<Link to={`/`}>
										<span>Volver al inicio</span>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Content>
			</div>
		</>
	);
}
