/** @format */

import React from 'react';
import { Row } from 'antd';
import './style.css';

export default function Footer(props) {
	console.log('Footer');
	return (
		<>
			<Row justify="center">
				<a href="https://www.creatulink.com/" target="_blank" rel="noopener noreferrer">
					<h3 className="own-footer">
						<img src={process.env.REACT_APP_LOGO_LETRA} alt={'Creatulink'} title={'Creatulink'} />
					</h3>
				</a>
			</Row>
		</>
	);
}
