/** @format */

/** @format */

import { axiosInterceptor } from '../../utility/axios-interceptor.utility';
import { axiosConfig } from '../../utility/axios-config.utility';
const path = 'src/pages/help/service.js';

export const serviceGetData = async (type) => {
	const response = await axiosConfig({
		method: 'GET',
		url: `/post/get-type/${type}`,
	});
	return await axiosInterceptor(response, path);
};
