import { TypeNotification } from '../../../../../../../_config/constants/notification.constant';
import CustomNotification from '../../../../../../../components/Antd/Notification';

export const handleAddToCart = async ({ products, oneProduct }) => {
	console.log('in - handleAddToCart', oneProduct);
	let arrayProducts = products;
	const checkProduct = arrayProducts.find((iterator) => {
		return iterator._id === oneProduct._id;
	});

	console.log('check', checkProduct);
	if (checkProduct === undefined) {
		console.log('new product', arrayProducts);
		oneProduct.count = 1;
		arrayProducts.push(oneProduct);
		console.log('new product', arrayProducts);

		CustomNotification({
			type: TypeNotification.success,
			description: `Se ha agregado exitosamente.`,
		});
		return arrayProducts;
	} else {
		console.log('exist product', arrayProducts);
		for (let i = 0; i < arrayProducts.length; i++) {
			if (arrayProducts[i]._id === oneProduct._id) {
				arrayProducts[i].count = arrayProducts[i].count + 1;
			}
		}
		CustomNotification({
			type: TypeNotification.success,
			description: `Se ha agregado una unidad correctamente.`,
		});
		return arrayProducts;
	}
};
