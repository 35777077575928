/** @format */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form } from 'antd';

import CustomButton from '../../../../../../components/Antd/Button';
import CustomSelect from '../../../../../../components/Antd/Select';
import Error from '../../../../../../components/Error';
import SkeletonProduct from '../SkeletonProduct';
import OneGrid from '../OneGrid';
import TwoGrid from '../TwoGrid';
import { Services } from './services';
import './style.css';

export default function Products(props) {
	console.log('Products', props);
	const [form] = Form.useForm();
	const history = useHistory();
	const { accountName, typeProduct, currencies } = props.params;
	const [data, setData] = useState(props.data.records[0].records);
	const [currentCurrency, setCurrentCurrency] = useState(props.data.currencies[0]);
	const [colorLink] = useState(props.data.account.color.link || '#210358');
	const [isLoading, setLoading] = useState(false);
	const [grid, setGrid] = useState('two-grid');
	const [showMore, setShowMore] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [isError] = useState(204);

	const handleGo = (item) => {
		console.log('handleGo', item);
		history.push(`/${accountName}/service/${item._id}`);
	};

	const handleBorderColor = (item) => {
		if (grid === item) {
			return { border: '1px solid #ec407f' };
		}
	};

	const handleChangeCurrency = (item) => {
		setButtonLoading(true);
		setLoading(true);
		setCurrentCurrency(item);
		new Services()
			.get({
				account: accountName,
				type: typeProduct,
				currency: item,
				page: 1,
			})
			.then((response) => {
				console.log('handleChangeCurrency', response);
				setButtonLoading(false);
				setLoading(false);
				setData(response.data);
				hanldeShowButton(1, response.totalPages);
			});
	};

	const handleLoadingMore = () => {
		console.log('handleLoadingMore');
		setButtonLoading(true);
		let page = currentPage + 1;
		setCurrentPage(currentPage + 1);
		new Services()
			.get({
				account: accountName,
				currency: currentCurrency,
				page: page,
			})
			.then((response) => {
				console.log(response);
				setButtonLoading(false);
				const newArray = [].concat(data, response.data);
				setData(newArray);
				hanldeShowButton(page, response.totalPages);
			});
	};

	const hanldeShowButton = (current, totalPage) => {
		if (current >= totalPage) {
			setShowMore(false);
		}
	};

	if (isLoading === true) {
		return <SkeletonProduct />;
	} else {
		return (
			<>
				<Row justify="center" className="own-wizzard-global-content own-product">
					<Col xs={24} sm={24} xl={24} className="own-wizzard-main-content">
						<div className="own-wizzard-content">
							<CustomButton className="own-button-grid" style={handleBorderColor('two-grid')} onClick={() => setGrid('two-grid')}>
								<img src={'https://i.ibb.co/ccwLYYT/grid.png'} alt="two-grid" />
							</CustomButton>
							<CustomButton className="own-button-grid" style={handleBorderColor('one-grid')} onClick={() => setGrid('one-grid')}>
								<img src={'https://i.ibb.co/W6c2f0T/square.png'} alt="one-grid" />
							</CustomButton>

							<Row justify="center" className={'own-currency'}>
								{currencies.length > 0 && (
									<>
										<Col xs={20} sm={20} md={20}>
											<Form form={form}>
												<CustomSelect
													label={'Moneda'}
													name={'currency'}
													mode={'single'}
													placeholder={'Seleccionar'}
													options={[...currencies]}
													value={currentCurrency}
													rules={'rulesSelect'}
													onChange={handleChangeCurrency}
												/>
											</Form>
										</Col>
									</>
								)}
							</Row>

							{data.length <= 0 && (
								<>
									<Error componentStatus={isError} />
								</>
							)}
							{grid === 'one-grid' && (
								<>
									<Row>
										<OneGrid data={data} onGo={handleGo} color={colorLink} />
									</Row>
								</>
							)}

							{grid === 'two-grid' && (
								<>
									<Row>
										<TwoGrid data={data} onGo={handleGo} />
									</Row>
								</>
							)}

							{showMore === true && (
								<Row justify="center">
									<CustomButton type="primary" loading={buttonLoading} onClick={() => handleLoadingMore()}>
										Cargar más
									</CustomButton>
								</Row>
							)}
						</div>
					</Col>
				</Row>
			</>
		);
	}
}
