/** @format */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GetUserStorage } from '../../../_config/utils/user-storage/get-user-storage.utils';

export default function PrivateRoute(props) {
	console.log('props', props);
	let isAuthenticated = GetUserStorage();
	if (isAuthenticated !== null) {
		return <Route {...props} />;
	}
	return <Redirect to="/auth/login" />;
}
