export const extractPhoneAndText = (url) => {
	const urlObj = new URL(url);
	const params = new URLSearchParams(urlObj.search);

	const phone = params.get('phone');
	const text = params.get('text');

	return {
		number: phone,
		message: text,
	};
};
