/** @format */

import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';

import CustomInput from '../Antd/Input';
import CustomButton from '../Antd/Button';
import CustomBreadcrumb from '../Antd/Breadcrumb';
import CustomDropdown from '../Antd/Dropdown';
import CustomRadio from '../Antd/Radio';
import CustomSelect from '../Antd/Select';
import CustomTimePicker from '../Antd/TimePicker';
import CustomCollapse from '../Antd/Collapse';

import UploadImagesAlt from '../UploadImagesAlt';

export default function AllComponents() {
	const [setImageProfile] = useState(undefined);

	/* Dropdown */
	const handleDropdown = (e) => {
		console.log('click left button', e);
	};

	const handleMenuDropdown = (e) => {
		console.log('click', e);
	};

	const items = [
		{
			label: '1st menu item',
			key: '1',
			icon: <UserOutlined />,
		},
		{
			label: '2nd menu item',
			key: '2',
			icon: <UserOutlined />,
		},
		{
			label: '3rd menu item',
			key: '3',
			icon: <UserOutlined />,
		},
	];
	const menuProps = {
		items,
		onClick: handleMenuDropdown,
	};

	/* Radio */
	const [value, setValue] = useState(1);
	const onChangeCustomRadio = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};

	/** Select  */
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	/** Time Picker */
	const onChangeTimePicker = (time, timeString) => {
		console.log(time, timeString);
	};

	/** Collapse */
	const onChangeCollapse = (key) => {
		console.log(key);
	};
	return (
		<>
			<Row justify="center" style={{ marginTop: '20px' }}>
				<Col span={20}>
					<p>Todos los componentes disponibles</p>
				</Col>
			</Row>
			<hr></hr>

			<Row justify="center">
				<Col span={20}>CustomInput</Col>
				<Col span={20}>
					<CustomInput label={'Label Input'} />
				</Col>
			</Row>
			<hr></hr>
			<Row justify="center">
				<Col span={20}>CustomButton</Col>
				<Col span={20}>
					<CustomButton>Label Button</CustomButton>
				</Col>
			</Row>
			<hr></hr>
			<Row justify="center">
				<Col span={20}>CustomBreadcrumb</Col>
				<Col span={20}>
					<CustomBreadcrumb items={['Uno', 'Dos', 'Tres']} />
				</Col>
			</Row>
			<hr></hr>
			<Row justify="center">
				<Col span={20}>Dropdown: </Col>
				<Col span={20}>
					<CustomDropdown menu={menuProps} onClick={handleDropdown} label={'Label Dropdown'} />
				</Col>
			</Row>
			<hr></hr>

			<Row justify="center">
				<Col span={20}>CustomRadio</Col>
				<Col span={20}>
					<CustomRadio
						onChange={onChangeCustomRadio}
						value={value}
						items={[
							{
								label: 'uno',
								value: '1',
							},
							{
								label: 'dos',
								value: '2',
							},
							{
								label: 'tres',
								value: '3',
							},
						]}
					/>
				</Col>
			</Row>
			<hr></hr>

			<Row justify="center">
				<Col span={20}>CustomSelect</Col>
				<Col span={20}>
					<CustomSelect
						defaultValue="lucy"
						style={{ width: 120 }}
						onChange={handleChange}
						options={[
							{
								value: 'jack',
								label: 'Jack',
							},
							{
								value: 'lucy',
								label: 'Lucy',
							},
							{
								value: 'disabled',
								disabled: true,
								label: 'Disabled',
							},
							{
								value: 'Yiminghe',
								label: 'yiminghe',
							},
						]}
					/>
				</Col>
			</Row>
			<hr></hr>

			<Row justify="center">
				<Col span={20}>CustomTimePicker</Col>
				<Col span={20}>
					<CustomTimePicker onChange={onChangeTimePicker} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
				</Col>
			</Row>
			<hr></hr>

			<Row justify="center">
				<Col span={20}>CustomCollapse</Col>
				<Col span={20}>
					<CustomCollapse
						defaultActiveKey={['1']}
						onChange={onChangeCollapse}
						items={[
							{
								header: 'Titulo 1',
								text: 'texto 1',
							},
							{
								header: 'Titulo 2',
								text: 'texto 2',
							},
							{
								header: 'Titulo 3',
								text: 'texto 3',
							},
						]}
					/>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={20}>
					<p>Subir Imagen</p>
					<UploadImagesAlt title={'Título'} componentName={`${'hola'}-${Date.now()}`} componentHandle={setImageProfile} />
				</Col>
			</Row>

			<br></br>
		</>
	);
}
