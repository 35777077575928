/** @format */

import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { TypeNotification } from '../../../../_config/constants/notification.constant';

import CustomFormItem from '../../../../components/Antd/FormItem';
import CustomRadioButton from '../../../../components/Antd/RadioButton';
import CustomButton from '../../../../components/Antd/Button';
import CustomInput from '../../../../components/Antd/Input';
import CustomModal from '../../../../components/Antd/Modal';
import CustomNotification from '../../../../components/Antd/Notification';

import { Services } from './services';
import './style.css';

export default function ModalConfig(props) {
	console.log('ModalConfig', props);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [showTitle] = useState(props.showTitle === false ? false : true);
	const [showPhone] = useState(props.showPhone === false ? false : true);
	const [data, setData] = useState<any>({
		title: '',
		phone: '',
		eneable: '',
	});

	useEffect(() => {
		new Services().get({ account: props.account, module: props.module }).then((response) => {
			setData(response);
		});
	}, [props]);

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleConfiguration = () => {
		setIsModalVisible(true);
		new Services().get({ account: props.account, module: props.module }).then((response) => {
			setData(response);
			form.resetFields(['phone', 'title']);
		});
	};

	const handleOnFinish = (item) => {
		item._id = data._id;
		new Services().update(item).then((response) => {
			CustomNotification({
				type: TypeNotification.success,
				description: `Se actualizo correctamente`,
			});
			setIsModalVisible(false);
		});
	};

	return (
		<>
			<CustomButton key={'config'} size={'large'} className={'own-default-button'} icon={<SettingOutlined />} onClick={() => handleConfiguration()} />
			<CustomModal title={props.title} visible={isModalVisible} onCancel={handleCancel} footer={null}>
				<Form layout={'vertical'} form={form} initialValues={data} onFinish={handleOnFinish}>
					<CustomFormItem rules={'required'} name={'eneable'}>
						<CustomRadioButton
							value={data.eneable}
							label={'Estado'}
							items={[
								{
									label: 'Mostrar',
									value: true,
								},
								{
									label: 'Ocultar',
									value: false,
								},
							]}
						/>
					</CustomFormItem>
					<br></br>
					{showTitle === true && (
						<>
							<CustomFormItem name={'title'} label={'Título'} rules={'required'} extra={'Modifica el nombre que se muestra a tus clientes'}>
								<CustomInput
									placeholder={'Título del módulo'}
									type={'text'}
									value={data.title}
									onChange={props.changeTitle}
									minLength={3}
									maxLength={50}
								/>
							</CustomFormItem>
						</>
					)}

					{showPhone === true && (
						<>
							<CustomFormItem
								name={'phone'}
								label={'WhatsApp'}
								rules={'rulesPhone'}
								extra={'Escribe el número de WhatsApp al que llegarán las solicitudes'}
							>
								<CustomInput placeholder={'56999999999'} type={'text'} value={data.phone} onChange={props.changeTitle} />
							</CustomFormItem>
						</>
					)}

					<CustomButton block htmlType={'submit'}>
						Guardar Cambios
					</CustomButton>
				</Form>
			</CustomModal>
		</>
	);
}
