/** @format */

import { Col, Form, Layout, Row } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TypeNotification } from '../../../_config/constants/notification.constant';
import { SetUserStorage } from '../../../_config/utils/user-storage/set-user-storage.utils';
import CustomButton from '../../../components/Antd/Button';
import CustomForm from '../../../components/Antd/Form';
import CustomFormItem from '../../../components/Antd/FormItem';
import CustomInput from '../../../components/Antd/Input';
import CustomNotification from '../../../components/Antd/Notification';
import { Services } from './services';
import './style.css';

const { Content } = Layout;

export default function Register() {
	const history = useHistory();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleOnFinish = (item) => {
		console.log(item);

		new Services()
			.register(item)
			.then((response) => {
				setLoading(false);
				CustomNotification({
					type: TypeNotification.success,
					description: 'Se ha creado tu cuenta con éxito',
				});
				SetUserStorage(item);

				setTimeout(() => {
					history.push('/profile/account-user');
				}, 2000);
			})
			.catch((e) => {
				console.log('error', e);
				CustomNotification({
					type: TypeNotification.error,
					description: e.message,
				});
			});
	};

	return (
		<>
			<div className="own-login-content">
				<Content className="own-global-main-container">
					<Row className="own-login-conteent-row" align="middle" justify="center">
						<Col xs={24} sm={12} md={12}>
							<div className="own-login-content-logins">
								<div className="own-login-content-logins-center">
									<Link to={`/`}>
										<img title="Logo Creatulink" alt="Logo Creatulink" width="100px" src={process.env.REACT_APP_LOGO} />
									</Link>
									<h2 className="own-login-title">Bienvenido a Creatulink</h2>
									<p className="own-login-sub-title">Un link profesional para compartir</p>
								</div>
								<div className="own-login-content-redes-main">
									<CustomForm form={form} onFinish={handleOnFinish} layout="vertical">
										<CustomFormItem name={'first_name'} label={'Nombre'} rules={'required'}>
											<CustomInput rules={'required'} placeholder={'Ingrese su Nombre'} type={'text'} />
										</CustomFormItem>
										<CustomFormItem name={'last_name'} label={'Apellido'} rules={'required'}>
											<CustomInput placeholder={'Ingrese su Apellido'} type={'text'} />
										</CustomFormItem>
										<CustomFormItem name={'email'} label={'Correo electrónico'} rules={'email'}>
											<CustomInput placeholder={'Ingrese su E-mail'} type={'email'} />
										</CustomFormItem>
										<CustomFormItem name={'password'} label={'Contraseña'} rules={'required'}>
											<CustomInput placeholder={'Ingrese su contraseña'} type={'password'} />
										</CustomFormItem>
										<CustomButton block loading={loading} htmlType={'submit'}>
											Registrarme
										</CustomButton>
									</CustomForm>
									<div className="own-login-content-register">
										<Link to={`/auth/login`}>
											¿Ya tienes cuenta? <span>Inicia Sesión</span>
										</Link>
									</div>
								</div>
								<div style={{ textAlign: 'center' }}>
									<Link to={`/`}>
										<span className="">Volver al Inicio</span>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Content>
			</div>
		</>
	);
}
