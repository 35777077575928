/** @format */

import React from 'react';

import { Link } from 'react-router-dom';
import './style.css';

export default function Logo() {
	return (
		<Link className="own-navbar-logo-link-container" to="/profile/resume">
			<img className="own-navbar-logo" src={process.env.REACT_APP_LOGO} alt="Logo Creatulink" />
			<h1 className="own-navbar-logo-titles">Creatulink</h1>
		</Link>
	);
}
