/** @format */

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import numberFormatter from 'number-formatter';

import CustomButton from '../../../../../../components/Antd/Button';
import CustomCheckbox from '../../../../../../components/Antd/Checkbox';

import './style.css';

export default function DragLinks(props) {
	const [links, setLinks] = useState(props.data);
	useEffect(() => {
		console.log('useEffect', props);
		setLinks(props.data);
	}, [props]);

	const handleOnDragEnd = async (result) => {
		if (!result.destination) return;
		const items = Array.from(links);
		const [reOrderItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reOrderItem);
		const newLinks = await items.map((item:any, key) => {
			item.id = key.toString();
			return item;
		});
		setLinks([...newLinks]);
	};

	const handleChecked = (key) => {
		let arrayLinks = links;
		arrayLinks[key].eneable = arrayLinks[key].eneable === true ? false : true;
		setLinks([...arrayLinks]);
	};

	const handleReset = () => {
		const resetLinks = links.map((iterator) => {
			iterator.count = 0;
			return iterator;
		});
		setLinks([...resetLinks]);
	};
	return (
		<>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<Droppable droppableId="characters">
					{(provided) => (
						<ul className="own-draglinks" {...provided.droppableProps} ref={provided.innerRef}>
							{links.map((item, key) => {
								return (
									<Draggable key={item.id.toString()} draggableId={item.id.toString()} index={key}>
										{(provided) => (
											<li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
												<Row>
													<Col span={14}>{item.phone}</Col>
													<Col span={3}>
														<CustomButton
															className="own-none-button"
															size={'large'}
															icon={<DeleteOutlined />}
															onClick={() => {
																props.delete(key);
															}}
														></CustomButton>
													</Col>
													<Col span={2}>
														<CustomCheckbox onChange={(e) => handleChecked(key)} checked={item.eneable} />
													</Col>
													<Col span={5}>Clicks: {numberFormatter('#.###,##', item.count)}</Col>
												</Row>
											</li>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
			<Row justify="center" align="middle" gutter={16}>
				<Col span={12}>
					<CustomButton block onClick={handleReset} className={'own-default-button'}>
						Reiniciar
					</CustomButton>
				</Col>
				<Col span={12}>
					Enlaces Agregados: <span>{links.length}</span>
				</Col>
			</Row>
		</>
	);
}
