export const GoogleConstant = {
	idGoogle: 'G-5TH0WJYWG0',
	actions: {
		click: 'click',
		view: 'view',
	},
	categories: {
		account: 'account',
		navbar: 'navbar',
		promotion: 'promotion',
		event: 'event',
	},
	labels: {
		biography: 'biography',
	},
};
