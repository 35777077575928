export const handleDeleteToCart = ({ products, oneProduct }) => {
	console.log('in - handleDeleteToCart', oneProduct);
	let arrayProducts = products;

	for (let i = 0; i < arrayProducts.length; i++) {
		if (arrayProducts[i]._id === oneProduct._id) {
			arrayProducts[i].count = arrayProducts[i].count - 1;

			if (arrayProducts[i].count === 0) {
				arrayProducts.splice(i, 1);
			}
		}
	}
	console.log('array', arrayProducts);

	return arrayProducts;
};
