/** @format */

import React from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import CustomButton from '../Antd/Button';
import Logo from './Logo';
import User from './User';

import './style.css';

export default function Header() {
	const history = useHistory();
	return (
		<div className="own-navbar-header-content">
			<div className="own-navbar-content">
				<Row>
					<Col xs={6} sm={6} md={3}>
						<Logo />
					</Col>
					<Col xs={13} sm={12} md={14} className="own-navbar-header-content-2">
						<CustomButton className="own-none-button" onClick={() => history.push('/help/quienes-somos')}>
							Ayuda
						</CustomButton>
					</Col>
					<Col xs={5} sm={6} md={7}>
						<User />
					</Col>
				</Row>
			</div>
		</div>
	);
}
