import React, { useEffect } from 'react';
import Menu from './components/Menu';
export default function ReactDndPage() {
	useEffect(() => {}, []);

	return (
		<>
			ReactDndPage
			<Menu />
		</>
	);
}
