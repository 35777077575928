/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Col, Card, Badge } from 'antd';
import numberFormatter from 'number-formatter';

import './style.css';
export default function TwoGrid(props) {
	const [data, setData] = useState(props.data);

	useEffect(() => {
		setData(props.data);
		console.log('useEffect', 'TwoGrid', props);
	}, [props]);

	const handleProductPrice = (item) => {
		if (item.price_offer) {
			return (
				<>
					<span className={'own-twogrid-old-price'}>
						{numberFormatter('#.###,##', item.price)} {item.currency}
					</span>{' '}
					<span className={'own-twogrid-new-price'}>
						{numberFormatter('#.###,##', item.price_offer)} {item.currency}
					</span>{' '}
				</>
			);
		} else {
			return (
				<span className={'own-twogrid-original-price'}>
					{numberFormatter('#.###,##', item.price)} {item.currency}
				</span>
			);
		}
	};

	return (
		<>
			{data.map((iterator, key) => {
				return (
					<Col xs={12} sm={12} xl={12} key={'col' + key}>
						<Card
							className={'own-card-product own-twogird-content'}
							key={'card' + key}
							cover={
								<Badge count={iterator.count}>
									<img alt={iterator.name} src={iterator.image} />
								</Badge>
							}
							actions={[
								<Button size="small" className="own-twogrid-button-add" onClick={() => props.onGo(iterator)}>
									Ver Detalle
								</Button>,
							]}
						>
							<div className="own-twogrid-container">
								<div className="own-twogrid-name">{iterator.name}</div>
								<div className="own-twogrid-price-content">{handleProductPrice(iterator)}</div>
							</div>
						</Card>
					</Col>
				);
			})}
		</>
	);
}
