/** @format */

import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TypeNotification } from '../../../../_config/constants/notification.constant';
import CustomButton from '../../../../components/Antd/Button';
import CustomCard from '../../../../components/Antd/Card';
import CustomForm from '../../../../components/Antd/Form';
import CustomFormItem from '../../../../components/Antd/FormItem';
import CustomInput from '../../../../components/Antd/Input';
import CustomNotification from '../../../../components/Antd/Notification';
import CustomSelect from '../../../../components/Antd/Select';
import Loading from '../../../../components/Loading';
import CoverYoutube from '../../../../components/CoverYoutube';
import DrawerOptions from '../../components/DrawerOptions';
import { Services } from './services';
import './style.scss';

interface RouterParams {
	account: string;
}

export default function VideoCover(props) {
	const history = useHistory();
	const { account } = useParams<RouterParams>();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>(undefined);
	const [isLoading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [cover, setCover] = useState(false);

	useEffect(() => {
		console.log('useEffects', 'VideoCover');
		new Services().get(account).then((response) => {
			setData(response);
			setShow(response.cover_active);
			setCover(response.cover_url);
			setLoading(false);
		});
	}, [account]);

	const handleOnFinish = (item) => {
		item._id = data._id;
		item.account = account;
		console.log('guardando registros', item);
		new Services().update(item).then(() => {
			CustomNotification({
				type: TypeNotification.success,
				description: `Se actualizo correctamente`,
			});

			setTimeout(() => {
				history.push(`/profile/modules/${account}`);
			}, 2000);
		});
	};

	if (isLoading === true) {
		return <Loading />;
	}
	return (
		<>
			<DrawerOptions account={account} />
			<Row justify="center">
				<Col xs={22} md={10}>
					<CustomCard title={'Agrega una presentación'} bordered={false}>
						<Row justify="center">
							<Col span={21}>
								<CustomForm layout="vertical" form={form} initialValues={data} onFinish={handleOnFinish}>
									<CustomFormItem name={'cover_active'} label={'¿Quieres mostrar un video de presentación?'} rules={'required'}>
										<CustomSelect
											options={[
												{
													label: 'Si, activar',
													value: true,
												},
												{
													label: 'No, ocultar',
													value: false,
												},
											]}
											onChange={(e) => {
												console.log(e);
												setShow(e);
											}}
										/>
									</CustomFormItem>

									<CustomFormItem
										name={'cover_url'}
										label={'Agrega el link de tu video'}
										rules={'video_cover'}
										extra={'Sólo se admiten videos subidos en Youtube'}
									>
										<CustomInput
											value={data.cover_url}
											type={'text'}
											onChange={(e) => {
												console.log(e.target.value);
												setCover(e.target.value);
											}}
											disabled={!show}
										/>
									</CustomFormItem>

									{cover !== undefined && show === true && (
										<>
											<p>Vista previa de tu video</p>
											<CoverYoutube url={cover} />
										</>
									)}

									<CustomButton block htmlType={'submit'}>
										Guardar
									</CustomButton>
								</CustomForm>
							</Col>
						</Row>
					</CustomCard>
				</Col>
			</Row>
		</>
	);
}
