/** @format */

import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import libphonenumber from 'google-libphonenumber';

import CustomInput from '../../../../../../components/Antd/Input';
import CustomButton from '../../../../../../components/Antd/Button';
import CustomTextArea from '../../../../../../components/Antd/TextArea';
import CustomSelect from '../../../../../../components/Antd/Select';
import CustomDrawer from '../../../../../../components/Antd/Drawer';
import CustomFormItem from '../../../../../../components/Antd/FormItem';

import { Services } from './services';
import './style.css';

const { PhoneNumberUtil } = libphonenumber;
const phoneUtil = PhoneNumberUtil.getInstance();

export default function Customer(props) {
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { module, account } = props.params;
	const [optionsCountry, setOptionsCountry] = useState([]);
	const [code, setCode] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const [prefixCountry, setPrefixCountry] = useState(null);

	useEffect(() => {
		console.log('useEffects', 'ModalCustomer', props);
		if (isModalVisible) {
			new Services()
				.getOptions('country')
				.then((response) => {
					setOptionsCountry([...response] as any);
				})
				.catch((e) => console.log(e));
		}
		setIsModalVisible(props.show);
	}, [props, isModalVisible]);

	const handleOnClose = () => {
		props.change(false);
	};

	const handleChangeCountry = (e) => {
		setCode(e);
		form.resetFields(['phone']);
		const findOption: any = optionsCountry.find((iterator: any) => iterator.value === e);
		setPrefixCountry(findOption.prefix);
	};

	const handlePhoneNumberChange = (value) => {
		setPhoneNumber(value);
		try {
			const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(value, prefixCountry);
			setIsPhoneNumberValid(phoneUtil.isValidNumber(parsedPhoneNumber));
		} catch (error) {
			setIsPhoneNumberValid(false);
		}
	};

	const handleOnFinish = async (item) => {
		console.log(optionsCountry);
		let findCountry: any = optionsCountry.find((iterator: any) => iterator.value === code);
		item.account = account;
		item.country = findCountry.name;
		item.code = findCountry.value;
		item.type = 'customer';
		console.log(item);
		new Services().create(item).then((response) => {
			console.log(response);
			form.resetFields();
			props.change(false);
		});
	};

	if (module.module_customer === false) return <></>;

	return (
		<>
			<CustomDrawer title={module.title} visible={isModalVisible} onClose={handleOnClose} footer={null} placement={'right'} width={400}>
				<Form layout="vertical" form={form} onFinish={handleOnFinish}>
					<CustomFormItem name={'name'} label={'Tu Nombre'} rules={'required'}>
						<CustomInput type={'text'} maxLength={50} />
					</CustomFormItem>
					<CustomFormItem name={'email'} label={'Tu Correo'} rules={'email'}>
						<CustomInput type={'text'} maxLength={50} />
					</CustomFormItem>
					<CustomFormItem name={'country'} label={'¿De què paìs nos escribes?'} rules={'required'}>
						<CustomSelect mode={'single'} placeholder={'Seleccione una opción'} options={[...optionsCountry]} onChange={handleChangeCountry} />
					</CustomFormItem>

					<Row>
						<Col span={4}>
							<CustomFormItem name={'code'} label={'Código'}>
								<CustomInput disabled={true} placeholder={code} value={code} />
							</CustomFormItem>
						</Col>
						<Col span={2}></Col>
						<Col span={18}>
							<CustomFormItem
								name={'phone'}
								label={'Su número de WhatsApp'}
								rules={'required'}
								validateStatus={isPhoneNumberValid ? 'success' : 'error'}
								help={isPhoneNumberValid ? null : 'Ingrese un número válido'}
							>
								<CustomInput value={phoneNumber} type={'number'} onChange={(e) => handlePhoneNumberChange(e.target.value)} />
							</CustomFormItem>
						</Col>
					</Row>

					<CustomFormItem name={'subject'} label={'¿En qué te podemos ayudar?'} rules={'required'}>
						<CustomTextArea type={'text'} rows={4} />
					</CustomFormItem>
					<CustomButton block htmlType={'submit'}>
						Enviar
					</CustomButton>
				</Form>
			</CustomDrawer>
		</>
	);
}
