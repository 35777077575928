/** @format */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Menu, Dropdown } from 'antd';
import { UserOutlined, ExportOutlined, SafetyOutlined, QuestionOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import CustomButton from '../../Antd/Button';

import { Storage } from '../../../_config/constants/storage.constant';
import { RemoveStorage } from '../../../_config/utils/remove-storage.utils';
import { GetUserStorage } from '../../../_config/utils/user-storage/get-user-storage.utils';

import SideBar from '../SideBar';

import './style.css';

const menu = (
	<Menu>
		<Menu.Item>
			<Link to={`/profile/resume`}>
				<div className="cv-header-user-icon-login-content">
					<UserOutlined style={{ fontSize: 16 }} />
					<span className="ml10">Mi Cuenta</span>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to={`/help/quienes-somos`}>
				<div className="cv-header-user-icon-login-content">
					<QuestionOutlined style={{ fontSize: 16 }} />
					<span className="ml10">Ayuda</span>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to={`/profile/change-password`}>
				<div className="cv-header-user-icon-login-content">
					<SafetyOutlined style={{ fontSize: 16 }} />
					<span className="ml10">Cambiar Contraseña</span>
				</div>
			</Link>
		</Menu.Item>
		<Menu.Item>
			<Link onClick={() => RemoveStorage(Storage.user)} to={`/`}>
				<div className="cv-header-user-icon-login-content">
					<ExportOutlined style={{ fontSize: 16 }} />
					<span className="ml10">Cerrar Sesión</span>
				</div>
			</Link>
		</Menu.Item>
	</Menu>
);

export default function User() {
	const history = useHistory();
	const [isMenu, setMenu] = useState(false);
	const [isUser] = useState(GetUserStorage);

	const handleOpenMenu = (item) => {
		setMenu(item);
	};

	return (
		<div className="cv-header-user-content">
			<Row align="middle" className="cv-header-user-desktop">
				{isUser ? (
					<Col xs={12} sm={12} md={{ span: 24, offset: 0 }}>
						<Row align="middle">
							<Col span={12}>
								<CustomButton className="own-none-button" onClick={() => history.push('/profile/account-user/')}>
									Crear
								</CustomButton>
							</Col>

							<Col span={7}>
								<Dropdown overlay={menu} placement="bottomRight" arrow>
									<div className="cv-header-user-image-container">
										<img
											className="cv-heder-user-image-user"
											src={isUser.image ? isUser.image : 'https://i.postimg.cc/YSQXZWCP/logo.jpg'}
											alt="Imagen de Usuario"
										/>
									</div>
								</Dropdown>
							</Col>
						</Row>
					</Col>
				) : (
					<Col xs={12} sm={12} md={24}>
						<Row align="middle">
							<Col xs={10} sm={10} md={10}>
								<Link to={`/auth/login`}>
									<div className="cv-header-user-icon-init-sesion">Iniciar sesión</div>
								</Link>
							</Col>
							<Col xs={10} sm={10} md={10}>
								<Link to={`/auth/register`}>
									<div className="cv-header-user-icon-login-content">
										<img className="cv-header-user-icon-login" src="https://i.ibb.co/0C5Mpp9/iniciar-sesion.png" alt="Crear Cuenta" /> Crear
										Cuenta
									</div>
								</Link>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
			<Row align="middle" className="cv-header-user-mobil">
				<Col xs={6} sm={4} md={4}></Col>
				<Col xs={18} sm={20} md={20}>
					<img
						onClick={() => {
							console.log('click');
							setMenu(true);
						}}
						className="cv-header-user-icon-menu"
						src="https://i.ibb.co/mzMWjY8/categorias.png"
						alt="Menu"
					/>
				</Col>
			</Row>
			<SideBar handleOpenMenu={() => handleOpenMenu(false)} menu={isMenu} />
		</div>
	);
}
