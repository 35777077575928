/** @format */

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DrawerOptions from '../../../components/DrawerOptions';

import FormComponent from '../components/Form';
import { Services } from '../services';
import './style.css';

interface RouterParams {
	account: string;
}

export default function CreateAgent(props) {
	const history = useHistory();
	const { account } = useParams<RouterParams>();

	const [isHelp, setHelp] = useState(false);
	const [data] = useState({
		title: '',
		message: '',
		phone: '',
		links: [],
	});

	useEffect(() => {
		console.log('useEffects', 'CreateAgent');
	}, [props]);

	const handleOnFinish = async (item) => {
		item.account = account;
		item.links = await item.links.map((item, key) => {
			item.id = key.toString();
			return item;
		});
		new Services().create(item).then((response) => {
			history.push(`/profile/agent-wizzard/${account}`);
		});
	};

	return (
		<>
			<DrawerOptions account={account} buttonBack={`/profile/agent-wizzard/${account}`} />
			<FormComponent
				label={'Crear Vendedor'}
				data={data}
				textButton={'Guardar'}
				submit={handleOnFinish}
				help={isHelp}
				handleHelp={() => setHelp(false)}
			/>
		</>
	);
}
