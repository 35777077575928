import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'antd';
import renderHTML from 'react-render-html';

import Footer from '../../../../../components/Footer';
import CustomButton from '../../../../../components/Antd/Button';
import CustomCard from '../../../../../components/Antd/Card';

import SkeletonAccount from '../components/SkeletonProduct';
import Biography from '../../../components/Biography';
import VideoYoutube from './components/VideoYoutube';

import { onReserve } from './handles/on-reserve.handle';
import { Config } from './constants/config.constant';
import { Services } from './services';
import './style.scss';

export default function DetailService(props) {
	console.log('DetailService', props);
	const { id, account } = useParams<any>();
	const [data, setData] = useState<any>();
	const [service, setService] = useState<any>();
	const [module, setModule] = useState<any>();
	const [isLoading, setLoading] = useState<any>(true);

	console.log(props);

	useEffect(() => {
		new Services()
			.getService(id)
			.then((response) => {
				console.log('getService', response);
				setService(response);
			})
			.catch((e) => {
				console.log('error', e);
			});
	}, [id]);

	useEffect(() => {
		new Services()
			.getAccount(account)
			.then((response) => {
				console.log('getAccount', response);
				setData(response.account);
				setModule(response.module_service[0]);
				setLoading(false);
			})
			.catch((e) => {
				console.log('error', e);
			});
	}, [account]);

	const handleOnReserve = (item) => {
		console.log('handleOnReserve', item);
		const result = onReserve(item, account);
		window.open(`${Config.apiWhatsapp}?phone=${module?.phone}&text=${result}`, '_blank');
	};

	if (isLoading === true) {
		return <SkeletonAccount />;
	}

	if (data) {
		return (
			<>
				<Helmet>
					<title>@{data.name} | Creatulink</title>
					<link rel="canonical" href={'https://www.creatulink.com/' + data.name} />
				</Helmet>

				<div className="own-detail-service">
					<Biography data={data} back={true} />
					{service && (
						<>
							<Row>
								<Col span={24}>
									<CustomCard
										title={service.name}
										actions={[
											<CustomButton onClick={() => handleOnReserve(service)} type="primary">
												Me interesa
											</CustomButton>,
										]}
									>
										<Row justify="center">
											<Col span={24}>
												<img width={'100%'} src={service.image} alt={service.name} />
											</Col>
										</Row>
										<Row justify={'jusify' as any} className="description">
											<Col span={24}>
												<h2>¿Qué incluye?</h2>
											</Col>
											<Col span={24} className="render-html">
												{renderHTML(service.description)}
											</Col>
										</Row>
										{service.faq && (
											<>
												<Row justify={'jusify' as any} className="description">
													<Col span={24}>
														<h2>Aclaratorias</h2>
													</Col>
													<Col span={24} className="render-html">
														{renderHTML(service.faq)}
													</Col>
												</Row>
											</>
										)}

										{service.video && (
											<>
												<Row justify={'jusify' as any} className="description">
													<Col span={24}>
														<h2>Explicación detallada</h2>
													</Col>
													<Col span={24} className="render-html">
														<VideoYoutube video={service.video} />
													</Col>
												</Row>
											</>
										)}
									</CustomCard>
								</Col>
							</Row>
						</>
					)}
				</div>

				<Footer />
			</>
		);
	}
	return <></>;
}
