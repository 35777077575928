/** @format */
import { Enviroment } from '../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../_config/adapters/axios/axios.protocols';

export class Services {
	async recovery(body) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/auth/recovery-password`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
