/** @format */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import GoogleAnalytics from '../../../../../../_config/adapters/google-analitycs';
import { GoogleConstant } from '../../../../../../_config/adapters/google-analitycs/constants';
import CustomDrawer from '../../../../../../components/Antd/Drawer';
import CustomButton from '../../../../../../components/Antd/Button';

import './style.css';

export default function NavBarLink(props) {
	console.log('BackLink', props);
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [placement] = useState('bottom');
	const [account] = useState(props.account);
	const [color] = useState(props.color);
	const [back] = useState(props.back || false);

	const handleShowDrawer = () => {
		GoogleAnalytics({ action: GoogleConstant.actions.click, category: GoogleConstant.categories.navbar, label: GoogleConstant.labels.biography } as any);
		setOpen(true);
	};

	const handleOnClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className="own-linkmenu-content">
				{back === true && (
					<>
						<div className="own-linkmenu-left" onClick={() => history.goBack()}>
							<img
								src={'https://i.ibb.co/YWZqpKQ/left-arrow-1.png'}
								alt={'home'}
								title={'home'}
								style={{
									background: color,
									borderColor: color,
								}}
							/>
						</div>
					</>
				)}
				<div className="own-linkmenu-right" onClick={handleShowDrawer}>
					<img
						src={'https://i.ibb.co/nCXPd8b/ask.png'}
						alt={'home'}
						title={'home'}
						style={{
							background: color,
							borderColor: color,
						}}
					/>
				</div>
			</div>

			<CustomDrawer
				title={`@${account} te invita a:`}
				placement={placement}
				height={200}
				closable={false}
				onClose={handleOnClose}
				visible={open}
				className="own-linkmenu-drawer"
			>
				<CustomButton
					type="text"
					block
					onClick={() => {
						history.push(`/auth/register`);
					}}
				>
					Crear un link como este Gratis
				</CustomButton>

				<CustomButton
					type="text"
					block
					onClick={() => {
						history.push(`/help/quienes-somos`);
					}}
				>
					Preguntas frecuentes
				</CustomButton>
			</CustomDrawer>
		</>
	);
}
