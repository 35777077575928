/** @format */

import React from 'react';
import { Card } from 'antd';
import './style.css';

export default function CustomCard(props) {
	return (
		<>
			<Card {...props} className={props.className || 'own-card'} />
		</>
	);
}
