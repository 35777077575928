/** @format */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Row, Col, notification } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';

import { Substring } from '../../../../../_config/hooks/substring.hook';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import Loading from '../../../../../components/Loading';
import PageError from '../../../../../components/Common/PageError';
import DrawerOptions from '../../../components/DrawerOptions';
import ModalConfig from '../../../components/ModalConfig';
import CustomCard from '../../../../../components/Antd/Card';
import CustomButton from '../../../../../components/Antd/Button';
import CustomNotification from '../../../../../components/Antd/Notification';

import { Services } from '../services';
import './style.css';

interface RouterParams {
	account: string;
}

export default function Agents(props) {
	const history = useHistory();
	const { account } = useParams<RouterParams>();
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(false);
	const [data, setData] = useState<any>(true);

	useEffect(() => {
		console.log('useEffect', 'LinkAgent', account);
		new Services()
			.get(account)
			.then((response) => {
				setData(response);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	}, [props, account]);

	const handleUpdate = (item) => {
		history.push(`/profile/agent-update/${account}/${item._id}`);
	};

	const handleDelete = (item) => {
		let btn = (
			<Button
				className="own-global-button-submit"
				onClick={() => {
					new Services()
						.get(item._id)
						.then((response) => {
							setData(
								data.filter((iterator) => {
									return iterator._id !== item._id;
								}),
							);
							CustomNotification({
								type: TypeNotification.success,
								description: `Se eliminó correctamente`,
							});
							notification.close('close-notification');
						})
						.catch((e) => console.log(e));
				}}
			>
				Confirmar
			</Button>
		);

		CustomNotification({
			type: TypeNotification.warning,
			title: `Eliminar`,
			description: `¿Estás seguro que quieres eliminar este número: ${item.title}?`,
			btn,
			key: 'close-notification',
		});
	};

	const handleCopy = (item) => {
		delete item._id;
		new Services().create(item).then((response) => {
			setData([...data, response]);
			CustomNotification({
				type: TypeNotification.success,
				description: `Se copió correctamente`,
			});
		});
	};

	if (isLoading === true) {
		return <Loading />;
	}

	if (isError === true) {
		return <PageError errorTitle={'Conexión fallida'} />;
	}

	if (data) {
		return (
			<>
				<DrawerOptions account={account} />

				<Row justify="center">
					<Col xs={22} md={10}>
						<Col span={3} offset={21}>
							<ModalConfig title={'Modulo de Agentes'} key={'modal'} module={'agent'} account={account} />
						</Col>
					</Col>
				</Row>
				<br></br>
				<Row justify="center" className="own-agents">
					<Col xs={22} md={10}>
						<CustomCard
							title={'Vendedores'}
							bordered={false}
							extra={[
								<CustomButton
									key={'add'}
									className={'own-add-button'}
									onClick={() => {
										history.push(`/profile/agent-create/${account}`);
									}}
								>
									Agregar
								</CustomButton>,
							]}
						>
							<Row justify="center">
								<Col span={24}>
									{data.map((item, key) => {
										return (
											<Row className="own-agents-list" key={key}>
												<Col span={3}>
													<CustomButton
														size={'large'}
														className={'own-none-button'}
														onClick={() => handleUpdate(item)}
														icon={<HistoryOutlined />}
													></CustomButton>
												</Col>
												<Col span={12}>{Substring({ text: item.title, init: 0, limit: 15 })}</Col>
												<Col span={3}>
													<CustomButton
														size={'large'}
														className={'own-none-button'}
														onClick={() => handleCopy(item)}
														icon={<CopyOutlined />}
													></CustomButton>
												</Col>
												<Col span={3}>
													<CustomButton
														size={'large'}
														className={'own-none-button'}
														onClick={() => handleUpdate(item)}
														icon={<EditOutlined />}
													></CustomButton>
												</Col>
												<Col span={3}>
													<CustomButton
														size={'large'}
														className={'own-none-button'}
														onClick={() => handleDelete(item)}
														icon={<DeleteOutlined />}
													></CustomButton>
												</Col>
											</Row>
										);
									})}
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			</>
		);
	}
}
