/** @format */

import React from 'react';
import { Checkbox } from 'antd';
import './style.css';

export default function CustomCheckbox(props) {
	return (
		<>
			<Checkbox className={props.className || 'own-default'} {...props} />
		</>
	);
}
