/** @format */

import { notification } from 'antd';
import { typeOfMessage } from './type-of-message.utility';

export const axiosInterceptor = async (auxResponse, path, message: any = null) => {
	try {
		console.log(auxResponse);
		const response = await auxResponse;
		console.log('interceptor -> response', response);
		if (response.data.statusCode === 200 || response.data.statusCode === 201) {
			await typeOfMessage(message);
			if (response.data.totalPages) {
				console.log('trae paginacion', response.data);
				return response.data;
			} else if (response.data.data.length > 1) {
				console.log('trae un arreglo [N]', response.data.data);
				return response.data.data;
			} else {
				console.log('trae un objeto', response);
				return response.data.data;
			}
		} else if (response.data.statusCode === 404) {
			throw Error('Servicio no encontrado');
		} else {
			throw response.data.message;
		}
	} catch (e:any) {
		console.log('error interceptor ->', path, e.response);
		notification.error({
			message: `¡Ups!`,
			description: `${e.response.data.message}`,
		});
		throw e;
	}
};
