/** @format */

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Row, Col } from 'antd'
import { InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons'

import './style.css'

export default function OptionWizzard(props) {
	const history = useHistory()

	useEffect(() => {
		console.log('useEffects')
	}, [props])

	return (
		<>
			<Row justify='center' className='own-wizzard-global-content'>
				<Col xs={23} sm={20} xl={10} className='own-wizzard-main-content'>
					<div className='own-wizzard-content'>
						<Card className='own-card-margin-content' title='¿Qué enlace quieres crear?' bordered={false}>
							<Row justify='center'>
								<Col xs={24} sm={24} md={24}>
									<div
										className='cv-profile-card-item-tre'
										style={{ cursor: 'pointer' }}
										onClick={() => history.push(`/profile/account-user/`)}>
										<div className='cv-profile-card-item-img'>
											<InstagramOutlined className='own-global-icon-small' />
										</div>
										<div className='cv-profile-card-item-title'>
											<h3 className='home-h3'>Multiples Links</h3>
											<p className='home-p'>
												Comparte todas tus redes sociales, trabajos, servicios y mucho más.
											</p>
											<p className='home-p'>
												De esa manera tus clientes o tu comunidad podrá ver todo lo que tu
												ofreces mientras tu estas dedicandote a otras cosas.
											</p>
										</div>
									</div>
								</Col>

								<Col xs={24} sm={24} md={24}>
									<div
										className='cv-profile-card-item-tre'
										style={{ cursor: 'pointer' }}
										onClick={() => history.push(`/profile/whatsapp-user/`)}>
										<div className='cv-profile-card-item-img'>
											<WhatsAppOutlined className='own-global-icon-small' />
										</div>
										<div className='cv-profile-card-item-title'>
											<h3 className='home-h3'>Link de WhatsApp</h3>
											<p className='home-p'>
												Convierte tu número en un enlace personalizado y profesional.
											</p>
											<p className='home-p'>
												Así se visualizará con mayor confianza y el destino final será tu
												WhatsApp
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</Card>
					</div>
				</Col>
			</Row>
		</>
	)
}
