/** @format */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import DrawerOptions from '../../../components/DrawerOptions';
import CustomNotification from '../../../../../components/Antd/Notification';

import FormProduct from '../components/FormProduct';
import { Services } from '../services';

interface RouterParams {
	account: string;
}

export default function ProductCreate(props) {
	const history = useHistory();
	const { account } = useParams<RouterParams>();
	const [image, setImage] = useState(undefined);
	const [data] = useState({
		account: account,
		name: '',
		image: '',
		description: '',
		price: '',
		currency: '',
	});

	useEffect(() => {
		console.log('ProductCreate', props);
	}, [props]);

	const handleImage = (e) => {
		setImage(e);
	};

	const handleSubmit = (item) => {
		console.log(item);
		if (parseInt(item.price_offer) >= parseInt(item.price)) {
			CustomNotification({
				type: TypeNotification.error,
				description: `El precio de oferta debe ser menor al precio de venta`,
			});

			return;
		}
		if (image === undefined) {
			CustomNotification({
				type: TypeNotification.error,
				description: `Aún falta cargar la imagen`,
			});

			return;
		}
		item.image = image;
		item.type = 'product';
		item.account = account;
		console.log('handleSubmit', item);
		new Services()
			.create(item)
			.then((response) => {
				CustomNotification({
					type: TypeNotification.success,
					description: `Se creo el producto correctamente`,
				});
				setInterval(() => {
					history.push(`/profile/product-wizzard/${account}`);
				}, 1000);
			})
			.catch((e) => {
				CustomNotification({
					type: TypeNotification.error,
					description: `Problemas al crear el producto`,
				});
				console.log(e);
			});
	};
	return (
		<>
			<DrawerOptions account={account} buttonBack={`/profile/product-wizzard/${account}`} />
			<FormProduct data={data} account={account} submit={handleSubmit} button={'Guardar'} uploadImage={handleImage} label={'Crear Producto'} />
		</>
	);
}
