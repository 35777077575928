/** @format */

import React, { useState, useEffect } from 'react';
import { Upload, notification, Row, Col } from 'antd';
import ImgCrop from 'antd-img-crop';
import { setGlobal } from 'reactn';

import { CONSTANTS } from '../Constant';

import { serviceUploadImage } from './services';
import './style.css';

export default function UploadImagesAlt(props) {
	const [count, setCount] = useState(0);
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		console.log('useEffect', props.componentName);
	}, [props]);

	const beforeUpload = (file) => {
		console.log('beforeUpload', file);
		const isSize = file.size / 1024 / 1024 <= 1;
		if (!isSize) {
			notification['error']({
				message: `¡Ups!`,
				description: `La imagen es muy pesada, reduce un poco más el peso`,
			});
		}
		return isSize;
	};

	const handleOnChange = ({ fileList: item }) => {
		console.log('handleOnChange', item);
		setFileList(item);
		if (item.length > 0) {
			if (count === 0) {
				handleSaveImage(item);
				setCount(1);
			}
		} else {
			setCount(0);
			props.componentHandle(undefined);
		}
	};

	const handleSaveImage = async (item) => {
		setGlobal({
			uploadImage: true,
		});
		console.log('handleSaveImage', item);
		console.log('name', props.componentName);
		let formData = new FormData();
		formData.append('image', item[0].originFileObj);
		formData.append('name', `${props.componentName}`);
		formData.append('key', process.env.REACT_APP_IMBB_API_KEY as string);
		await serviceUploadImage(formData).then((response) => {
			console.log('imagen subida', response);
			props.componentHandle(response.image.url);
		});
		setGlobal({
			uploadImage: false,
		});
	};

	const handleOnPreview = async (item) => {
		let src = item.url;
		if (!src) {
			src = await new Promise((resolve) => {
				let reader = new FileReader();
				reader.readAsDataURL(item.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		let image = new Image();
		image.src = src;
		let imgWindow = window.open(src);
		if (imgWindow) {
			imgWindow.document.write(image.outerHTML);
		}
	};

	return (
		<Row className="own-uplodad-image-content">
			<Col span={6}>
				<ImgCrop>
					<Upload
						className="own-upload-image"
						listType="picture-card"
						fileList={fileList}
						onChange={handleOnChange}
						onPreview={handleOnPreview}
						beforeUpload={beforeUpload}
						progress={{ strokeWidth: 2, showInfo: false }}
						accept={props.componentAccept || CONSTANTS.FORMAT}
					>
						{fileList.length <= 0 && 'CLICK'}
					</Upload>
				</ImgCrop>
			</Col>
			<Col span={10} offset={6}>
				<br></br>
				<h5>{props.title}</h5>
				<h5>Click en el recuadro</h5>
				<h6>(obligatoria)</h6>
			</Col>
		</Row>
	);
}
