/** @format */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Row, Col, notification, Form } from 'antd';
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import Loading from '../../../../../components/Loading';
import CustomFormItem from '../../../../../components/Antd/FormItem';
import CustomSelect from '../../../../../components/Antd/Select';
import CustomCard from '../../../../../components/Antd/Card';
import CustomButton from '../../../../../components/Antd/Button';
import CustomNotification from '../../../../../components/Antd/Notification';

import PageError from '../../../../../components/Common/PageError';
import ModalConfig from '../../../components/ModalConfig';
import DrawerOptions from '../../../components/DrawerOptions';

import { Services } from '../services';
import './style.css';

interface RouterParams {
	account: string;
}

export default function Products(props) {
	const history = useHistory();
	const { account } = useParams<RouterParams>();
	const [form] = Form.useForm();
	const [isDisabled, setDisabled] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(false);
	const [data, setData] = useState<any>([]);
	const [isDrag, setDrag] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [showMore, setShowMore] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [currentCurrency, setCurrentCurrency] = useState();

	useEffect(() => {
		console.log('useEffect', 'Products');
		new Services()
			.groupBy(account)
			.then((response) => {
				setCurrencies(response.currencies);
				setCurrentCurrency(response.current_currency);
				const products = handleProductKey(response.current_records);
				setData(products);
				if (products.length >= 20) {
					setShowMore(true);
				}
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	}, [account]);

	const handleUpdate = (item) => {
		history.push(`/profile/product-update/${account}/${item._id}`);
	};

	const handleLoadingMore = () => {
		setButtonLoading(true);
		new Services().pagination({ account: account, page: currentPage, currency: currentCurrency }).then((response) => {
			if (response.length >= 20) {
				setShowMore(true);
				setCurrentPage(currentPage + 1);
				setButtonLoading(false);
				const newArray = [...data, ...response.data];
				const arrayMap = handleProductKey(newArray);
				setData(arrayMap);
			} else {
				setShowMore(false);
				setCurrentPage(1);
			}
			setButtonLoading(false);
		});
	};

	const handleDelete = (item) => {
		let btn = (
			<Button
				className="own-global-button-submit"
				onClick={() => {
					new Services().delete(item._id).then(() => {
						setData(
							data.filter((iterator) => {
								return iterator._id !== item._id;
							}),
						);
						notification.close('notification-delete');
					});
				}}
			>
				Confirmar
			</Button>
		);

		CustomNotification({
			type: TypeNotification.warning,
			description: `¿Estás seguro que quieres eliminar este producto: ${item.name}?`,
			btn,
			key: 'notification-delete',
		});
	};

	const handleProductPrice = (item) => {
		if (item.price_offer) {
			return (
				<>
					<span className={'own-price-offer'}>
						{item.price} {item.currency}
					</span>{' '}
					<span className={'own-price'}>
						{item.price_offer} {item.currency}
					</span>{' '}
				</>
			);
		} else {
			return (
				<span className={'own-price'}>
					{item.price} {item.currency}
				</span>
			);
		}
	};

	const handleProductKey = (item) => {
		if (item.length <= 0) {
			return item;
		}
		return item.map((iterator, key) => {
			iterator.id = key.toString();
			return iterator;
		});
	};

	const handleCopy = (item) => {
		setDisabled(true);
		delete item._id;
		new Services()
			.create(item)
			.then((response) => {
				setDisabled(false);
				setData(handleProductKey([...data, response]));
			})
			.catch((e) => {
				setDisabled(false);
			});
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(data);
		const [reOrderItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reOrderItem);
		const arrayMap = handleProductKey(items);
		setData([...arrayMap]);
		setDrag(true);
	};

	const handleUpdateMany = () => {
		setDrag(true);
		new Services().updateMany(data).then((response) => {
			setDrag(false);
		});
	};

	const handleChangeCurrency = (item) => {
		setCurrentCurrency(item);
		setDrag(false);
		new Services().pagination({ account: account, page: 1, type: 'product', currency: item }).then((response) => {
			const products = handleProductKey(response.data);
			setData(products);
			if (products.length >= 20) {
				setShowMore(true);
			} else {
				setShowMore(false);
			}
		});
	};

	if (isLoading === true) {
		return <Loading />;
	}

	if (isError === true) {
		return <PageError errorTitle={'Conexión fallida'} />;
	}

	if (data) {
		return (
			<>
				<DrawerOptions account={account} />

				<Row justify="center">
					<Col xs={22} md={10}>
						<Col span={3} offset={21}>
							<ModalConfig title={'Modulo de Productos'} key={'modal'} module={'store'} account={account} />
						</Col>
					</Col>
				</Row>
				<br></br>
				<Row justify="center" className="own-product">
					<Col xs={22} md={10}>
						<CustomCard
							title={'Productos'}
							bordered={false}
							extra={[
								<CustomButton
									key={'add'}
									className={'own-add-button'}
									onClick={() => {
										history.push(`/profile/product-create/${account}`);
									}}
								>
									Agregar
								</CustomButton>,
							]}
						>
							<Row justify="center">
								<Col span={24}>
									{currencies.length > 1 && (
										<>
											<Form form={form} layout={'vertical'}>
												<CustomFormItem label={'Filtrar por Moneda'} name={'currency'}>
													<CustomSelect
														mode={'single'}
														options={[...currencies]}
														value={currentCurrency}
														onChange={handleChangeCurrency}
													/>
												</CustomFormItem>
											</Form>
										</>
									)}

									<DragDropContext onDragEnd={handleOnDragEnd}>
										<Droppable droppableId="characters">
											{(provided) => (
												<ul {...provided.droppableProps} ref={provided.innerRef}>
													{data.map((item, key) => {
														return (
															<Draggable key={item.id.toString()} draggableId={item.id.toString()} index={key}>
																{(provided) => (
																	<li
																		className="own-product-list"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																	>
																		<Row>
																			<Col span={5}>
																				<img src={item.image} alt={key} title={key} />
																			</Col>
																			<Col span={19}>
																				<Row>
																					<ul>
																						<li>{item.name}</li>
																						<li>{handleProductPrice(item)}</li>
																						<li>
																							<Row>
																								<Col span={4}>
																									<CustomButton
																										className={'own-none-button'}
																										disabled={isDisabled}
																										onClick={() => handleCopy(item)}
																										icon={<CopyOutlined />}
																									/>
																								</Col>
																								<Col span={4}>
																									<CustomButton
																										className="own-none-button"
																										onClick={() => handleUpdate(item)}
																										icon={<EditOutlined />}
																									/>
																								</Col>
																								<Col span={4}>
																									<CustomButton
																										className="own-none-button"
																										onClick={() => handleDelete(item)}
																										icon={<DeleteOutlined />}
																									/>
																								</Col>
																							</Row>
																						</li>
																					</ul>
																				</Row>
																			</Col>
																		</Row>
																	</li>
																)}
															</Draggable>
														);
													})}
													{provided.placeholder}
												</ul>
											)}
										</Droppable>
									</DragDropContext>

									{showMore === true && (
										<Row justify="center">
											<CustomButton block loading={buttonLoading} className={'own-default-button'} onClick={() => handleLoadingMore()}>
												Cargar más
											</CustomButton>
										</Row>
									)}
									<br></br>
									{isDrag === true && (
										<Row justify="center">
											<CustomButton block loading={buttonLoading} onClick={() => handleUpdateMany()}>
												Actualizar
											</CustomButton>
										</Row>
									)}
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			</>
		);
	}
}
