/** @format */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Col, Modal, Row } from 'antd';
import { ExportOutlined, QuestionOutlined, SafetyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Storage } from '../../../../../_config/constants/storage.constant';
import { RemoveStorage } from '../../../../../_config/utils/remove-storage.utils';
import './style.css';

export default function Options(props) {
	const history = useHistory();

	useEffect(() => {
		console.log('Options');
	}, [props]);

	return (
		<>
			<br></br>
			<br></br>
			<Row>
				<h3>Configuraciones</h3>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col xs={24} sm={7} md={7} className="own-option-card">
					<Link to={`/profile/change-password`}>
						<Row align={'middle'}>
							<Col span={4}>
								<SafetyOutlined className="own-option-icon" />
							</Col>
							<Col span={20}>
								<h3>Cambiar contraseña</h3>
								<p>Para proteger tu cuenta, cambia a menudo tu contraseña.</p>
							</Col>
						</Row>
					</Link>
				</Col>
				<Col xs={24} sm={7} md={7} className="own-option-card">
					<Row
						align={'middle'}
						onClick={() => {
							Modal.confirm({
								title: 'Cerrar sesión',
								icon: <ExclamationCircleOutlined className="own-global-icon-small" />,
								content: '¿Estas seguro que queires cerrar tu seción?',
								okText: 'Confirmar',
								onOk: () => {
									RemoveStorage(Storage.user);
									history.push('/');
								},
								cancelText: 'Cancelar',
							});
						}}
					>
						<Col span={4}>
							<ExportOutlined className="own-option-icon" />
						</Col>
						<Col span={20}>
							<h3>Cerrar sesión</h3>
							<p>¿Deseas cerrar sesión en tu cuenta? no olvides visitarnos</p>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={7} md={7} className="own-option-card">
					<Link to={`/help/quienes-somos`}>
						<Row align={'middle'}>
							<Col span={4}>
								<QuestionOutlined className="own-option-icon" />
							</Col>
							<Col span={20}>
								<h3>Preguntas frecuentes</h3>
								<p>Tines alguna duda entra en ayuda y busca lo que necesites.</p>
							</Col>
						</Row>
					</Link>
				</Col>
			</Row>
		</>
	);
}
