/** @format */

import React from 'react';
import { Input } from 'antd';
import './style.css';

const { TextArea } = Input;

export default function CustomTextArea(props) {
	return <TextArea {...props} className={'own-text-area-default' || props.className} />;
}
