/** @format */
import React, { useState, useEffect } from 'react';
import { Col, Row, Form } from 'antd';

import Loading from '../../../../../../components/Loading';
import UploadImageSquare from '../../../../../../components/UploadImageSquare';
import CustomFormItem from '../../../../../../components/Antd/FormItem';
import CustomInput from '../../../../../../components/Antd/Input';
import CustomSelect from '../../../../../../components/Antd/Select';
import CustomButton from '../../../../../../components/Antd/Button';
import CustomCard from '../../../../../../components/Antd/Card';
import CustomRadioButton from '../../../../../../components/Antd/RadioButton';
import CustomJodiEditor from '../../../../../../components/Antd/JodiEditor';

export default function FormProduct(props) {
	const [form] = Form.useForm();
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState(true);
	const negotiaton = Form.useWatch('negotiation', form);

	useEffect(() => {
		console.log('useEffect', 'FormProduct');
		setData(props.data);
		setLoading(false);
	}, [props]);

	if (isLoading === true) {
		return <Loading />;
	} else {
		return (
			<>
				<Row justify="center">
					<Col xs={24} md={10}>
						<CustomCard title={props.Label} bordered={false}>
							<Row justify="center">
								<Col span={21}>
									<Form layout="vertical" form={form} initialValues={data} onFinish={props.submit}>
										<CustomFormItem name={'name'} label={'Nombre del Servicio'} rules={'required'}>
											<CustomInput type={'text'} value={data.name} maxLength={50} />
										</CustomFormItem>

										<UploadImageSquare
											image={data.image}
											title={'Imagen del Servicio'}
											name={`servicio-${Date.now()}`}
											handle={props.uploadImage}
										/>

										<CustomFormItem rules={'required'} name={'negotiation'}>
											<CustomRadioButton
												value={data.negotiation}
												label={'Tipo de Negociacion'}
												items={[
													{
														label: 'A Convenir',
														value: true,
													},
													{
														label: 'Precio Fijo',
														value: false,
													},
												]}
											/>
										</CustomFormItem>

										{negotiaton === false && (
											<>
												<CustomFormItem name={'price'} label={'Precio de Venta'} rules={'required'}>
													<CustomInput type={'number'} value={data.price} />
												</CustomFormItem>
												<CustomFormItem name={'price_offer'} label={'Precio (Oferta)'}>
													<CustomInput type={'number'} value={data.price_offer} />
												</CustomFormItem>
												<CustomFormItem label={'Tipo de Moneda'} name={'currency'} rules={'required'}>
													<CustomSelect
														mode={'single'}
														placeholder={'Seleccione una opción'}
														options={[
															{
																name: 'Dólar',
																value: 'USD',
															},
															{
																name: 'Peso Chileno',
																value: 'CLP',
															},
															{
																name: 'Peso Colombiano',
																value: 'COP',
															},
															{
																name: 'Peso Argentino',
																value: 'ARS',
															},
														]}
													/>
												</CustomFormItem>
											</>
										)}

										<CustomJodiEditor
											name={'description'}
											label={'¿Que Incluye?'}
											extra={'Menciona lo que incluye tu servicio'}
											value={data.description}
											rules={'required'}
										/>

										<CustomJodiEditor
											name={'faq'}
											label={'Condiciones del Servicio (Opcional)'}
											extra={'Menciona lo que quieras aclarar'}
											value={data.faq}
										/>

										<CustomFormItem
											name={'video'}
											label={'Video de presentación'}
											extra={'El link debes tomarlo de la pagina de YouTube, utiliza este video para dar detalles de tu servicio'}
										>
											<CustomInput type={'text'} value={data.video} maxLength={50} />
										</CustomFormItem>

										<CustomButton block htmlType={'submit'}>
											{props.button}
										</CustomButton>
									</Form>
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			</>
		);
	}
}
