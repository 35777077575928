export const DICTIONARY = {
	whatsapp: 'Whatsapp',
	addLink: 'Agregar link',
	editLink: 'Editar link',
	generateWhatsapp: 'Generar link de WhatsApp',
	editWhatsapp: 'Editar link de WhatsApp',
	web: 'web',
	social: 'social',
	webSite: 'Sitio Web',
	socialMedia: 'Red Social',
};
