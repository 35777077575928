/** @format */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import Loading from '../../../../../components/Loading';
import PageError from '../../../../../components/Common/PageError';
import DrawerOptions from '../../../components/DrawerOptions';
import CustomNotification from '../../../../../components/Antd/Notification';

import FormProduct from '../components/FormProduct';
import { Services } from '../services';

interface RouterParams {
	account: string;
	id: string;
}

export default function ProductUpdate(props) {
	const history = useHistory();
	const { account, id } = useParams<RouterParams>();
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(false);
	const [data, setData] = useState<any>(true);
	const [image, setImage] = useState(undefined);

	useEffect(() => {
		console.log('useEffect -> ProductUpdate');
		new Services()
			.getOne(id)
			.then((response) => {
				setData(response);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	}, [id]);

	const handleImage = (e) => {
		setImage(e);
		data.image = e;
		setData(data);
	};

	const handleSubmit = (item) => {
		item.image = image;
		new Services()
			.updateOne({ id: data._id, body: item })
			.then(() => {
				CustomNotification({
					type: TypeNotification.success,
					description: `Se ha actualizado el producto correctamente`,
				});
				setTimeout(() => {
					history.push(`/profile/product-wizzard/${account}`);
				}, 1000);
			})
			.catch((e) => console.log(e));
	};

	if (isLoading === true) {
		return <Loading />;
	}

	if (isError === true) {
		return <PageError errorTitle={'Conexión fallida'} />;
	}

	if (data) {
		return (
			<>
				<DrawerOptions account={account} buttonBack={`/profile/product-wizzard/${account}`} />
				<FormProduct
					data={data}
					account={account}
					submit={handleSubmit}
					button={'Actualizar'}
					uploadImage={handleImage}
					label={'Actualizar Producto'}
				/>
			</>
		);
	}
}
