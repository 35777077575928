/** @format */

import React from 'react';
import { Form } from 'antd';
import { rules } from './rules';
import './style.css';

export default function CustomFormItem(props) {
	return <Form.Item {...props} rules={rules[props.rules]} className="own-form-item" />;
}
