/** @format */

import React from 'react';
import JoditEditor from 'jodit-react';
import CustomFormItem from '../FormItem';

import './style.css';

export default function CustomJodiEditor(props) {
	return (
		<>
			<CustomFormItem {...props}>
				<JoditEditor
					ref={props.ref}
					value={props.value}
					tabIndex={1}
					config={{
						buttons: props.buttons || ['bold', 'ul', 'link', 'left', 'center', 'right', 'undo'],
						readonly: false,
						toolbarAdaptive: false,
						placeholder: props.placeholder || 'Escribe aquí',
					}}
					onBlur={props.onBlur}
					{...props}
				/>
			</CustomFormItem>
		</>
	);
}
