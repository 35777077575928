/** @format */

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomResult from '../Antd/Result';
import CustomButton from '../Antd/Button';

export default function ErrorPage(props) {
	const history = useHistory();

	useEffect(() => {
		console.log('useEffect', props);
	}, [props]);

	const handleMessage = (item) => {
		if (item === 404) {
			return 'Página no encontrada';
		}

		if (item === 403) {
			return 'Conflicto en la solicitud';
		}

		if (item === 500) {
			return 'Problemas de conexión';
		}

		if (item === 204) {
			return 'No se encontraron resultados';
		}

		return 'Página no encontrada';
	};

	const handleRedirect = () => {
		history.push('/');
	};

	return (
		<CustomResult
			status={404}
			title={'¡Ups!'}
			subTitle={handleMessage(props.componentStatus)}
			extra={
				<>
					<CustomButton onClick={() => handleRedirect()}>Volver</CustomButton>
				</>
			}
		/>
	);
}
