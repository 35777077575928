/** @format */

import { AxiosAdapter } from '../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../_config/adapters/axios/axios.protocols';

export class Services {
	async get() {
		return new AxiosAdapter({
			method: Http.GET,
			url: `http://localhost:8000/test/count`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
