import { Enviroment } from '../../../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async get() {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/promotion`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
