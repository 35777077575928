/** @format */

import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Biography from '../../components/Biography';
import SkeletonProduct from './components/SkeletonProduct';
import Error from '../../../../components/Error';
import Footer from '../../../../components/Footer';
import Products from './components/Products';
import { handleCurrenciesAdapter } from './handle/currencies-adapter.handle';
import { Config } from './constants/config.contant';
import { Services } from './services';
import './style.scss';

interface RouterParams {
	account: string;
}

export default function AccountServices(props) {
	const typeProduct = Config.type;
	const location = useLocation();
	const { account } = useParams<RouterParams>();
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(200);
	const [data, setData] = useState<any>();
	const [accountData, setAccountData] = useState();
	const searchParams = new URLSearchParams(location.search);
	const queryRef = searchParams.get('ref');
	const [referal] = useState(queryRef ? queryRef : null);

	useEffect(() => {
		console.log('useEffects', 'AccountStore', props);
		new Services()
			.get(account, typeProduct)
			.then((response) => {
				console.log(response);
				setAccountData(response.account);
				setData(response);
				setLoading(false);
			})
			.catch((e) => setError(500));
	}, [props, account, typeProduct]);

	if (isLoading === true) {
		return <SkeletonProduct />;
	}

	if (isError !== 200) {
		return <Error componentStatus={isError} />;
	}

	if (data.records.length <= 0) {
		return (
			<>
				<div className="own-account-content">
					<Biography data={accountData} back={true} />
					No se encontraron registros, vuelva a <a href={`/${account}`}>atrás</a>.
				</div>
			</>
		);
	}
	return (
		<>
			<div className="own-account-content">
				<Biography data={accountData} back={true} />

				{data.records.length > 0 && (
					<Products
						data={data}
						params={{
							typeProduct: typeProduct,
							accountName: account,
							module: data.module,
							referal: referal,
							currencies: handleCurrenciesAdapter(data.currencies),
						}}
					/>
				)}

				<Footer />
			</div>
		</>
	);
}
