/** @format */

import { axiosInterceptor } from '../../../../utility/axios-interceptor.utility';
import { axiosConfig } from '../../../../utility/axios-config.utility';
const path = 'src/pages/profile/pages/my-modules/services.js';

export const serviceGetData = async (account) => {
	const response = axiosConfig({
		method: 'GET',
		url: `/billing/get-modules/${account}`,
	});
	return await axiosInterceptor(response, path);
};
