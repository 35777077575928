/** @format */

import React from 'react';
import { Skeleton, Row, Col } from 'antd';

import './style.css';

export default function SkeletonProduct(props) {
	console.log('useEffects', 'SkeletonProduct', props);

	return (
		<>
			<Row justify="center">
				<Col md={11} sm={18} xs={24}>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Skeleton.Input active block={true} style={{ height: '160px', borderRadius: '7px' }} />
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Skeleton.Avatar active={true} size="large" shape="circle" style={{ borderRadius: '7px', marginRight: '10px' }} />
						<Skeleton.Avatar active={true} size="large" shape="circle" style={{ borderRadius: '7px', marginRight: '10px' }} />
						<Skeleton.Avatar active={true} size="large" shape="circle" style={{ borderRadius: '7px' }} />
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={11} sm={11} xs={11}>
							<Skeleton.Input active block={true} style={{ height: '160px', borderRadius: '7px' }} />
						</Col>
						<Col md={1} sm={1} xs={1}></Col>
						<Col md={11} sm={11} xs={11}>
							<Skeleton.Input active block={true} style={{ height: '160px', borderRadius: '7px' }} />
						</Col>
					</Row>

					<Row justify="center" style={{ marginTop: '10px', marginBottom: '20px' }}>
						<Col md={11} sm={11} xs={11}>
							<Skeleton.Input active block={true} style={{ height: '160px', borderRadius: '7px' }} />
						</Col>
						<Col md={1} sm={1} xs={1}></Col>
						<Col md={11} sm={11} xs={11}>
							<Skeleton.Input active block={true} style={{ height: '160px', borderRadius: '7px' }} />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
