/** @format */
import { notification } from 'antd';

export const typeOfMessage = async (typeOfMessage) => {
	switch (typeOfMessage) {
		case 'CREATE':
			notification.success({
				message: `¡Bien!`,
				description: `Se ha registrado con éxito`,
			});
			break;

		case 'UPDATE':
			notification.success({
				message: `¡Bien!`,
				description: `Se ha actualizado con éxito`,
			});
			break;

		case 'DELETE':
			notification.success({
				message: `¡Bien!`,
				description: `Se ha eliminado con éxito`,
			});
			break;

		case 'SEND':
			notification.success({
				message: `Felicidades!`,
				description: `Se ha enviado con éxito`,
			});
			break;

		case 'COPY':
			notification.success({
				message: `¡Bien!`,
				description: `Se ha duplicado con éxito`,
			});
			break;

		default:
			if (typeOfMessage === null) break;
			notification.success({
				message: `¡Bien!`,
				description: `${typeOfMessage}`,
			});
			break;
	}
};
