/** @format */

import React, { useEffect, useState } from 'react';
import { Constant } from './constants';
import './style.css';

export default function StandarLink(props) {
	const [data] = useState(props.data);
	const [theme] = useState(props.theme);
	const [colorLink] = useState(props.colorLink);
	const [colorText] = useState(props.text);

	useEffect(() => {
		console.log('useEffects', 'StandarLink');
	}, [props]);

	return (
		<>
			<a href={data.url} target="_blank" rel="noopener noreferrer">
				<div style={{ background: colorLink }} className={theme}>
					{data.icon === Constant.generic && <img src={Constant.url_icon} alt={data.title} title={data.title} />}
					{data.icon !== Constant.generic && <img src={data.icon} alt={data.title} title={data.title} />}
					<span style={{ color: colorText }}>{data.title}</span>
				</div>
			</a>
		</>
	);
}
