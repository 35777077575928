/** @format */

import { notification } from 'antd';
import './style.css';

export default function CustomNotification(props) {
	const title = {
		success: '¡Bien!',
		info: '¡Informaciòn!',
		warning: '¡Cuidado!',
		error: '¡Ups!',
	};

	if (props.type === 'close') {
		notification.close(props.key);
		return;
	}

	notification[props.type]({
		...props,
		message: props.title || title[props.type],
		description: props.description,
		className: 'own-notification',
	});
}
