/** @format */

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Button, Card, notification, Row, Col } from 'antd'
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Loading from '../../../../../components/Loading'
import InputField from '../../../../../components/Form/Input'
import SelectField from '../../../../../components/Form/Select'
import { CONSTANTS } from '../../../../../components/Constant'

import DrawerOptions from '../../../components/DrawerOptions'
import EditLinkModal from './components/EditLinkModal'

import { serviceGetData, serviceUpdateData, serviceGetTypeOptions } from '../services'

import './style.css'

export default function AccountMainLink(props) {
	const history = useHistory()
	const [form] = Form.useForm()
	const { account, modify } = useParams<any>()
	const [data, setData] = useState<any>()
	const [isLoading, setLoading] = useState(true)
	const [buttonText, setButtonText] = useState<any>('Siguiente')
	const [links, setLinks] = useState<any>([])
	const [isModify, setIsModify] = useState<any>(false)
	const [redSocial, setRedSocial] = useState<any>([])
	const [textButton] = useState('Agregar')
	const [textInput, setTextInput] = useState('Ingresa tu URL')
	const [textIntructions, setTextInstructions] = useState('Ejemplo: https://www.creatulink.com')

	useEffect(() => {
		console.log('useEffects', 'AccountMainLink')
		if (modify) {
			setButtonText('Actualizar')
			setIsModify(true)
		}
		initData(account)
	}, [account, modify])

	const initData = async (account) => {
		const response = await serviceGetData(account)
		response.radio = 'social'
		setData(response)

		const responseOptions = await serviceGetTypeOptions('main')
		setRedSocial(responseOptions)

		const newLinks = response.link_main.map((item, key) => {
			return {
				id: key.toString(),
				title: item.title,
				type: item.type,
				url: item.url,
			}
		})
		setLinks(newLinks)
		setLoading(false)
	}
	const handleEditOneLink = (item) => {
		const index = links.findIndex((e) => {
			return e.id === item.id
		})
		let updateData = [...links]
		updateData[index] = item
		setLinks(updateData)
	}

	const handleAddElement = (item) => {
		console.log('handleAddElement', item)
		if (links.length >= 5) {
			notification['error']({
				message: `Ups!`,
				description: `Sólo se permiten máximo 5 redes sociales en la parte superior`,
			})
			return
		}

		if (item.type === 'whatsapp') {
			item.url = 'https://wa.me/' + item.url
		}

		if (item.type === 'email') {
			item.url = 'mailto:' + item.url
		}
		console.log('links', links)

		setLinks((links) => [
			...links,
			{
				id: links.length.toString(),
				type: item.type,
				title: item.type,
				url: item.url,
			},
		])
		form.resetFields(['title', 'url'])
	}

	const handleDelete = async (e) => {
		console.log('handleDelete', e)
		const filterLinks = await links.filter((item) => {
			return parseInt(item.id) !== e
		})

		console.log('filterLinks', filterLinks)

		const newOrder = await handleMap(filterLinks)

		console.log('newOrder', newOrder)
		setLinks([...newOrder])
	}

	const handleMap = async (item) => {
		const map = item.map((iterator, key) => {
			iterator.id = key.toString()
			return iterator
		})

		return map
	}

	const handleOnDragEnd = async (result) => {
		if (!result.destination) return
		const items = Array.from(links)
		const [reOrderItem] = items.splice(result.source.index, 1)
		items.splice(result.destination.index, 0, reOrderItem)
		setLinks(items)
		let item = data
		item.link_main = items
		console.log('handleOnDragEnd', item)
	}

	const handleSubmit = () => {
		let item = data
		item.link_main = links
		console.log(item)
		serviceUpdateData(item)
			.then((response) => {
				console.log(response)
				if (isModify === false) {
					history.push(`/profile/account-links/${response.data.name}`)
				}
			})
			.catch((e) => console.log(e))
	}

	const handleOnChange = (item) => {
		console.log(item)

		switch (item) {
			case 'whatsapp':
				setTextInput('Ingresa tu número de WhatsApp')
				setTextInstructions('Es importante que agregues el código de tu país')
				break

			case 'ubicacion':
				setTextInput('Ingresa tu dirección de Google Maps')
				setTextInstructions(
					'Una vez encuentres la ubicación de tu negocio en google maps, copia y pega la URL aquí.'
				)
				break

			case 'email':
				setTextInput('Ingresa tu Correo Electrónico')
				setTextInstructions('Recuerda colocar el correo de uso frecuente')
				break

			default:
				setTextInput('Ingresa tu URL')
				break
		}
	}

	if (isLoading === true) {
		return <Loading />
	}

	if (data !== undefined) {
		return (
			<>
				{isModify && (
					<>
						<DrawerOptions componentAccount={account} />
					</>
				)}
				<Row justify='center' className='own-wizzard-global-content'>
					<Col xs={23} sm={20} xl={10} className='own-wizzard-main-content'>
						<div className='own-wizzard-content'>
							<Card className={'own-card-margin-content'} title={'Links de Contacto'} bordered={false}>
								<Form form={form} initialValues={data} onFinish={handleAddElement}>
									<p className='own-p-input'>
										Coloca aquí todos los puntos de contacto para que puedan llegar a tí o tú
										negocio.
									</p>
									<div className='own-form-container'>
										<SelectField
											componentLabel={'Tipo de Contacto'}
											componentName={'type'}
											componentMode={'single'}
											componentPlaceholder={'Seleccione una opcion'}
											componentOptions={[...redSocial]}
											componentRules={'rulesSelect'}
											componentOnChange={handleOnChange}
										/>

										<InputField
											componentName={'url'}
											componentLabel={`${textInput}`}
											componentRules={'required'}
											componentPlaceholder={'Ingresar'}
											componentType={'text'}
											componentValue={data.url}
											componentExample={textIntructions}
										/>
									</div>
									<Form.Item>
										<Button htmlType={'submit'} className={'own-global-button-add'}>
											{textButton}
										</Button>
									</Form.Item>
									<div className='cv-account-wizard-social-links-container'>
										<h3 className='cv-account-wizard-social-links-title'>{`Enlaces Agregados: ${links.length}`}</h3>
										<DragDropContext onDragEnd={handleOnDragEnd}>
											<Droppable droppableId='characters'>
												{(provided) => (
													<ul
														className='characters'
														{...provided.droppableProps}
														ref={provided.innerRef}>
														{links.map((item, key) => {
															return (
																<Draggable
																	key={item.id.toString()}
																	draggableId={item.id.toString()}
																	index={key}>
																	{(provided) => (
																		<li
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}>
																			<p className='cv-account-wizard-social-links-list-title'>
																				{item.title}
																			</p>
																			<div className='cv-account-wizard-social-links-list-spacer'></div>
																			<Button
																				className='own-btn-none'
																				type='link'
																				shape='round'
																				icon={<LinkOutlined />}
																				onClick={() => {
																					window.open(item.url)
																				}}>
																				Probar
																			</Button>
																			<Button
																				className='cv-account-wizard-social-links-button-delete-container'
																				danger
																				type='link'
																				shape='round'
																				icon={<DeleteOutlined />}
																				onClick={() => {
																					handleDelete(key)
																				}}>
																				<span className='cv-account-wizard-social-links-button-delete'>
																					Eliminar
																				</span>
																			</Button>
																			<EditLinkModal
																				handleEditOneLink={(e) =>
																					handleEditOneLink(e)
																				}
																				params={{
																					typeLink: [...CONSTANTS.TYPE_LINK],
																					item: item,
																					redSocial: redSocial,
																					data: data,
																				}}
																			/>
																			<div className='characters-thumb'>
																				<img
																					src={
																						'https://i.ibb.co/NCmMyV7/drag-flick.png'
																					}
																					alt={`${item.title} Thumb`}
																				/>
																			</div>
																		</li>
																	)}
																</Draggable>
															)
														})}
														{provided.placeholder}
													</ul>
												)}
											</Droppable>
										</DragDropContext>
									</div>
									{modify && (
										<h4 className='cv-account-links-edit-info-container'>
											* Recuerda darle al boton "Actualizar" para que tus cambios surtan efecto.
										</h4>
									)}
									<Form.Item>
										<div className='own-wizard-actions-container'>
											<Button
												className={'own-global-button-submit'}
												onClick={() => {
													handleSubmit()
												}}>
												{buttonText}
											</Button>
										</div>
									</Form.Item>
								</Form>
							</Card>
						</div>
					</Col>
				</Row>
			</>
		)
	}
}
