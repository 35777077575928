import { Enviroment } from '../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async groupBy(account) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/product/group-by`,
			params: {
				account: account,
				type: 'product',
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async getOne(id) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/product/${id}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async getCategories(account) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/category/by-account/${account}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async updateOne({ id, body }) {
		return new AxiosAdapter({
			method: Http.PUT,
			url: `${Enviroment.HOST}/product/${id}`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async create(body) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/product`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async pagination(query) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/product/pagination`,
			params: query,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async delete(id) {
		return new AxiosAdapter({
			method: Http.DELETE,
			url: `${Enviroment.HOST}/product/${id}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async updateMany(body) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/product/update-many`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
