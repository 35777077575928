/** @format */

import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import renderHTML from 'react-render-html';
import numberFormatter from 'number-formatter';
import CustomButton from '../../../../../../components/Antd/Button';
import CustomDrawer from '../../../../../../components/Antd/Drawer';
import CustomSelect from '../../../../../../components/Antd/Select';
import Error from '../../../../../../components/Error';
import SkeletonProduct from '../SkeletonProduct';
import { useCounterStore } from '../../store';
import OneGrid from '../OneGrid';
import TwoGrid from '../TwoGrid';
import { handleAddToCart } from './handle/add-to-cart.handle';
import { handleSubTotal } from './handle/sub-total.handle';
import { handleDeleteToCart } from './handle/delete-to-cart.handle';
import { handleReserve } from './handle/reserve.handle';
import { Services } from './services';
import './style.css';

interface CounterStore {
	countProduct: number;
	increment: () => void;
	decrement: () => void;
  }

export default function Products(props) {
	console.log('Products', props);
	const [form] = Form.useForm();
	const { accountName, typeProduct, module, currencies } = props.params;
	const { countProduct, increment, decrement } = useCounterStore<CounterStore>(() => ({
	  countProduct: 0,
	  increment: () => {},
	  decrement: () => {},
	}));
	const [data, setData] = useState(props.data.records[0].records);
	const [currentCurrency, setCurrentCurrency] = useState(props.data.currencies[0]);
	const [colorLink] = useState(props.data.account.color.link || '#210358');
	const [isLoading, setLoading] = useState(false);
	const [cart, setCart] = useState([]);
	const [subTotal, setSubTotal] = useState(null);
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState(null);
	const [faq, setFaq] = useState(null);
	const [image, setImage] = useState('');
	const [grid, setGrid] = useState('two-grid');
	const [showMore, setShowMore] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [isError] = useState(204);

	const onClickAdd = async (item) => {
		const result = await handleAddToCart({ products: cart, oneProduct: item });
		setCart([...result] as any);
		const subTotal = await handleSubTotal({ products: result });
		setSubTotal(subTotal);
		increment();
	};

	const onClickDelete = async (item) => {
		const result = await handleDeleteToCart({ products: cart, oneProduct: item });
		const subTotal = await handleSubTotal({ products: result });
		setSubTotal(subTotal);
		setCart([...result]  as any);
		decrement();
	};

	const onClickReserve = async () => {
		const getMessage = await handleReserve({ products: cart, name: accountName, phone: module.phone, currency: currentCurrency });
		window.location.replace(getMessage);
	};

	const handleGo = (item) => {
		console.log('handleGo', item);
		setOpen(true);
		setImage(item.image);
		setName(item.name);
		setDescription(item.description ? item.description : null);
		setFaq(item.faq ? item.faq : null);
	};

	const handleOnClose = () => {
		setOpen(false);
	};

	const handleBorderColor = (item) => {
		if (grid === item) {
			return { border: '1px solid #ec407f' };
		}
	};

	const handleChangeCurrency = (item) => {
		setButtonLoading(true);
		setLoading(true);
		setCurrentCurrency(item);
		new Services()
			.get({
				account: accountName,
				type: typeProduct,
				currency: item,
				page: 1,
			})
			.then((response) => {
				console.log('handleChangeCurrency', response);
				setButtonLoading(false);
				setLoading(false);
				setData(response.data);
				hanldeShowButton(1, response.totalPages);
			});
	};

	const handleLoadingMore = () => {
		console.log('handleLoadingMore');
		setButtonLoading(true);
		let page = currentPage + 1;
		setCurrentPage(currentPage + 1);
		new Services()
			.get({
				account: accountName,
				currency: currentCurrency,
				page: page,
			})
			.then((response) => {
				console.log(response);
				setButtonLoading(false);
				const newArray = [].concat(data, response.data);
				setData(newArray);
				hanldeShowButton(page, response.totalPages);
			});
	};

	const hanldeShowButton = (current, totalPage) => {
		if (current >= totalPage) {
			setShowMore(false);
		}
	};

	if (isLoading === true) {
		return <SkeletonProduct />;
	} else {
		return (
			<>
				<Row justify="center" className="own-wizzard-global-content own-product">
					<Col xs={24} sm={24} xl={24} className="own-wizzard-main-content">
						<div className="own-wizzard-content">
							<CustomButton className="own-button-grid" style={handleBorderColor('two-grid')} onClick={() => setGrid('two-grid')}>
								<img src={'https://i.ibb.co/ccwLYYT/grid.png'} alt="two-grid" />
							</CustomButton>
							<CustomButton className="own-button-grid" style={handleBorderColor('one-grid')} onClick={() => setGrid('one-grid')}>
								<img src={'https://i.ibb.co/W6c2f0T/square.png'} alt="one-grid" />
							</CustomButton>

							<Row justify="center" className={'own-currency'}>
								{currencies.length > 0 && (
									<>
										<Col xs={20} sm={20} md={20}>
											<Form form={form}>
												<CustomSelect
													label={'Moneda'}
													name={'currency'}
													mode={'single'}
													placeholder={'Seleccionar'}
													options={[...currencies]}
													value={currentCurrency}
													rules={'rulesSelect'}
													onChange={handleChangeCurrency}
												/>
											</Form>
										</Col>
									</>
								)}
							</Row>

							{data.length <= 0 && (
								<>
									<Error componentStatus={isError} />
								</>
							)}
							{grid === 'one-grid' && (
								<>
									<Row>
										<OneGrid
											componentData={data}
											componenteDelete={onClickDelete}
											componentAdd={onClickAdd}
											componentGo={handleGo}
											componentColor={colorLink}
										/>
									</Row>
								</>
							)}

							{grid === 'two-grid' && (
								<>
									<Row>
										<TwoGrid componentData={data} componenteDelete={onClickDelete} componentAdd={onClickAdd} componentGo={handleGo} />
									</Row>
								</>
							)}

							{showMore === true && (
								<Row justify="center">
									<CustomButton type="primary" loading={buttonLoading} onClick={() => handleLoadingMore()}>
										Cargar más
									</CustomButton>
								</Row>
							)}

							{cart.length > 0 && (
								<>
									<br></br>

									<Row gutter={[16, 16]}>
										<Col span={10} className="own-sub-total" offset={1}>
											Total de Productos: {countProduct}
										</Col>
										<Col span={10} className="own-sub-total" offset={2}>
											Total a pagar: {numberFormatter('#.###,##', subTotal)} {currentCurrency}
										</Col>
									</Row>
									<br></br>
									<br></br>
									<Row justify="center">
										<Col xs={8} sm={8} md={8}>
											<CustomButton
												style={{
													borderColor: colorLink,
													backgroundColor: colorLink,
												}}
												className={'own-button-reserve'}
												type="primary"
												onClick={() => onClickReserve()}
												icon={<WhatsAppOutlined />}
											>
												Hacer Pedido
											</CustomButton>
										</Col>
									</Row>
								</>
							)}

							<CustomDrawer title={`${name}`} placement={'bottom'} onClose={handleOnClose} visible={open} className={'own-drawer-product'}>
								<Row justify="center">
									<img src={image} alt={name} className={'own-image'} />
								</Row>
								{description !== null && (
									<>
										<Row className="own-products-drawer">
											<h3>¿Que incluye?</h3>
										</Row>
										<Row>
											<div>{renderHTML(description)}</div>
										</Row>
									</>
								)}
								{faq !== null && (
									<>
										<Row className="own-products-drawer">
											<h3>Condiciones del Servicio</h3>
										</Row>
										<Row>
											<div>{renderHTML(faq)}</div>
										</Row>
									</>
								)}
							</CustomDrawer>
						</div>
					</Col>
				</Row>
			</>
		);
	}
}
