/** @format */

import React, { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col } from 'antd';
import { Services } from './services';
import './style.css';

export default function ReportPage(props) {
	const [data, setData] = useState({
		accounts: null,
		products: null,
		services: null,
	});
	useEffect(() => {
		new Services().get().then((response) => {
			setData((prev) => {
				return {
					accounts: response.accounts,
					products: response.products,
					services: response.services,
				};
			});
		});
	}, []);

	return (
		<>
			<Row justify="center">
				<Col span={18}>Count</Col>
			</Row>
			<Row justify="center">
				<Col span={4}>Cuentas:</Col>
				<Col span={4}>{data.accounts}</Col>
			</Row>
			<Row justify="center">
				<Col span={4}>Productos:</Col>
				<Col span={4}>{data.products}</Col>
			</Row>
			<Row justify="center">
				<Col span={4}>Servicios:</Col>
				<Col span={4}>{data.services}</Col>
			</Row>
		</>
	);
}
