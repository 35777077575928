import axios from 'axios';
import { AxiosInterceptor } from './axios.interceptor';

export class AxiosAdapter {
	constructor(public config: any) {}

	async request() {
		try {
			console.log('try adapter');
			const response = await axios(this.config);
			return await new AxiosInterceptor(response).pipe();
		} catch (e: any) {
			console.log('catch adapter', e);
			throw e.response.data;
		}
	}
}
