/** @format */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Row, Col } from 'antd';
import { SafetyOutlined, SmileOutlined } from '@ant-design/icons';
import { TypeNotification } from '../../../../_config/constants/notification.constant';
import { GetUserStorage } from '../../../../_config/utils/user-storage/get-user-storage.utils';
import CustomInput from '../../../../components/Antd/Input';
import CustomButton from '../../../../components/Antd/Button';
import CustomFormItem from '../../../../components/Antd/FormItem';
import CustomCard from '../../../../components/Antd/Card';
import CustomForm from '../../../../components/Antd/Form';
import CustomNotification from '../../../../components/Antd/Notification';
import CustomResult from '../../../../components/Antd/Result';

import { Services } from './services';
import './style.css';

export default function ChangePassword() {
	const [form] = Form.useForm();
	const history = useHistory();
	const [user] = useState(GetUserStorage());

	const handleOnFinish = (item) => {
		new Services()
			.recovery({
				email: user.email,
				password: item.password,
				new_password: item.newPassword,
			})
			.then(() => {
				CustomNotification({
					type: TypeNotification.success,
					description: `Su contraseña fue modificada exitosamente`,
				});
				form.resetFields(['password', 'newPassword']);
			})
			.catch((e) =>
				CustomNotification({
					type: TypeNotification.error,
					description: e.message,
				}),
			);
	};

	return (
		<>
			<Row justify="center" style={{ marginBottom: '-25px' }}>
				<SafetyOutlined className="own-global-icon-large" />
			</Row>
			<Row justify="center">
				<Col xs={22} md={10}>
					<CustomCard title={'Cambiar contraseña'} bordered={false}>
						<Row justify="center">
							<Col span={21}>
								{user.autentication !== 'google' && (
									<CustomForm layout="vertical" form={form} onFinish={handleOnFinish}>
										<CustomFormItem name={'password'} label={'Contraseña Actual'} rules={'password'}>
											<CustomInput placeholder={'Ingrese su contraseña'} type={'password'} />
										</CustomFormItem>

										<CustomFormItem name={'newPassword'} label={'Nueva Contraseña'} rules={'password'}>
											<CustomInput placeholder={'Ingrese su nueva contraseña'} type={'password'} />
										</CustomFormItem>

										<Row gutter={16}>
											<Col xs={12} sm={12} md={12}>
												<CustomButton block className="own-primary-button" onClick={() => history.push('/profile/resume')}>
													Atrás
												</CustomButton>
											</Col>
											<Col xs={12} sm={12} md={12}>
												<CustomButton block htmlType={'submit'}>
													Modificar
												</CustomButton>
											</Col>
										</Row>
									</CustomForm>
								)}
								{user.autentication === 'google' && (
									<CustomResult
										icon={<SmileOutlined />}
										title="Google"
										subTitle="Iniciaste sesión con Google, no es necesario que cambies tu contraseña."
									/>
								)}
							</Col>
						</Row>
					</CustomCard>
				</Col>
			</Row>
		</>
	);
}
