/** @format */

import React, { useEffect, useState } from 'react';
import { Col, Row, Badge } from 'antd';
import numberFormatter from 'number-formatter';

import CustomButton from '../../../../../../components/Antd/Button';
import CustomCard from '../../../../../../components/Antd/Card';

import './style.css';
export default function OneGrid(props) {
	const [data, setData] = useState(props.componentData);
	const [color] = useState(props.componentColor);
	useEffect(() => {
		setData(props.componentData);
		console.log('useEffect', 'OneGrid', props);
	}, [props]);

	const handleProductPrice = (item) => {
		console.log('handleProductPrice', item.price_offer);
		if (item.price_offer) {
			return (
				<>
					<span className={'own-onegrid-old-price'}>
						{numberFormatter('#.###,##', item.price)} {item.currency}
					</span>{' '}
					<span className={'own-onegrid-new-price'}>
						{numberFormatter('#.###,##', item.price_offer)} {item.currency}
					</span>{' '}
				</>
			);
		} else {
			return (
				<span className={'own-onegrid-original-price'}>
					{numberFormatter('#.###,##', item.price)} {item.currency}
				</span>
			);
		}
	};

	return (
		<>
			{data.map((iterator, key) => {
				return (
					<Col span={24} key={'col' + key}>
						<CustomCard
							className={'own-card-product own-onegrid'}
							key={'card' + key}
							cover={<img alt={iterator.name} src={iterator.image} />}
							actions={[
								<CustomButton size="small" className="own-onegrid-button-delete" onClick={() => props.componenteDelete(iterator)}>
									Eliminar
								</CustomButton>,
								<CustomButton size="small" className="own-onegrid-button-add" onClick={() => props.componentAdd(iterator)}>
									Agregar
								</CustomButton>,
							]}
						>
							<Row className="own-onegrid-name">{iterator.name}</Row>
							<Row className="own-onegrid-price-content">{handleProductPrice(iterator)}</Row>
							<Row className="own-onegrid-badge">{iterator.count >= 1 && <Badge count={`Cantidad agregada: ${iterator.count}`}></Badge>}</Row>
							<br></br>
							<CustomButton className="own-default-button" block style={{ borderColor: color }} onClick={() => props.componentGo(iterator)}>
								Ver Detalle
							</CustomButton>
						</CustomCard>
					</Col>
				);
			})}
		</>
	);
}
