/** @format */

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Form } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import Loading from '../../../../../components/Loading';
import CustomCard from '../../../../../components/Antd/Card';
import CustomButton from '../../../../../components/Antd/Button';
import CustomRadioButton from '../../../../../components/Antd/RadioButton';
import DrawerOptions from '../../../components/DrawerOptions';

import { serviceGetData, serviceUpdateData } from '../services';
import { Constant } from './constant';
import './style.css';

export default function AccountColor(props) {
	const history = useHistory();
	const { account, modify } = useParams<any>();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState(true);
	const [link, setLink] = useColor('hex', '#210358');
	const [text, setText] = useColor('hex', '#FFFFFF');
	const [icon, setIcon] = useColor('hex', '#FFFFFF');
	const [current, setCurrent] = useState<any>('link');
	const [radio, setRadio] = useState<any>('link');
	const [isModify, setIsModify] = useState<any>(false);
	const [textButton, setTextButton] = useState<any>('Finalizar');

	useEffect(() => {
		console.log('useEffects', 'AccountColor');
		if (modify) {
			setIsModify(true);
			setTextButton('Actualizar');
		}
		initData(account);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, modify]);

	const initData = async (account) => {
		await serviceGetData(account)
			.then((response) => {
				// link.hex = response.color.link;
				// text.text = response.color.text;
				// icon.icon = response.color.icon;
				setData(response);
				setLink(link);
				setText(text);
				setIcon(icon);
				setLoading(false);
			})
			.catch((e) => console.log(e));
	};

	const handleChangeColor = () => {
		switch (current) {
			case 'link':
				return <ColorPicker width={250} height={150} color={link} onChange={setLink} hideHSV hideRGB />;
			case 'icon':
				return <ColorPicker width={250} height={150} color={icon} onChange={setIcon} hideHSV hideRGB />;
			case 'text':
				return <ColorPicker width={250} height={150} color={text} onChange={setText} hideHSV hideRGB />;
			default:
				break;
		}
	};

	const handleSubmit = () => {
		const item = {
			background: link.hex,
			text: text.hex,
			icon: icon.hex,
			link: link.hex,
		};
		data.color = item;
		console.log('data', data);
		serviceUpdateData(data)
			.then((response) => {
				console.log(response);
				if (isModify === false) {
					history.push(`/profile/modules/${account}`);
				}
			})
			.catch((e) => console.log(e));
	};

	if (isLoading) {
		return <Loading />;
	}

	if (data !== undefined) {
		return (
			<>
				{isModify && (
					<>
						<DrawerOptions account={account} />
					</>
				)}
				<Row justify="center">
					<Col xs={22} md={10}>
						<CustomCard title={'Colores'} bordered={false}>
							<Form form={form} initialValues={data}>
								<Row justify={'center'}>
									<Col span={21}>
										<Row justify={'center'}>
											<img style={{ borderColor: link.hex }} className="own-color-img" src={data.image} alt={data.name} />
										</Row>

										{data.links.map((item, key) => {
											return (
												<a href={item.url} target="_blank" key={key} rel="noopener noreferrer">
													<Row className="own-link" style={{ background: link.hex, borderColor: link.hex }}>
														<Col span={2}>
															<LinkOutlined className="cv-linktree-link-icon" style={{ color: icon.hex }} />
														</Col>
														<Col span={22}>
															<span style={{ color: text.hex }}>{item.title}</span>
														</Col>
													</Row>
												</a>
											);
										})}

										<br></br>

										<Row justify="center">
											<CustomButton
												block
												onClick={() => {
													handleSubmit();
												}}
											>
												{textButton}
											</CustomButton>
										</Row>

										<br></br>
										<Row justify="center">
											<CustomRadioButton
												onChange={(e) => {
													setRadio(e.target.value);
													if (e.target.value === Constant.link) setCurrent('link');
													if (e.target.value === Constant.text) setCurrent('text');
													if (e.target.value === Constant.icon) setCurrent('icon');
												}}
												value={radio}
												label={'Colores de:'}
												items={[
													{
														label: 'Links',
														value: 'link',
													},
													{
														label: 'Texto',
														value: 'text',
													},
													{
														label: 'Icon',
														value: 'icon',
													},
												]}
											/>
										</Row>
										<br></br>
										<Row justify="center">{handleChangeColor()}</Row>
									</Col>
								</Row>
							</Form>
						</CustomCard>
					</Col>
				</Row>
				<br></br>
			</>
		);
	}
}
