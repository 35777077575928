/** @format */

import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Config } from '../../../../../../../../_config/constants/config.constant';
import './style.css';

export default function VideoYoutube(props) {
	const [data] = useState(props.data);
	const [theme] = useState(props.theme);
	const [colorLink] = useState(props.colorLink);
	const [colorText] = useState(props.text);
	const isMobile = useMediaQuery({ maxDeviceWidth: 415 });
	const [showVideo, setShowVideo] = useState(null);

	useEffect(() => {
		console.log('useEffects', 'VideoCover');
	}, [props]);

	const handleVideoYoutube = (item) => {
		try {
			let videoFinal = [];
			if (item.split('watch?v=').length > 1) {
				let splitOne = item.split('watch?v=');
				console.log('whatch&', splitOne[1].split('&'));
				let splitTwo = splitOne[1].split('&');
				videoFinal = splitTwo[0];
				console.log('whatch', videoFinal);
			} else if (item.split('youtu.be/').length > 1) {
				videoFinal = item.split('youtu.be/')[1];
				console.log('youtu.be/', videoFinal);
			}
			return 'https://www.youtube.com/embed/' + videoFinal;
		} catch (e) {
			return false;
		}
	};

	return (
		<>
			{data.type === Config.optionsType.youtubeVideo && (
				<>
					<div style={{ background: colorLink }} className={theme} onClick={() => setShowVideo(showVideo === null ? data.id : null)}>
						{data.icon === 'generico' && <img src={'https://i.ibb.co/V2byZdS/link.png'} alt={data.title} title={data.title} />}
						{data.icon !== 'generico' && <img src={data.icon} alt={data.title} title={data.title} />}
						<span style={{ color: colorText }}>{data.title}</span>
					</div>

					{showVideo === data.id && (
						<div>
							<iframe
								style={{ borderRadius: '10px', marginBottom: '10px' }}
								width="100%"
								height={isMobile ? '175' : '300'}
								src={handleVideoYoutube(data.url) as string}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					)}
				</>
			)}
		</>
	);
}
