import { Enviroment } from '../../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async getService(id) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/product/${id}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async getAccount(account) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/account/get-detail/${account}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
