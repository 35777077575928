/** @format */

import { Enviroment } from '../../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async get(account) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/suscriber/check-module`,
			data: {
				account: account,
				type: 'customer',
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}

	async delete(id) {
		return new AxiosAdapter({
			method: Http.DELETE,
			url: `${Enviroment.HOST}/suscriber/${id}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}

	async pagination(account, page, limit) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/suscriber/pagination`,
			data: {
				account: account,
				type: 'customer',
				page: page,
				limit: limit,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				return e;
			});
	}
}
