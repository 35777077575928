/** @format */

import React, { useState } from 'react';
import renderHTML from 'react-render-html';

import NavBarLink from './components/NavBarLink';
import './style.css';

export default function Biography(props) {
	console.log('Biography', props);
	const [data] = useState(props.data);
	const [color] = useState(props.data.color.link);
	const [account] = useState(props.data.account);
	const [biography] = useState(props.data.biography);
	const [back] = useState(props.back);

	if (props.data === undefined) return <></>;

	return (
		<>
			<div className="own-biography-cover" style={{ backgroundColor: color }}>
				<NavBarLink account={account} color={color} back={back} />
			</div>
			<div className="own-biography-logo">
				<img src={data.image} alt={data.name} />
			</div>
			<div className="own-biography-description">
				<h1 className="own-biography-name">@{data.name}</h1>
				<div className="own-biography-text">{renderHTML(biography)}</div>
			</div>
		</>
	);
}
