/** @format */

import React from 'react';
import { Radio, Col, Row } from 'antd';
import './style.css';

export default function CustomRadioButton(props) {
	return (
		<>
			<h3>{props.label}</h3>
			<Radio.Group {...props} style={{ width: '100%' }}>
				<Row gutter={16}>
					{props.items.map((iterator, key) => {
						return (
							<Col span={24 / props.items.length} key={key}>
								<Radio.Button className={props.className || 'own-default-radio-button'} key={key} value={iterator.value}>
									{iterator.label}
								</Radio.Button>
							</Col>
						);
					})}
				</Row>
			</Radio.Group>
		</>
	);
}
