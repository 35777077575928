/** @format */

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AllComponent from '../components/AllComponents';
import Layout from '../components/Layout/Layout';
import PrivateRoute from './components/PrivateRoute';
// import AdminRoute from './components/AdminRoute'

import SocketPage from '../pages/test/socket';
import ReportPage from '../pages/test/reports';
import ReactQueryPage from '../pages/test/react-query';
import ReactDndPage from '../pages/test/react-dnd';

import AccountDetail from '../pages/account/pages/account';
import AccountStore from '../pages/account/pages/store';
import AccountServices from '../pages/account/pages/services';
import DetailService from '../pages/account/pages/services/details';

import Resume from '../pages/profile/pages/resume';
import ChangePassword from '../pages/profile/pages/change-password';

import OptionWizzard from '../pages/profile/pages/option-wizzard';
import AccountUser from '../pages/profile/pages/account-wizzard/user';
import AccountBiography from '../pages/profile/pages/account-wizzard/biography';
import AccountBodyLink from '../pages/profile/pages/account-wizzard/body-link';
import AccountMainLink from '../pages/profile/pages/account-wizzard/main-link';
import AccountColor from '../pages/profile/pages/account-wizzard/color';
import AccountAnimation from '../pages/profile/pages/account-wizzard/animation';

import MyModules from '../pages/profile/pages/my-modules';

import Products from '../pages/profile/pages/product-wizzard/products';
import ProductCreate from '../pages/profile/pages/product-wizzard/create';
import ProductUpdate from '../pages/profile/pages/product-wizzard/update';

import Agents from '../pages/profile/pages/agent-wizzard/agents';
import CreateAgent from '../pages/profile/pages/agent-wizzard/create';
import UpdateAgent from '../pages/profile/pages/agent-wizzard/update';

import ServicesPage from '../pages/profile/pages/service-wizzard/service';
import ServiceCreate from '../pages/profile/pages/service-wizzard/create';
import ServiceUpdate from '../pages/profile/pages/service-wizzard/update';

import Customer from '../pages/profile/pages/customer-wizzard/customer';

import VideoCover from '../pages/profile/pages/video-cover';

import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
import Recovery from '../pages/auth/recovery';
import ErrorPage from '../components/Error';
import Help from '../pages/help';

const Routers = (props) => (
	<BrowserRouter>
		<Layout>
			<Switch>
				<Route exact path="/test/socket/:user?" component={SocketPage} />
				<Route exact path="/test/report" component={ReportPage} />
				<Route exact path="/test/react-query" component={ReactQueryPage} />
				<Route exact path="/test/react-dnd" component={ReactDndPage} />

				<Route exact path="/" component={Login} />
				<Route exact path="/components" component={AllComponent} />
				<Route exact path="/auth/login" component={Login} />
				<Route exact path="/auth/register" component={Register} />
				<Route exact path="/auth/recovery" component={Recovery} />
				<Route exact path="/help/:slug" component={Help} />

				<PrivateRoute exact path="/profile/resume" component={Resume} />
				<PrivateRoute exact path="/profile/wizzard" component={OptionWizzard} />
				<PrivateRoute exact path="/profile/change-password" component={ChangePassword} />
				<PrivateRoute exact path="/profile/modules/:account" component={MyModules} />

				<PrivateRoute exact path="/profile/account-user" component={AccountUser} />
				<PrivateRoute exact path="/profile/account-biography/:account/:modify?" component={AccountBiography} />
				<PrivateRoute exact path="/profile/account-body-links/:account/:modify?" component={AccountBodyLink} />
				<PrivateRoute exact path="/profile/account-main-links/:account/:modify?" component={AccountMainLink} />
				<PrivateRoute exact path="/profile/account-color/:account/:modify?" component={AccountColor} />
				<PrivateRoute exact path="/profile/account-animation/:account" component={AccountAnimation} />

				<PrivateRoute exact path="/profile/product-wizzard/:account" component={Products} />
				<PrivateRoute exact path="/profile/product-create/:account" component={ProductCreate} />
				<PrivateRoute exact path="/profile/product-update/:account/:id" component={ProductUpdate} />

				<PrivateRoute exact path="/profile/agent-wizzard/:account" component={Agents} />
				<PrivateRoute exact path="/profile/agent-create/:account" component={CreateAgent} />
				<PrivateRoute exact path="/profile/agent-update/:account/:id" component={UpdateAgent} />

				<PrivateRoute exact path="/profile/service-wizzard/:account" component={ServicesPage} />
				<PrivateRoute exact path="/profile/service-create/:account" component={ServiceCreate} />
				<PrivateRoute exact path="/profile/service-update/:account/:id" component={ServiceUpdate} />

				<PrivateRoute exact path="/profile/customer-wizzard/:account" component={Customer} />

				<PrivateRoute exact path="/profile/video-cover/:account" component={VideoCover} />

				<Route exact path="/:account/product" component={AccountStore} />
				<Route exact path="/:account/service" component={AccountServices} />
				<Route exact path="/:account/service/:id" component={DetailService} />
				<Route exact path="/:account" component={AccountDetail} />
				<Route component={ErrorPage} />
			</Switch>
		</Layout>
	</BrowserRouter>
);

export default Routers;
