/** @format */

import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'antd';

import GoogleAnalytics from '../../../../../../_config/adapters/google-analitycs';
import { GoogleConstant } from '../../../../../../_config/adapters/google-analitycs/constants';

import { checkPosition } from './utils/check-position.utils';
import { Services } from './services';
import './style.css';

const getPromotion = async () => {
	return await new Services().get();
};

export default function Promotion(props) {
	const { data, isLoading, error } = useQuery('getPromotion', getPromotion);

	useEffect(() => {
		console.log('useEffect', 'Promotion');
		GoogleAnalytics({ action: GoogleConstant.actions.view, category: GoogleConstant.categories.promotion, label: 'view_promotion' } as any);
	}, []);

	const handleRedirect = (data) => {
		GoogleAnalytics({ action: GoogleConstant.actions.click, category: GoogleConstant.categories.promotion, label: data.company } as any);
		window.open(data.redirect, '_blank');
	};

	window.addEventListener('scroll', function () {
		let el = document.getElementById('own-promotion-id');
		let scroll = window.scrollY;
		checkPosition({ element: el, position: scroll });
	});

	if (isLoading) return <></>;

	if (error) return <></>;

	if (data === undefined) return <></>;

	return (
		<>
			<Row justify="center" id={'own-promotion-id'} className="own-promotion" onClick={() => handleRedirect(data)}>
				<Col span={2}>
					<img src={data.image} alt={data.title} title={data.title} />
				</Col>
				<Col span={22}>
					<p>{data.description}</p>
				</Col>
			</Row>
		</>
	);
}
