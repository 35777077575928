/** @format */

import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { MenuOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import CustomButton from '../../../../components/Antd/Button';
import CustomDrawer from '../../../../components/Antd/Drawer';
import Modules from '../Modules';

export default function DrawerOptions(props) {
	console.log('DrawerOptions', props);
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const [account] = useState(props.account);

	const onClose = () => {
		setOpen(false);
	};

	return (
		<>
			<br></br>
			<Row justify="center">
				{props.buttonBack && (
					<Col xs={10} md={4}>
						<CustomButton
							className="own-default-button"
							block
							icon={<ArrowLeftOutlined />}
							label={'Volver'}
							onClick={() => history.push(props.buttonBack)}
						/>
					</Col>
				)}

				<Col xs={10} md={3} offset={1}>
					<CustomButton block onClick={() => setOpen(true)} icon={<MenuOutlined />}>
						Menú
					</CustomButton>
				</Col>
			</Row>
			<br></br>
			<CustomDrawer placement={'left'} width={400} title="Opciones disponibles" onClose={onClose} visible={open}>
				<Modules account={account} class={'own-modules-option'} />
			</CustomDrawer>
		</>
	);
}
