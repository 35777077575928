/** @format */

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Form, Col, Row } from 'antd';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import Loading from '../../../../../components/Loading';
import CustomSelect from '../../../../../components/Antd/Select';
import CustomFormItem from '../../../../../components/Antd/FormItem';
import CustomInput from '../../../../../components/Antd/Input';
import CustomButton from '../../../../../components/Antd/Button';
import CustomCard from '../../../../../components/Antd/Card';
import CustomJodiEditor from '../../../../../components/Antd/JodiEditor';
import DrawerOptions from '../../../components/DrawerOptions';
import CustomNotification from '../../../../../components/Antd/Notification';

import UploadImage from './components/UploadImage';
import { Services } from '../services';
import './style.css';

export default function AccountBiography(props) {
	const history = useHistory();
	const { account, modify } = useParams<any>();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState(true);
	const [isModify, setIsModify] = useState<any>(false);
	const [buttonText, setButtonText] = useState('Continuar');
	const [imageProfile, setImageProfile] = useState<any>(undefined);
	const [isImageUpload] = useGlobal<any>('uploadImage');
	const [optionDomains, setOptionDomains] = useState<any>([]);
	const [allOptions, setAllOptions] = useState<any>([]);

	useEffect(() => {
		console.log('useEffects', 'AccountBiography');
		if (modify) {
			setIsModify(true);
			setButtonText('Actualizar');
		}
		new Services().get(account).then((response) => {
			setData(response);
			setImageProfile(response.image);
			setLoading(false);
		});
		new Services().getDomains().then((response) => {
			setOptionDomains([...response.select_options]);
			setAllOptions([...response.all_options]);
		});
	}, [account, modify]);

	const handleSetImageProfile = (item) => {
		setImageProfile(item);
	};

	const handleOnFinish = (item) => {
		console.log('item', item);
		if (imageProfile === undefined) {
			CustomNotification({
				type: TypeNotification.error,
				description: `Aún falta cargar la imagen de perfil`,
			});
			return;
		}
		item._id = data._id;
		item.name = item.account;
		console.log('handleOnFinish', item);
		new Services().update(item).then((response) => {
			CustomNotification({
				type: TypeNotification.success,
				description: `Se actualizo correctamente`,
			});
			if (isModify === false) {
				history.push(`/profile/account-body-links/${response.account}`);
			}
		});
	};

	if (isLoading === true) {
		return <Loading />;
	} else {
		return (
			<>
				{isModify && (
					<>
						<DrawerOptions account={account} />
					</>
				)}
				<Row justify="center">
					<Col xs={22} md={10}>
						<CustomCard title={'Biografía'} bordered={false}>
							<Row justify="center">
								<Col span={21}>
									<Form form={form} layout="vertical" initialValues={data} onFinish={handleOnFinish}>
										<CustomFormItem name={'account'} label={'Tu usuario'}>
											<CustomInput type={'text'} value={data.account} disabled={true} />
										</CustomFormItem>
										{isModify === true && (
											<CustomFormItem label={'Links Disponibles'} name={'domain'} rules={'required'}>
												<CustomSelect
													mode={'single'}
													placeholder={'Seleccione una opción'}
													options={[...optionDomains]}
													onChange={(e) => {
														const filterOption = allOptions.filter((iterator) => {
															return iterator.name === e;
														});
														if (filterOption[0].type === 'domain-premium') {
															window.location.replace(process.env.REACT_APP_SUPPORT as string);
														}
													}}
												/>
											</CustomFormItem>
										)}

										<CustomJodiEditor
											name={'biography'}
											label={'Biografía'}
											extra={'Describe de que trata tu negocio y/o cuenta.'}
											value={data.biography}
										/>

										<UploadImage title={'Actualizar Imagen'} account={data} componentHandle={handleSetImageProfile} />

										<CustomButton block disabled={isImageUpload} htmlType={'submit'}>
											{buttonText}
										</CustomButton>
									</Form>
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			</>
		);
	}
}
