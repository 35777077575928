import create from 'zustand';

export const useCounterStore = create((set) => ({
	countProduct: 0,
	increment: () =>
		set((state) => ({
			countProduct: state.countProduct + 1,
		})),
	decrement: () =>
		set((state) => ({
			countProduct: state.countProduct - 1,
		})),
}));
