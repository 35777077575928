/** @format */

import React from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'antd';
import { Services } from './services';
import './style.css';

const init = async () => {
	console.log('entro al init');
	const response = await new Services().get();
	console.log('response', response);
	return response;
};

export default function ReactQueryPage(props) {
	const { data, isLoading, error } = useQuery('getData', init);

	if (isLoading) return <div>Loading...</div>;
	// @ts-ignore
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<Row justify="center">
				<Col span={4}>Count</Col>
			</Row>
			<Row justify="center">
				<Col span={4}>Cuentas:</Col>
				<Col span={4}>{data.accounts}</Col>
			</Row>
			<Row justify="center">
				<Col span={4}>Productos:</Col>
				<Col span={4}>{data.products}</Col>
			</Row>
			<Row justify="center">
				<Col span={4}>Servicios:</Col>
				<Col span={4}>{data.services}</Col>
			</Row>
		</>
	);
}
