/** @format */

import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import Modules from '../../components/Modules';
import { serviceGetData } from './services';
import './style.css';

interface RouteParams {
	account: string;
}

export default function MyModules(props) {
	const { account } = useParams<RouteParams>();

	useEffect(() => {
		console.log('useEffect', 'MyModules', props);
		init(account);
	}, [props, account]);

	const init = (item) => {
		serviceGetData(item).then((response) => {
			console.log('init', response);
		});
	};
	return (
		<Row justify="center">
			<Col xs={23} md={10}>
				<Modules account={account} class={'own-modules-option'} />
			</Col>
		</Row>
	);
}
