/** @format */

import lodash from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Result } from 'antd';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { TypeNotification } from '../../../../../_config/constants/notification.constant';
import { Enviroment } from '../../../../../_config/constants/enviroment.constant';

import CustomButton from '../../../../../components/Antd/Button';
import CustomNotification from '../../../../../components/Antd/Notification';
import Loading from '../../../../../components/Loading';

import { Services } from '../services';
import './style.css';

const Accounts = (props) => {
	const history = useHistory();
	const [data, setData] = useState<any>(lodash.orderBy(props.data, ['eneable'], ['asc']));
	const [isLoading, setLoading] = useState(false);
	const [count, setCount] = useState<any>();

	useEffect(() => {
		const check = props.data.filter((item) => {
			return item.eneable === false;
		});
		setCount(check);
		setLoading(true);
	}, [props]);

	const handleDeleteAccount = (item) => {
		let btn = (
			<CustomButton
				onClick={() => {
					setData(
						data.filter((iterator) => {
							return iterator._id !== item._id;
						}),
					);
					new Services()
						.delete({
							id: item._id,
						})
						.then(() => {
							CustomNotification({
								type: TypeNotification.success,
								description: 'Cuenta eliminada correctamente',
							});
							CustomNotification({
								type: TypeNotification.close,
								key: 'close-notification',
							});
						})
						.catch((e) => console.log(e));
				}}
			>
				Confirmar
			</CustomButton>
		);

		CustomNotification({
			type: TypeNotification.warning,
			message: 'Eliminar Cuenta',
			description: `¿Estás seguro que quieres eliminar la cuenta "${item.account}"?`,
			btn,
			key: 'close-notification',
		});
	};

	if (isLoading === false) {
		return <Loading />;
	}
	if (data.length <= 0) {
		return (
			<Result
				status="error"
				title="Cuentas Registradas"
				subTitle="No tienes ningùn LINK registrado actualmente, para comenzar sólo debes presionar el siguiente botón"
				extra={[
					<CustomButton
						key="account-create-link"
						onClick={() => {
							history.push(`/profile/account-user`);
						}}
						className={'own-primary-button'}
					>
						Crear Link
					</CustomButton>,
				]}
			></Result>
		);
	}
	if (data.length > 0) {
		return (
			<>
				<br></br>
				<Row>
					<h3>Mis Enlaces</h3>
				</Row>
				<Row>
					<h5>{count.length && <>Tienes {count.length} cuenta(s) registradas</>}</h5>
				</Row>
				<Row justify="center">
					{data.map((item, key) => {
						return (
							<Col xs={24} sm={12} md={7} key={key} className="own-accounts-card">
								<Row className="own-accounts-card-info">
									<Col span={3}>
										<img
											className="own-accounts-card-img"
											src={item.image || process.env.REACT_APP_LOGO}
											alt={item.name}
											title={item.name}
										/>
									</Col>
									<Col span={21}>
										<Row>
											<Col span={22}>
												<h3>{item.account}</h3>
											</Col>
											<Col span={2}>
												<CustomButton
													className="own-default-button"
													icon={<DeleteOutlined />}
													size={'medium'}
													onClick={() => {
														handleDeleteAccount(item);
													}}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<CopyToClipboard text={`${Enviroment.DOMAIN}/${item.account}`}>
											<CustomButton
												className="own-accounts-option"
												block
												style={{ margin: '0px 0px' }}
												onClick={() => {
													CustomNotification({
														type: TypeNotification.success,
														description: `Enlace copiado, listo para compartir`,
													});
												}}
											>
												Copiar <CopyOutlined /> {item.domain}/{item.account}
											</CustomButton>
										</CopyToClipboard>
									</Col>
								</Row>
								<Row>
									<Col span={12}>
										<CustomButton
											className="own-accounts-config own-accounts-config-left"
											block
											shape="round"
											href={`/${item.name}`}
											target="_blank"
										>
											Vista Previa
										</CustomButton>
									</Col>
									<Col span={12}>
										<CustomButton
											className="own-accounts-config own-accounts-config-right"
											block
											style={{ margin: '0px 0px' }}
											shape="round"
											href={`/profile/modules/${item.name}`}
										>
											Editar
										</CustomButton>
									</Col>
								</Row>
							</Col>
						);
					})}
				</Row>
			</>
		);
	}

	return <></>;
};

export default Accounts;
