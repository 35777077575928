/** @format */

import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import './style.css';

export default function CoverYoutube(props) {
	const isMobile = useMediaQuery({ maxDeviceWidth: 415 });

	useEffect(() => {
		console.log('useEffects', 'CoverYoutube');
	}, [props]);

	const handleVideoYoutube = (item) => {
		try {
			let videoFinal = [];
			if (item.split('watch?v=').length > 1) {
				let splitOne = item.split('watch?v=');
				console.log('whatch&', splitOne[1].split('&'));
				let splitTwo = splitOne[1].split('&');
				videoFinal = splitTwo[0];
				console.log('whatch', videoFinal);
			} else if (item.split('youtu.be/').length > 1) {
				videoFinal = item.split('youtu.be/')[1];
				console.log('youtu.be/', videoFinal);
			}
			return 'https://www.youtube.com/embed/' + videoFinal;
		} catch (e) {
			return false;
		}
	};

	return (
		<>
			<iframe
				style={{ borderRadius: '10px', marginBottom: '10px' }}
				width="100%"
				height={isMobile ? '175' : '300'}
				src={handleVideoYoutube(props.url) as string}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
		</>
	);
}
