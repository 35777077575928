import { Storage } from '../../constants/storage.constant';
export const SetUserStorage = (item) => {
	localStorage.setItem(
		Storage.user,
		JSON.stringify({
			email: item.email.trim(),
			image: item.image,
			first_name: item.first_name,
			last_name: item.last_name,
			referal: item.referal,
		}),
	);
};
