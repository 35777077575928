/** @format */

import React, { useState, useEffect } from 'react';
import { Upload, notification, Row, Col } from 'antd';
import ImgCrop from 'antd-img-crop';

import { Config } from '../../_config/constants/config.constant';
import { serviceUploadImage } from './services';
import './style.css';

export default function UploadImageSquare(props) {
	const [count, setCount] = useState(0);
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		console.log('useEffect', props.image);
		if (props.image) {
			setFileList([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: props.image,
					image: props.image,
					image_thumb: props.image,
				} as never,
			]);
		}
	}, [props]);

	const beforeUpload = (file) => {
		console.log('beforeUpload', file);
		const isSize = file.size / 1024 / 1024 <= 0.5;
		if (!isSize) {
			notification['error']({
				message: `¡Ups!`,
				description: `La imagen es muy pesada, reduce un poco más el peso`,
			});
		}
		return isSize;
	};

	const handleOnChange = ({ fileList: item }) => {
		console.log('handleOnChange', item);
		setFileList(item);
		if (item.length > 0) {
			if (count === 0) {
				handleSaveImage(item);
				setCount(1);
			}
		} else {
			setCount(0);
			props.handle(undefined);
		}
	};

	const handleSaveImage = async (item) => {
		console.log('handleSaveImage', item);
		console.log('name', props.name);
		let formData = new FormData();
		formData.append('image', item[0].originFileObj);
		formData.append('name', `${props.name}`);
		formData.append('key', process.env.REACT_APP_IMBB_API_KEY as string);
		console.log('formData', formData);
		await serviceUploadImage(formData).then((response) => {
			console.log('response', response);
			props.handle(response.medium.url);
		});
	};

	const handleOnPreview = async (item) => {
		let src = item.url;
		if (!src) {
			src = await new Promise((resolve) => {
				let reader = new FileReader();
				reader.readAsDataURL(item.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		let image = new Image();
		image.src = src;
		let imgWindow = window.open(src);
		if (imgWindow) {
			imgWindow.document.write(image.outerHTML);
		}
	};

	return (
		<Row justify="center" className="own-uplodad-image-square">
			<Col span={7}>
				<ImgCrop>
					<Upload
						listType="picture-card"
						fileList={fileList}
						onChange={handleOnChange}
						onPreview={handleOnPreview}
						beforeUpload={beforeUpload}
						// aspect={1 / 1}
						progress={'line' as any}
						accept={props.accept || Config.format}
					>
						{fileList.length <= 0 && 'CLICK'}
					</Upload>
				</ImgCrop>
			</Col>
			<Col span={16} offset={1}>
				<br></br>
				<br></br>
				<h4>{props.title}</h4>
				<h5>Click en el recuadro</h5>
				<h6>(obligatoria)</h6>
			</Col>
		</Row>
	);
}
