/** @format */

import React, { useState } from 'react'

import { Modal, Form, Button } from 'antd'

import { EditOutlined } from '@ant-design/icons'

import InputField from '../../../../../../../components/Form/Input'
import SelectField from '../../../../../../../components/Form/Select'

import './style.css'

export default function EditLinkModal(props) {
	const [form] = Form.useForm()
	const [isVisible, setVisible] = useState(false)

	const handleEditLink = (item) => {
		let data
		data = {
			id: props.params.item.id.toString(),
			type: item.type,
			title: item.type,
			url: item.url,
		}
		props.handleEditOneLink(data)
		setVisible(false)
	}

	return (
		<>
			<Button
				className='cv-account-wizard-social-links-button-delete-container'
				danger
				type='link'
				shape='round'
				icon={<EditOutlined />}
				onClick={() => {
					setVisible(true)
				}}>
				<span className='cv-account-wizard-social-links-button-delete'>Editar</span>
			</Button>
			<Modal
				wrapClassName='cv-edit-modal-links-container'
				maskClosable={true}
				width='700px'
				centered
				visible={isVisible}
				onCancel={() => setVisible(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<h3 className='cv-edit-modal-links-main-title'>Editar Enlace</h3>
				<Form
					form={form}
					initialValues={{
						title: props.params.item.title,
						url: props.params.item.url,
						radio: props.params.item.type,
						type: props.params.item.type,
					}}
					onFinish={handleEditLink}>
					<div className='own-form-container'>
						<SelectField
							componentLabel={'Red Social'}
							componentName={'type'}
							componentMode={'single'}
							componentPlaceholder={'Seleccione una opción'}
							componentOptions={props.params.redSocial}
							componentRules={'rulesSelect'}
						/>
						<InputField
							componentName={'url'}
							componentLabel={`Coloca aquí tu enlace`}
							componentRules={'required'}
							componentPlaceholder={'Copia tu link aquí'}
							componentType={'text'}
							componentValue={props.params.item.url}
						/>
						<p className='cv-account-wizard-social-example-title'>Ejemplo: https://www.creatulink.com/</p>
					</div>
					<div className='cv-edit-modal-links-button-container'>
						<Button htmlType={'submit'} className='cv-edit-modal-links-button'>
							Editar
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	)
}
