/** @format */
import React from 'react'
import { Button } from 'antd'

import './style.css'

export default function Domain(props) {
	return (
		<>
			<p style={{ textAlign: 'center' }}>
				Así se vería
				<span role='img' aria-label='cara lentes'>
					🤩
				</span>
			</p>
			<Button type='dashed' block shape='round' className={'own-button-domain'}>
				<span style={{ color: 'red' }}>{props.domain}</span>
			</Button>
		</>
	)
}
