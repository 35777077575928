/** @format */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Form, Row, Col } from 'antd';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import { GetUserStorage } from '../../../../../_config/utils/user-storage/get-user-storage.utils';

import CustomForm from '../../../../../components/Antd/Form';
import CustomCard from '../../../../../components/Antd/Card';
import CustomButton from '../../../../../components/Antd/Button';
import CustomFormItem from '../../../../../components/Antd/FormItem';
import CustomInput from '../../../../../components/Antd/Input';
import UploadImagesAlt from '../../../../../components/UploadImagesAlt';
import CustomNotification from '../../../../../components/Antd/Notification';
import Domain from '../../../components/Domain';

import { Services } from '../services';
import './style.css';

export default function AccountUser(props) {
	const history = useHistory();
	const [form] = Form.useForm();
	const [data] = useState<any>({});
	const [user] = useState(GetUserStorage());
	const [imageProfile, setImageProfile] = useState(undefined);
	const [imageCover] = useState(process.env.REACT_APP_LOGO);
	const [name, setName] = useState();
	const [textImage] = useState('Tu LOGO');
	const [isImageUpload] = useGlobal<any>('uploadImage');
	const [domain] = useState('creatulink.com');
	const [completeDomain, setCompleteDomain] = useState<any>();

	const handleOnFinish = (item) => {
		if (imageProfile === undefined) {
			CustomNotification({
				type: TypeNotification.error,
				description: `Aún falta cargar la imagen de perfil`,
			});
			return;
		}

		item.email = user.email;
		item.referal = user.referal;
		item.name = item.type !== undefined ? `${item.account}-${item.type}` : item.account;
		item.image = imageProfile;
		item.image_thumb = imageProfile;
		item.image_cover = imageCover;
		item.domain = domain;
		item.type_account = 'business';
		new Services()
			.create(item)
			.then((response) => {
				history.push(`/profile/account-biography/${response.account}`);
			})
			.catch((e) => {
				CustomNotification({
					type: TypeNotification.error,
					description: `Este link ya esta reservado`,
				});
				return;
			});
	};

	return (
		<>
			{data !== undefined && (
				<Row justify="center">
					<Col xs={22} md={10}>
						<CustomCard title={'Reservemos tu nombre'} bordered={false}>
							<Row justify="center">
								<Col span={21}>
									<CustomForm layout="vertical" form={form} initialValues={data} onFinish={handleOnFinish}>
										<CustomFormItem name={'account'} label={'Nombre de tu enlace'} rules={'account'}>
											<CustomInput
												value={data.account}
												type={'text'}
												example={'Este es el nombre que verán las personas cuando compartas tu link, Además debe ser único'}
												onChange={(e) => {
													setName(e.target.value);
													setCompleteDomain(`${domain}/${e.target.value}`);
												}}
											/>
										</CustomFormItem>

										{name !== undefined && <Domain domain={completeDomain} />}

										{name !== undefined && (
											<>
												<UploadImagesAlt
													title={textImage}
													componentName={`${name}-${Date.now()}`}
													componentHandle={(e) => {
														setImageProfile(e);
													}}
												/>

												<Row>
													<Col span={6}>
														<img
															style={{ width: '100%' }}
															className="cv-profile-account-img-phone-type-account"
															src="https://i.ibb.co/c6FVtS2/Grupo-45.png"
															title="Teléfonos"
															alt="Teléfonos"
														/>
													</Col>
													<Col span={18}>
														<h3>Tu Cuenta</h3>
														<p>Asi luciría tu cuenta al terminar todo el proceso, asi que ánimo y continua que falta poco.</p>
													</Col>
												</Row>

												<br />
											</>
										)}

										{domain !== undefined && (
											<>
												<CustomButton block disabled={isImageUpload} htmlType={'submit'}>
													Siguiente
												</CustomButton>
											</>
										)}
									</CustomForm>
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			)}
		</>
	);
}
