/** @format */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';

import Loading from '../../../../../components/Loading';
import CustomCard from '../../../../../components/Antd/Card';
import CustomButton from '../../../../../components/Antd/Button';
import CustomTable from '../../../../../components/Antd/Table';
import CustomSelect from '../../../../../components/Antd/Select';
import PageError from '../../../../../components/Common/PageError';
import DrawerOptions from '../../../components/DrawerOptions';
import ModalConfig from '../../../components/ModalConfig';
import CustomNotification from '../../../../../components/Antd/Notification';
import { PaginationConstant } from '../../../../../components/Constant/pagination.constant';

import { Services } from './services';
import './style.css';

interface RouterParams {
	account: string;
}

export default function Customer(props) {
	const [data, setData] = useState<any>();
	const { account } = useParams<RouterParams>();
	const [module, setModule] = useState<any>([]);
	const [isLoading, setLoading] = useState(true);
	const [isError, setError] = useState(false);
	const [isMore, setShowMore] = useState(false);
	const [isButtoonLoading, setButtonLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentRecords, setCurrentRecords] = useState(0);
	const [limitPagintation, setLimitPagination] = useState(20);
	const headersCsv = [
		{ label: 'Nombre', key: 'name' },
		{ label: 'Número', key: 'phone' },
		{ label: 'Correo', key: 'email' },
		{ label: 'Link WhatsApp', key: 'whatsapp' },
		{ label: 'Asunto', key: 'subject' },
	];
	const columnsTable = [
		{
			title: 'Nombre',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (record.name.length > 10 ? record.name.substr(0, 10) : record.name),
		},
		{
			title: 'WhatsApp',
			dataIndex: 'phone',
			key: 'phone',
			render: (_, record) => (
				<a href={`https://wa.me/${record.code}${record.phone}`}>
					{record.code}
					{record.phone.substr(0, 3)}***
				</a>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_, record) => (
				<>
					<CustomButton
						size={'large'}
						className="own-default-button"
						disabled={module === null ? true : false}
						onClick={() => handleDelete(record)}
						icon={<DeleteOutlined />}
					></CustomButton>
				</>
			),
		},
	];

	useEffect(() => {
		console.log('useEffect', 'Customer');
		new Services()
			.get(account)
			.then((response) => {
				if (response.records.length === 20) {
					setShowMore(true);
					setCurrentPage(1);
				}
				setData(response.records);
				setCurrentRecords(response.current_records);
				setModule(response.module);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	}, [account]);

	const handleAdapterTable = (item) => {
		return item.map((iterator, key) => {
			iterator.key = key;
			return iterator;
		});
	};

	const handleCsvFile = (data) => {
		return data.map((iterator) => {
			console.log(iterator);
			return {
				name: iterator.name,
				phone: (iterator.code || '') + iterator.phone,
				email: iterator.email,
				whatsapp: 'https://wa.me/' + (iterator.code || '') + iterator.phone,
				subject: iterator.subject,
			};
		});
	};

	const handleDelete = (item) => {
		let btn = (
			<CustomButton
				onClick={() => {
					new Services().delete(item._id).then((response) => {
						setData(
							data.filter((iterator) => {
								return iterator._id !== item._id;
							}),
						);
						setCurrentRecords(currentRecords - 1);
						notification.close('notification-delete');
					});
				}}
			>
				Confirmar
			</CustomButton>
		);

		CustomNotification({
			type: TypeNotification.warning,
			description: `¿Estás seguro que quieres eliminar este contacto: ${item.name}?`,
			btn,
			key: 'notification-delete',
		});
	};

	const handleLoadingMore = (account, currentPage, limitPagintation) => {
		setButtonLoading(true);
		new Services()
			.pagination(account, currentPage, limitPagintation)
			.then((response) => {
				if (response.data.length === limitPagintation) {
					setShowMore(true);
					setCurrentPage(currentPage + 1);
				} else {
					setShowMore(false);
				}
				if (currentPage === 1) {
					setData([...response.data]);
				} else {
					setData([...data, ...response.data]);
				}
				setButtonLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	};

	if (isLoading === true) {
		return <Loading />;
	}

	if (isError === true) {
		return <PageError errorTitle={'Conexión fallida'} />;
	}

	if (data) {
		return (
			<>
				<DrawerOptions account={account} />

				<Row justify="center">
					<Col xs={22} md={10}>
						<Col span={3} offset={21}>
							<ModalConfig title={'WhatsApp'} key={'modal'} module={'customer'} account={account} showPhone={false} />
						</Col>
					</Col>
				</Row>
				<br></br>

				<Row justify="center">
					<Col xs={22} md={10}>
						<CustomCard title={'Posibles Clientes'} bordered={false}>
							<Row justify="center">
								<Col span={24}>
									<CustomTable
										columns={columnsTable}
										dataSource={handleAdapterTable(data)}
										pagination={false}
										bordered
										footer={() => {
											return (
												<>
													<Row justify="center">
														<Col xs={18} sm={18} xl={18} style={{ paddingTop: '16px' }}>
															Mostrando: {data.length} de {currentRecords}
														</Col>
														<Col xs={6} sm={6} xl={6}>
															<CustomSelect
																name={'records'}
																mode={'single'}
																placeholder={'Cantidad de Registros'}
																options={[...PaginationConstant]}
																onChange={(e) => {
																	setLimitPagination(e);
																	setCurrentPage(1);
																	setData([]);
																	handleLoadingMore(account, 1, e);
																}}
															/>
														</Col>
													</Row>
												</>
											);
										}}
										expandable={{
											expandedRowRender: (record) => {
												return (
													<>
														<p style={{ margin: 0, textTransform: 'capitalize' }}>País: {record.country}</p>
														<p style={{ margin: 0 }}>Nombre: {record.name}</p>
														<p style={{ margin: 0 }}>Correo: {record.email}</p>
														<p style={{ margin: 0 }}>
															Número: {record.code}
															{record.phone}
														</p>
														<p style={{ margin: 0 }}>Mensaje: {record.subject}</p>
													</>
												);
											},
										}}
									/>
								</Col>
							</Row>
							<br></br>
							<Row justify="center" gutter={16}>
								<Col span={12}>
									<CustomButton block>
										<CSVLink
											data={handleCsvFile(data)}
											headers={headersCsv}
											filename={`${data.length}_Registros_WhatsApp_Creatulink.csv`}
											target="_blank"
										>
											Descargar
										</CSVLink>
									</CustomButton>
								</Col>
								<Col span={12}>
									{isMore === true && (
										<CustomButton
											block
											loading={isButtoonLoading}
											onClick={() => handleLoadingMore(account, currentPage, limitPagintation)}
										>
											Cargar más
										</CustomButton>
									)}
								</Col>
							</Row>
						</CustomCard>
					</Col>
				</Row>
			</>
		);
	}
}
