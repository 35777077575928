export const FacebookConstant = {
	pixel: '174855991527234',
	actions: {
		click: 'click',
		view: 'view',
	},
	categories: {
		account: 'account',
		navbar: 'navbar',
		promotion: 'promotion',
		event: 'event',
	},
};
