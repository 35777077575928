import { Config } from '../../../../../../_config/constants/config.constant';
export const addElement = async (arrayLinks, item) => {
	console.log('addElement', arrayLinks, item);
	try {
		const lengthLinks = arrayLinks.length;
		if (item.radio === Config.optionsType.whatsapp) {
			return {
				fields: ['message', 'title', 'number'],
				newLink: {
					id: lengthLinks,
					type: Config.optionsType.whatsapp,
					title: item.title,
					url: `https://api.whatsapp.com/send?phone=${item.number}&text=${item.message}`,
					number: item.number,
					msg: item.message,
				},
			};
		} else if (item.radio === Config.optionsType.social) {
			return {
				fields: ['title', 'url'],
				newLink: {
					id: lengthLinks,
					type: Config.optionsType.social,
					title: item.title,
					url: item.url,
				},
			};
		} else if (item.radio === Config.optionsType.web) {
			return {
				fields: ['title', 'url'],
				newLink: {
					id: lengthLinks,
					type: Config.optionsType.web,
					title: item.title,
					url: item.url,
				},
			};
		} else if (item.radio === Config.optionsType.coverVideo) {
			const checkCover = arrayLinks.filter((item) => item.type === Config.optionsType.coverVideo);
			if (checkCover.length > 0) {
				throw new Error('Ya existe un cover');
			}
			return {
				fields: ['title', 'url'],
				newLink: {
					id: lengthLinks,
					type: Config.optionsType.coverVideo,
					title: 'Video de Presentación',
					url: item.url,
				},
			};
		}
	} catch (e) {
		throw e;
	}
};
