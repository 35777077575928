import { Enviroment } from '../../../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async get(body) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/product/by-filter`,
			params: {
				account: body.account,
				type: body.type,
				currency: body.currency,
				category: body.category,
				page: body.page,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
