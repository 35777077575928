export const sortElement = async (elements) => {
	const newLinks = await elements.map((item, key) => {
		return {
			id: key.toString(),
			title: item.title,
			type: !item.type ? 'web' : item.type,
			url: item.url,
		};
	});

	return newLinks;
};
