import lodash from 'lodash';

export const handleSubTotal = async ({ products }) => {
	const priceMap = products.map((iterator) => {
		iterator.auxPrice = parseInt(iterator.price_offer) ? iterator.price_offer * iterator.count : iterator.price * iterator.count;
		return iterator;
	});
	const totalPay = await lodash.sumBy(priceMap, 'auxPrice');
	console.log('totalPay', totalPay);
	return totalPay;
};
