/** @format */

import React, { useEffect } from 'react';

import { useState } from 'react';
import { Row, Button, Input, Form, Col } from 'antd';
import io from 'socket.io-client';
import './style.css';
import { Services } from './services';
import { useParams } from 'react-router-dom';

export default function SocketPage(props) {
	// const [idSocket, setIdSocket] = useState(null);
	// const [msg, setMsg] = useState(null);

	const [data, setData] = useState<any>([]);
	const [form] = Form.useForm();
	const [socket, setSocket] = useState<any>(null);
	const { user } = useParams<any>();

	useEffect(() => {
		const socket = io('http://localhost:3002', {
			query: {
				email: 'diego.carciente@gmail.com',
			},
		});

		setSocket(socket);

		console.log(socket.query);
		console.log('solicitando notificacion');
		socket.emit('get-notification', socket.query);
		socket.on('receive-notifications', (socketResponse) => {
			console.log('se recibe la notificacion', socketResponse);
			setData([...socketResponse]);
		});

		// socket.on('new-notification', (socketResponse) => {
		// 	console.log('se recibe una nueva notificacion', socketResponse);
		// 	setData((prev) => [...prev, socketResponse]);
		// });
		// socket.emit('get-count-notifications', socket.query);
		// socket.on('count-notifications', (socketResponse) => {
		// 	console.log('se recibe conteo de notificaciones', socketResponse);
		// });
	}, []);

	const onFinish = (item) => {
		console.log(item);
		new Services()
			.sendNotification({
				type: 'new-order',
				email: user,
				trx_id: data.length,
				country: 'CL',
				data: { msg: item.msg + user },
			})
			.then((response) => {
				console.log(response);
			});
	};

	const handleDelete = (item) => {
		console.log(item);
		new Services().delete(item._id).then((response) => {
			console.log('response', response);
			console.log(socket.query);
			socket.emit('get-notification', socket.query);
		});
	};

	return (
		<>
			<Row justify="center">
				<Col span={18}>Web Socket</Col>
			</Row>
			<Row justify="center">
				<Col span={18}>
					<ul>
						{data.map((iterator, key) => {
							return (
								<li key={key}>
									{key} - {iterator.trx_id} - {iterator.data?.msg}
									<Button type="primary" onClick={() => handleDelete(iterator)}>
										Eliminar
									</Button>
								</li>
							);
						})}
					</ul>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={18}>
					<Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
						<Form.Item name="msg" label="Mensaje">
							<Input />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Enviar Notificacion
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
}
