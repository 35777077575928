/** @format */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Form, Divider } from 'antd';
import { TypeNotification } from '../../../_config/constants/notification.constant';
import { SetUserStorage } from '../../../_config/utils/user-storage/set-user-storage.utils';
import CustomInput from '../../../components/Antd/Input';
import CustomButton from '../../../components/Antd/Button';
import CustomForm from '../../../components/Antd/Form';
import CustomFormItem from '../../../components/Antd/FormItem';
import CustomNotification from '../../../components/Antd/Notification';
import { Services } from './services';
import './style.css';

const { Content } = Layout;

export default function Login() {
	const [email] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [form] = Form.useForm();

	const handleOnFinish = (item) => {
		setLoading(true);
		new Services()
			.login(item)
			.then((response) => {
				SetUserStorage(response);
				CustomNotification({
					type: TypeNotification.success,
					description: `¡Bienvenid@!`,
				});
				setTimeout(() => {
					history.push('/profile/resume');
				}, 1000);
			})
			.catch((e) => {
				console.log('error', e);
				CustomNotification({
					type: TypeNotification.error,
					description: 'Problemas para iniciar sesión',
				});
			});
		setLoading(false);
	};

	return (
		<>
			<div className="own-login-content">
				<Content className="own-global-main-container">
					<Row className="own-login-content-row" align="middle">
						<Col className="own-login-content-col" xs={24} sm={24} md={10} offset={1}>
							<h1>Un link para</h1>
							<ul>
								<li>Compartir tus redes sociales</li>
								<li>Compartir tus páginas webs</li>
								<li>Compartir tus guías</li>
								<li>Compartir tu CV</li>
								<li>Recolectar mails y números</li>
								<li>Gestionar tráfico de campañas</li>
								<li>Y mucho más...</li>
							</ul>
						</Col>
						<Col xs={24} sm={24} md={12}>
							<div className="own-login-content-logins">
								<div className="own-login-content-logins-center">
									<Link to={`/`}>
										<img
											title="Logo Creatulink"
											alt="Logo Creatulink"
											width="100px"
											className="cv-login-logo-app"
											src={process.env.REACT_APP_LOGO}
										/>
									</Link>
									<h2 className="own-login-title">Creatulink {email}</h2>
									<p className="own-login-sub-title">Un link para que vale la pena compartir</p>
								</div>
								<div className="own-login-content-redes-main">
									<CustomForm form={form} onFinish={handleOnFinish} layout="vertical">
										<CustomFormItem name={'email'} label={'Correo electrónico'} rules={'required'}>
											<CustomInput placeholder={'Ingrese su E-mail'} type={'text'} />
										</CustomFormItem>
										<CustomFormItem name={'password'} label={'Contraseña'} rules={'required'}>
											<CustomInput placeholder={'Ingrese su contraseña'} type={'password'} />
										</CustomFormItem>
										<Link to={`/auth/recovery`}>
											<h3 className="own-login-forgot-password">¿Olvidaste tu contraseña?</h3>
										</Link>
										<CustomButton block loading={loading} htmlType={'submit'}>
											Iniciar sesión
										</CustomButton>
									</CustomForm>
									<Divider />
									<div className="own-login-content-register">
										<Link to={`/auth/register`}>
											¿Aún no estás en Creatulink? <span>Regístrate</span>
										</Link>
									</div>
								</div>
								<br />
								<p className="own-login-title-termi-condition">
									Al continuar, aceptas las Condiciones del servicio y la Política de privacidad de Creatulink.
								</p>
								<div style={{ textAlign: 'center' }}>
									<Link to={`/`}>
										<span className="">Volver al Inicio</span>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Content>
			</div>
		</>
	);
}
