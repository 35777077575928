/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../Header';

import './style.css';

function Layout(props) {
	const handleBody = () => {
		return (
			<>
				<div className="own-layout">
					<Header />
					<div className="own-layout-br"></div>
					{props.children}
					{props.location.pathname !== '/profile/accounts' &&
						props.location.pathname !== '/profile/account-user' &&
						props.location.pathname !== '/profile/change-password' &&
						!window.location.pathname.match('/profile/account-biography') &&
						!window.location.pathname.match('/profile/account-links') &&
						!window.location.pathname.match('/profile/account-finish') &&
						!window.location.pathname.match('/profile/edit-account')}
				</div>
			</>
		);
	};
	const handleLayouts = () => {
		const splitRoute = props.location.pathname.split('/');
		const routeAdmited = ['profile', 'test', 'notifications', 'pricing', 'models', 'company', 'help', 'shorten', 'tools-whatsapp', 'giveaway'];
		if (routeAdmited.includes(splitRoute[1]) === true) {
			return handleBody();
		} else if (
			props.location.pathname === '/' ||
			props.location.pathname === '/auth/login' ||
			props.location.pathname === '/auth/recovery' ||
			props.location.pathname === '/auth/register' ||
			(splitRoute[0] === '' && splitRoute[1] !== '')
		) {
			return <>{props.children}</>;
		} else {
			return handleBody();
		}
	};

	return <React.Fragment>{handleLayouts()}</React.Fragment>;
}

export default withRouter(Layout);
