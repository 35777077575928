export const Icons = {
	biography: 'https://i.ibb.co/0fvX8Vr/usuario.png',
	links: 'https://i.ibb.co/F3YhVY7/destellos.png',
	links_main: 'https://i.ibb.co/3zdjprm/CONTACTO.png',
	color: 'https://i.ibb.co/QpScXQY/llenar.png',
	animation: 'https://i.ibb.co/MMK8wQc/ANIMACION.png',
	category: 'https://i.ibb.co/3Rv5tTt/juego.png',
	store: 'https://i.ibb.co/fXgDcBP/shopping-bag-anadir.png',
	service: 'https://i.ibb.co/vz2kVJC/taza-caliente-alt.png',
	seller: 'https://i.ibb.co/Myds4b7/comentarios.png',
	email: 'https://i.ibb.co/x1cp528/descarga-de-sobres.png',
	phone: 'https://i.ibb.co/KzjMsXH/phone.png',
	preview: 'https://i.ibb.co/QfrszPr/external.png',
	exit: 'https://i.ibb.co/CHqBQM0/deshacer.png',
	video: 'https://i.ibb.co/xf8zspK/video2.jpg',
};
