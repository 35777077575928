import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory, useParams } from 'react-router-dom';
import { Config } from '../../../../../_config/constants/config.constant';
import { TypeNotification } from '../../../../../_config/constants/notification.constant';
import CustomButton from '../../../../../components/Antd/Button';
import CustomCard from '../../../../../components/Antd/Card';
import CustomForm from '../../../../../components/Antd/Form';
import CustomFormItem from '../../../../../components/Antd/FormItem';
import CustomInput from '../../../../../components/Antd/Input';
import CustomNotification from '../../../../../components/Antd/Notification';
import CustomSelect from '../../../../../components/Antd/Select';
import CustomTextArea from '../../../../../components/Antd/TextArea';
import CustomModal from '../../../../../components/Antd/Modal';
import Loading from '../../../../../components/Loading';
import DrawerOptions from '../../../components/DrawerOptions';
import { serviceGetData, serviceUpdateData } from '../services';
import { DICTIONARY } from './constants/dictionary.constant';
import { addElement } from './handles/add-element.handle';
import { deleteElement } from './handles/delete-element.handle';
import { extractPhoneAndText } from './handles/extract-phone-and-text.handle';
import { generateWhatsappUrl } from './handles/generate-whatsapp-url.handle';
import { sortElement } from './handles/sort-element.handle';
import './style.css';
import { SOCIAL } from './constants/social.contants';

export default function AccountBodyLink(props) {
	const history = useHistory();
	const { account, modify } = useParams<any>();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>();
	const [isLoading, setLoading] = useState<any>(true);
	const [buttonText, setButtonText] = useState<any>('Siguiente');
	const [links, setLinks] = useState<any>([]);
	const [isModify, setIsModify] = useState<any>(false);
	const [radio, setRadio] = useState<any>(undefined);
	const [textButton, setTextButton] = useState<any>('Agregar');
	const [nameLink, setNameLink] = useState<any>(undefined);
	const [showModal, setShowModal] = useState<any>(false);
	const [editIndex, setEditIndex] = useState<any>(null);
	const [deleteIndex, setDeleteIndex] = useState<any>(null);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [isDelete, setIsDelete] = useState<any>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<any>(false);
	console.log('nameLink', nameLink);
	useEffect(() => {
		console.log('useEffects', 'AccountBodyLink');
		if (modify) {
			setButtonText('Actualizar');
			setIsModify(true);
		}
		initData(account);
	}, [account, modify]);

	console.log('links', links);
	const initData = async (account) => {
		const response = await serviceGetData(account);
		setData(response);
		const sortedElements = await sortElement(response.links);
		setLinks(sortedElements);
		setLoading(false);
	};

	const handleAddElement = async (item) => {
		try {
			const { fields, newLink } = (await addElement(links, item)) as any;
			setLinks((links) => [...links, newLink]);
			form.resetFields(fields);
			setShowModal(false);
		} catch (e) {
			console.log(e);
			CustomNotification({
				type: TypeNotification.error,
				description: 'Sólo puedes tener un video de presentación',
			});
			return;
		}
	};

	const handleEditElement = (item) => {
		if (radio === DICTIONARY.whatsapp.toLowerCase()) {
			links[editIndex] = { ...links[editIndex], title: item.title, url: generateWhatsappUrl(item.number, item.message) };
		} else {
			links[editIndex] = { ...links[editIndex], title: item.title, url: item.url };
		}

		setShowModal(false);
		form.resetFields();
		setIsEdit(false);
	};

	const handleDelete = (e) => {
		setDeleteIndex(e);
		setShowDeleteModal(true);
	};

	const handleDeleteModalOk = async () => {
		setIsDelete(true);
		const result = await deleteElement(links, deleteIndex);
		const sortedElements = await sortElement(result);
		setLinks(sortedElements);
		setShowDeleteModal(false);
	};

	const handleSubmit = () => {
		if (links.length <= 0 && !isDelete) {
			CustomNotification({
				type: TypeNotification.error,
				description: 'No tienes ningún enlace creado',
			});
			return;
		}
		let item = data;
		item.links = links.map((iterator) => {
			iterator.id = iterator.id.toString();
			return iterator;
		});
		serviceUpdateData(item).then((response) => {
			setIsDelete(false);
			if (isModify === false) {
				history.push(`/profile/account-color/${response.account}`);
			}

			history.push(`/profile/modules/${response.account}`);
		});
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(links);
		const [reOrderItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reOrderItem);
		setLinks(items);
		let item = data;
		item.links = items;
	};

	const handleModalOk = () => {
		form.validateFields().then((values) => {
			if (isEdit) {
				handleEditElement(values);
				return;
			}
			handleAddElement(values);
			form.resetFields(['radio']);
		});
	};

	const handleModalCancel = () => {
		setShowModal(false);
		form.resetFields();
	};

	const handleDeleteModalCancel = () => {
		setShowDeleteModal(false);
	};

	const handleSelectChange = (value) => {
		setRadio(value);
		if (value === DICTIONARY.whatsapp.toLowerCase()) {
			setNameLink(DICTIONARY.whatsapp);
			setTextButton(DICTIONARY.generateWhatsapp);
		} else if (value === DICTIONARY.web) {
			setNameLink(DICTIONARY.webSite);
			setTextButton(DICTIONARY.addLink);
		} else if (value === DICTIONARY.social) {
			setNameLink(DICTIONARY.socialMedia);
			setTextButton(DICTIONARY.addLink);
		}
		setShowModal(true);
	};

	const handleEdit = (index) => {
		const link = links[index];
		setEditIndex(index);
		setIsEdit(true);
		form.setFieldsValue({ radio: link.type });
		setRadio(link.type);
		if (link.type === DICTIONARY.whatsapp.toLowerCase()) {
			setNameLink(DICTIONARY.whatsapp);
			setTextButton(DICTIONARY.editWhatsapp);
			let inputs: any = extractPhoneAndText(link.url);
			inputs.title = link.title;
			form.setFieldsValue(inputs);
		} else if (link.type === DICTIONARY.web) {
			setNameLink(DICTIONARY.webSite);
			setTextButton(DICTIONARY.editLink);
			form.setFieldsValue(link);
		} else if (link.type === DICTIONARY.social || SOCIAL.includes(link.type)) {
			setNameLink(DICTIONARY.socialMedia);
			setTextButton(DICTIONARY.editLink);
			form.setFieldsValue(link);
			setRadio(DICTIONARY.social);
		}

		setShowModal(true);
	};
	if (isLoading === true) {
		return <Loading />;
	}

	return (
		<>
			{isModify && (
				<>
					<DrawerOptions account={account} />
				</>
			)}
			<Row justify="center" className="own-bodylink-list">
				<Col xs={22} md={10}>
					<CustomCard title={'Links'} bordered={false}>
						<Row justify={'center'}>
							<Col span={21}>
								<CustomForm layout="vertical" form={form}>
									<CustomFormItem
										label={'¿Qué tipo de LINK quieres crear?'}
										name={'radio'}
										rules={'required'}
										extra={'Selecciona la opción que más se adecue a lo que necesitas compartir'}
									>
										<CustomSelect
											mode={'single'}
											placeholder={'Seleccione una opción'}
											options={[...Config.typeLinks]}
											onChange={handleSelectChange}
										/>
									</CustomFormItem>
								</CustomForm>
								<br></br>
								<h3>Enlaces Agregados: {links.length}</h3>
								<DragDropContext onDragEnd={handleOnDragEnd}>
									<Droppable droppableId="characters">
										{(provided) => (
											<ul {...provided.droppableProps} ref={provided.innerRef}>
												{links.map((item, key) => (
													<Draggable key={item.id.toString()} draggableId={item.id.toString()} index={key}>
														{(provided) => (
															<li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																<Row>
																	<Col span={18}>{item.title}</Col>
																	<Col span={2}>
																		<CustomButton
																			className="own-none-button"
																			icon={<LinkOutlined />}
																			onClick={() => {
																				window.open(item.url);
																			}}
																		/>
																	</Col>
																	<Col span={2}>
																		<CustomButton
																			className="own-none-button"
																			icon={<EditOutlined />}
																			onClick={() => {
																				handleEdit(key);
																			}}
																		/>
																	</Col>
																	<Col span={2}>
																		<CustomButton
																			className="own-none-button"
																			icon={<DeleteOutlined />}
																			onClick={() => {
																				handleDelete(key);
																			}}
																		/>
																	</Col>
																</Row>
															</li>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</ul>
										)}
									</Droppable>
								</DragDropContext>
								{modify && <h5>* Recuerda darle al boton "Actualizar" para que tus cambios surtan efecto.</h5>}
								{(links.length > 0 || isDelete === true) && (
									<CustomButton block onClick={handleSubmit}>
										{buttonText}
									</CustomButton>
								)}
							</Col>
						</Row>
					</CustomCard>
					<CustomModal
						title={textButton}
						visible={showModal}
						onOk={handleModalOk}
						onCancel={handleModalCancel}
						footer={[
							<CustomButton className="own-add-button" onClick={handleModalCancel}>
								Cancelar
							</CustomButton>,
							<CustomButton className="own-add-button" onClick={handleModalOk}>
								{textButton}
							</CustomButton>,
						]}
					>
						<CustomForm layout="vertical" form={form} onFinish={handleModalOk}>
							{nameLink !== undefined && (
								<CustomFormItem
									name={'title'}
									label={'Titulo del LINK'}
									rules={'required'}
									extra={'Este es el nombre que aparecerá en el menú de opciones a tus clientes'}
								>
									<CustomInput placeholder={'Nombre del enlace'} type={'text'} />
								</CustomFormItem>
							)}

							{radio === 'whatsapp' && (
								<>
									<CustomFormItem
										name={'number'}
										label={'Coloca tu WhatsApp'}
										rules={'phone'}
										extra={'Recuerda colocar el número con el código de área de tu país para que funcione correctamente y SIN el signo (+)'}
									>
										<CustomInput placeholder={'Ingresa tu número de WhatsApp'} type={'text'} />
									</CustomFormItem>

									<CustomFormItem
										name={'message'}
										label={'Mensaje PRE-Determinado'}
										extra={
											'Escribe el mensaje con el que deseas que lleguen a tú WhatsApp, ejemplo: Hola! me interesa recibir el catálogo de sus productos'
										}
									>
										<CustomTextArea placeholder={'Escribe un mensaje personalizado para que sepas que necesita tu cliente'} rows={4} />
									</CustomFormItem>
								</>
							)}

							{(radio === Config.optionsType.web || radio === Config.optionsType.social) && (
								<>
									<CustomFormItem
										name={'url'}
										label={`Coloca aquí el LINK de tu ${nameLink}`}
										rules={'required'}
										extra={'Copia y pega aquí la URL del sitio que deseas compartir'}
									>
										<CustomInput placeholder={'Copia tu link aquí'} type={'text'} />
									</CustomFormItem>
								</>
							)}
						</CustomForm>
					</CustomModal>
					<CustomModal
						title={'Eliminar enlace'}
						visible={showDeleteModal}
						onOk={handleDeleteModalOk}
						onCancel={handleDeleteModalCancel}
						footer={[
							<CustomButton className="own-add-button" onClick={handleDeleteModalCancel}>
								Cancelar
							</CustomButton>,
							<CustomButton className="own-add-button" onClick={() => handleDeleteModalOk()}>
								Eliminar
							</CustomButton>,
						]}
					>
						<p>¿Estas seguro que deseas eliminar este enlace?</p>
						<span>{links[deleteIndex]?.url}</span>
					</CustomModal>
				</Col>
			</Row>
		</>
	);
}
