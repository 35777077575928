/** @format */

import React, { useEffect } from 'react';
import { Skeleton, Row, Col } from 'antd';

import Footer from '../../../../../../components/Footer';

import './style.css';

export default function SkeletonAccount(props) {
	useEffect(() => {
		console.log('useEffects', 'SkeletonAccount');
	}, []);

	return (
		<>
			<Row justify="center">
				<Col md={12} sm={14} xs={24}>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Skeleton.Avatar active size="large" shape="circle" style={{ width: '125px', height: '125px' }} />
					</Row>

					<Row justify="center" style={{ marginTop: '35px' }}>
						<Col md={10} sm={10} xs={10}>
							<Skeleton.Input active block={true} style={{ height: '25px', borderRadius: '15px' }} />
						</Col>
					</Row>

					<Row justify="center" style={{ marginTop: '15px' }}>
						<Col md={15} sm={15} xs={15}>
							<Skeleton.Input active block={true} style={{ height: '15px', borderRadius: '15px' }} />
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '3px' }}>
						<Col md={15} sm={15} xs={15}>
							<Skeleton.Input active block={true} style={{ height: '15px', borderRadius: '15px' }} />
						</Col>
					</Row>

					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={21} sm={17} xs={20}>
							<Skeleton.Input active block={true} style={{ height: '35px', borderRadius: '25px' }} />
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={21} sm={17} xs={20}>
							<Skeleton.Input active block={true} style={{ height: '35px', borderRadius: '25px' }} />
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={21} sm={17} xs={20}>
							<Skeleton.Input active block={true} style={{ height: '35px', borderRadius: '25px' }} />
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={21} sm={17} xs={20}>
							<Skeleton.Input active block={true} style={{ height: '35px', borderRadius: '25px' }} />
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={21} sm={17} xs={20}>
							<Skeleton.Input active block={true} style={{ height: '35px', borderRadius: '25px' }} />
						</Col>
					</Row>
					<Row justify="center" style={{ marginTop: '10px' }}>
						<Col md={21} sm={17} xs={20}>
							<Skeleton.Input active block={true} style={{ height: '35px', borderRadius: '25px' }} />
						</Col>
					</Row>
				</Col>
			</Row>

			<Footer />
		</>
	);
}
