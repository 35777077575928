import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MenuItem from './MenuItem';

const Menu = () => {
	const [menuItems, setMenuItems] = useState<any>([
		{
			title: 'Item 1',
			url: '/item1',
			position: 1,
			subMenuItems: [
				{
					title: 'Subitem 1.1',
					url: '/subitem1-1',
					position: 1,
					subMenuItems: [
						{
							title: 'Subitem 1.1.1',
							url: '/subitem1-1-1',
							position: 1,
							subMenuItems: [],
						},
					],
				},
				{
					title: 'Subitem 1.2',
					url: '/subitem1-2',
					position: 2,
					subMenuItems: [],
				},
			],
		},
		{
			title: 'Item 2',
			url: '/item2',
			position: 2,
			subMenuItems: [],
		},
	]);

	const moveItem = (fromIndex, toIndex) => {
		const updatedMenuItems = [...menuItems];
		const [movedItem] = updatedMenuItems.splice(fromIndex, 1);
		updatedMenuItems.splice(toIndex, 0, movedItem);
		setMenuItems(updatedMenuItems);
	};

	const addMenuItem = () => {
		const updatedMenuItems = [...menuItems];
		const newMenuItem = {
			title: `New Item ${menuItems.length + 1}`,
			url: `/newitem ${menuItems.length + 1}`,
			position: menuItems.length + 1,
			subMenuItems: [],
		};
		updatedMenuItems.push(newMenuItem);
		setMenuItems(updatedMenuItems);
	};

	const addSubMenuItem = (parentItem) => {
		const updatedMenuItems = [...menuItems];
		const newSubMenuItem = {
			title: `New Subitem ${parentItem.subMenuItems.length + 1}`,
			url: `/newsubitem ${parentItem.subMenuItems.length + 1}`,
			position: parentItem.subMenuItems.length + 1,
			subMenuItems: [],
		};
		parentItem.subMenuItems.push(newSubMenuItem);
		setMenuItems(updatedMenuItems);
	};

	const deleteMenuItem = (parentItem, index) => {
		const updatedMenuItems = [...menuItems];
		if (parentItem) {
			parentItem.subMenuItems.splice(index, 1);
		} else {
			updatedMenuItems.splice(index, 1);
		}
		setMenuItems(updatedMenuItems);
	};

	const renderMenu = (items) => {
		if (!items || items.length === 0) return null;

		return (
			<ul>
				{items.map((item, index) => (
					<li key={index}>
						<a href={item.url}>{item.title}</a>
						{item.subMenuItems && item.subMenuItems.length > 0 && renderMenu(item.subMenuItems)}
					</li>
				))}
			</ul>
		);
	};

	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<div>
					<button onClick={addMenuItem}>Agregar Nuevo Item</button>
					{menuItems.map((item, index) => (
						<MenuItem
							key={index}
							item={item}
							index={index}
							moveItem={moveItem}
							addSubMenuItem={addSubMenuItem}
							deleteMenuItem={deleteMenuItem}
							parentItem={null}
						/>
					))}
				</div>
			</DndProvider>

			<div>
				<h2>Resultado Final</h2>
				<div>{renderMenu(menuItems)}</div>
			</div>
		</>
	);
};

export default Menu;
