/** @format */

import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { QuestionOutlined, CloseOutlined, UserOutlined, SafetyOutlined, LinkOutlined } from '@ant-design/icons';

import { TypeNotification } from '../../../_config/constants/notification.constant';
import { Storage } from '../../../_config/constants/storage.constant';
import { RemoveStorage } from '../../../_config/utils/remove-storage.utils';
import { GetUserStorage } from '../../../_config/utils/user-storage/get-user-storage.utils';

import CustomButton from '../../Antd/Button';
import CustomDrawer from '../../Antd/Drawer';
import CustomNotification from '../../Antd/Notification';

import './style.css';

export default function SideBar(props) {
	const [user] = useState(GetUserStorage());
	const handleLogout = () => {
		let btn = (
			<CustomButton
				onClick={() => {
					window.location.href = '/';
					RemoveStorage(Storage.user);
				}}
			>
				Confirmar
			</CustomButton>
		);

		CustomNotification({
			type: TypeNotification.warning,
			title: `Cerrar Sesión`,
			description: `¿Estás seguro que quieres salir?`,
			btn,
			key: 'close-notification',
		});
	};

	return (
		<CustomDrawer className="own-header-sidebar-content" title={'Creatulink'} width={300} onClose={() => props.handleOpenMenu()} visible={props.menu}>
			<>
				<Row className="own-header-sidebar-content-row">
					<Col xs={7} sm={7} md={7}>
						<Link to={`/profile/accounts`}>
							<img className="own-header-sidebar-content-img-user" width="100%" src={user.image} alt="Imagen Usuario" />
						</Link>
					</Col>
					<Col xs={15} sm={15} md={15}>
						<h3 className="own-header-sidebar-title">
							{user.first_name} {user.last_name}
						</h3>
						<p className="own-header-sidebar-sub-title">Estas registrado con: {user.email}</p>
					</Col>
					<hr className="own-header-sidebar-hr" />
				</Row>

				<div className="own-header-sidebar-list">
					<Link to={`/`}>
						<img className="own-header-logo" src={process.env.REACT_APP_LOGO} alt="Logo Creatulink" />
						<span className="own-header-sidebar-list-title">Inicio</span>
					</Link>
				</div>
				<div className="own-header-sidebar-list">
					<Link to={`/profile/resume`}>
						<UserOutlined className="own-header-sidebar-list-icon" style={{ fontSize: 22 }} />
						<span className="own-header-sidebar-list-title">Mi Cuenta</span>
					</Link>
				</div>
				<div className="own-header-sidebar-list">
					<Link to={`/profile/account-user/`}>
						<LinkOutlined className="own-header-sidebar-list-icon" style={{ fontSize: 22 }} />
						<span className="own-header-sidebar-list-title">Crear Link</span>
					</Link>
				</div>
				<div className="own-header-sidebar-list">
					<Link to={`/profile/change-password`}>
						<SafetyOutlined className="own-header-sidebar-list-icon" style={{ fontSize: 22 }} />
						<span className="own-header-sidebar-list-title">Cambiar Contraseña</span>
					</Link>
				</div>

				<div className="own-header-sidebar-list own-header-sidebar-list-cs">
					<hr className="own-header-sidebar-hr mb10" />

					<div
						className="own-header-icon-login-content"
						style={{ cursor: 'pointer' }}
						onClick={() => {
							handleLogout();
						}}
					>
						<CloseOutlined />
						<span className="own-header-sidebar-list-title">Cerrar sesión</span>
					</div>
				</div>

				<div className="own-header-sidebar-list">
					<Link to={`/help/quienes-somos`}>
						<QuestionOutlined className="own-header-sidebar-list-icon" style={{ fontSize: 22 }} />
						<span className="own-header-sidebar-list-title">Ayuda</span>
					</Link>
				</div>
			</>
		</CustomDrawer>
	);
}
