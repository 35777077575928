import { Storage } from '../../constants/storage.constant';
import { Enviroment } from '../../constants/enviroment.constant';
export const GetUserStorage = () => {
	console.log('localStorage', localStorage.getItem(Storage.user));
	if (localStorage.getItem(Storage.user) === undefined || localStorage.getItem(Storage.user) === null) {
		return null;
	}
	let user = JSON.parse(localStorage.getItem(Storage.user) as any);
	user.image = user.image === undefined ? Enviroment.LOGO : user.image;
	return user;
};
