import { Enviroment } from '../../../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async create(body) {
		return new AxiosAdapter({
			method: Http.POST,
			url: `${Enviroment.HOST}/suscriber`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async getOptions(type) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/select-option/number-format/${type}`,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
