/** @format */

import { Enviroment } from '../../../../_config/constants/enviroment.constant';
import { AxiosAdapter } from '../../../../_config/adapters/axios/axios.adapter';
import { Http } from '../../../../_config/adapters/axios/axios.protocols';

export class Services {
	async get(param) {
		return new AxiosAdapter({
			method: Http.GET,
			url: `${Enviroment.HOST}/billing/get-one-module`,
			params: {
				account: param.account,
				module: param.module,
			},
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}

	async update(body) {
		return new AxiosAdapter({
			method: Http.PUT,
			url: `${Enviroment.HOST}/billing`,
			data: body,
		})
			.request()
			.then((response) => {
				return response;
			})
			.catch((e) => {
				throw e;
			});
	}
}
