import numberFormatter from 'number-formatter';

export const onReserve = (item, account) => {
	console.log('onReserve', item);
	const priceOffer = item.price_offer ? item.price_offer : 0;

	if (priceOffer === 0) {
		return `Hola, ${account} me interesa el servicio de *${item.name}* que tiene un valor publicado de *${numberFormatter('#.###,##', item.price)}* ${
			item.currency
		}`;
	}

	if (priceOffer !== 0) {
		return `Hola, ${account} me interesa el servicio de *${item.name}* que esta de promoción por *${numberFormatter('#.###,##', priceOffer)}* ${
			item.currency
		}`;
	}
};
