/** @format */

import React from 'react'
import { Form, Input } from 'antd'

import { rulesValidation } from '../Rules'
import './style.css'

export default function InputField(props) {
	return (
		<>
			<h3 className='own-form-label'>
				{props.componentLabel} {props.componentRules ? '*' : ''}
			</h3>
			<Form.Item
				name={props.componentName}
				rules={rulesValidation[props.componentRules]}
				extra={props.componentExtra}>
				<Input
					size='large'
					className={props.componentClass || 'own-form-input'}
					name={props.componentName}
					prefix={props.componentPrefix}
					type={props.componentType}
					placeholder={props.componentPlaceholder || 'Escribe aquí'}
					onChange={props.componentOnChange}
					disabled={props.componentDisabled}
					value={props.componentValue}
					maxLength={props.componentMaxLength}
					minLength={props.componentMinLength}
					addonBefore={props.componentAddonBefore}
					addonAfter={props.componentAddonAfter}
					defaultValue={props.componentDefaultValue}
					autoComplete='off'
				/>
			</Form.Item>
			<p className='own-form-example-input'>{props.componentExample}</p>
		</>
	)
}
