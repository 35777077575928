/** @format */

import React, { useState, useEffect } from 'react';
import { Form, notification, Row, Col } from 'antd';

import CustomForm from '../../../../../../components/Antd/Form';
import CustomInput from '../../../../../../components/Antd/Input';
import CustomFormItem from '../../../../../../components/Antd/FormItem';
import CustomTextArea from '../../../../../../components/Antd/TextArea';
import CustomButton from '../../../../../../components/Antd/Button';
import CustomCard from '../../../../../../components/Antd/Card';

import DragLinks from '../DragLinks';

export default function FormComponent(props) {
	const [form] = Form.useForm();
	const [data] = useState<any>(props.data);
	const [links, setLinks] = useState<any>([]);
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');

	useEffect(() => {
		console.log('useEffect', props);
		setLinks([...props.data.links]);
		setTitle(props.data.title);
		setMessage(props.data.message);
	}, [props]);

	const handleAddItem = (item) => {
		console.log('handleAddItem', item);
		setLinks((links) => [
			...links,
			{
				id: links.length.toString(),
				phone: item.phone,
				eneable: true,
			},
		]);

		form.resetFields(['phone']);
	};

	const handleDelete = async (e) => {
		let filterLink = await links.filter((item, key) => {
			return key !== e;
		});
		setLinks([...filterLink]);
		const newLinks = await filterLink.map((item, key) => {
			item.id = key.toString();
			return item;
		});
		setLinks([...newLinks]);
		console.log('handleDelete', newLinks);
	};

	const handleSubmit = () => {
		if (title === '') {
			notification['error']({
				message: `¡Ups!`,
				description: `Falta el título del link`,
			});
			return;
		}

		if (message === '') {
			notification['error']({
				message: `¡Ups!`,
				description: `Falta agregar el mensaje `,
			});
			return;
		}

		if (links.length <= 0) {
			notification['error']({
				message: `¡Ups!`,
				description: `Debe agregar al menos un número`,
			});
			return;
		}

		let item = {
			title: title,
			message: message,
			links: links,
		};
		console.log('item', item);

		props.submit(item);
	};

	return (
		<>
			<Row justify="center" className="own-product">
				<Col xs={22} md={10}>
					<CustomCard title={props.label} bordered={false}>
						<Row justify="center">
							<Col span={24}>
								<CustomForm layout="vertical" form={form} initialValues={data} onFinish={handleAddItem}>
									<CustomFormItem name={'title'} label={'Titulo'} rules={'required'} extra={'Agrega el título del enlace'}>
										<CustomInput
											placeholder={'Nombre del enlace'}
											type={'text'}
											value={data.title}
											onChange={(e) => setTitle(e.target.value)}
										/>
									</CustomFormItem>

									<CustomFormItem
										name={'message'}
										label={'Mensaje PRE-Determinado'}
										rules={'required'}
										extra={
											'Escribe el mensaje con el que deseas que lleguen las personas, ejemplo: Hola! me interesa recibir el catálogo de sus productos'
										}
									>
										<CustomTextArea
											placeholder={'Escribe un mensaje personalizado para que sepas que necesita tu cliente'}
											rows={4}
											value={data.description}
											onChange={(e) => setMessage(e.target.value)}
										/>
									</CustomFormItem>

									<CustomFormItem
										name={'phone'}
										label={'Número de WhatsApp'}
										rules={'phone'}
										extra={'Escibe correctamente el número de tu agente, incluye el código del país'}
									>
										<CustomInput placeholder={'Número de WhatsApp'} type={'text'} value={data.phone} />
									</CustomFormItem>

									<CustomButton block htmlType="submit" className={'own-add-button'}>
										Agregar
									</CustomButton>
								</CustomForm>

								{links.length > 0 && (
									<>
										<DragLinks data={links} delete={handleDelete} />
										<br></br>
										<Row justify={'center'}>
											<CustomButton block onClick={handleSubmit}>
												{props.textButton}
											</CustomButton>
										</Row>
									</>
								)}
							</Col>
						</Row>
					</CustomCard>
				</Col>
			</Row>
		</>
	);
}
